import FormListField from "../forms/FormListField";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { CircularProgress, Stack, } from "@mui/material";
import FormDialog from "../forms/FormDialog";
import React, { useEffect, useState } from "react";
import FormAutocomplete from "../forms/FormAutocomplete";
import FormTextField from "../forms/FormTextField";
import FormDateField from "../forms/FormDateField";

const ClientInstitutionsField = ({ 
    i18nKeyLabel, 
    i18nKeyAddButton, 
    i18nKeyDateFormat, 
    values, 
    onChange, 
    institutions, 
    editable 
}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(null);
    const [index, setIndex] = useState(null);
    const [selectedInstitution, setSelectedInstitution] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [showValidation, setShowValidation] = useState(false);

    const [errInstitution, setErrInstitution] = useState('');
    const [errInstitutionNumber, setErrInstitutionNumber] = useState('');
    const [errValidFrom, setErrValidFrom] = useState('');

    useEffect(() => {
        if (institutions && value) {
            const institution = institutions.find((institution) => institution.id === value.insuranceId);
            setSelectedInstitution(institution ? institution : null);
        }
    }, [institutions, value?.insuranceId]);

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const validate = () => {
        let valid = true;

        if (!selectedInstitution) {
            setErrInstitution('clients.error.institution');
            valid = false;
        } else {
            setErrInstitution('');
        }

        if (!value?.insuranceNumber) {
            setErrInstitutionNumber('clients.error.institution-number');
            valid = false;
        } else {
            setErrInstitutionNumber('');
        }

        if (!value?.validFrom) {
            setErrValidFrom('clients.error.care-degree-valid-from');
            valid = false;
        } else {
            setErrValidFrom('');
        }

        return valid;
    }

    const handleSaveModal = () => {
        //if no Startdate was chosen, take the date from today
        value.validFrom = value?.validFrom ?? DateTime.now();

        if (!validate()) {
            setShowValidation(true);
            return;
        }

        setShowValidation(false);
        const newValues = [...values];

        if (index !== null) {
            newValues.splice(index, 1, value);
        } else {
            newValues.push(value);
        }

        setOpenModal(false);
        onChange(newValues);
    }

    useEffect(() => {
        validate();
    }, [selectedInstitution, value]);

    const sortedInstitutions = institutions 
            ? institutions.sort(function (x, y) {
                if (x.shortname < y.shortname) {
                    return -1;
                }
                if (x.shortname > y.shortname) {
                    return 1;
                }
                return 0;
            })
            : null;

    return (<>
        <FormListField
            i18nKeyLabel={i18nKeyLabel}
            i18nKeyAddButton={i18nKeyAddButton}
            editable={editable}
            values={values}
            getValueText={(value) => {
                let text = value.shortname + ' (' + value.insuranceNumber + ')';
                return text;
            }}
            onNew={() => {
                setValue({
                    insuranceId: null,
                    insuranceNumber: '',
                    validFrom: null,
                    validTo: null,
                    persisted: false,
                });
                setIndex(null);
                setOpenModal(true);
            }}
            onEdit={(value, index) => {
                if (institutions) {
                    setSelectedInstitution(institutions.find((institution) => institution.id === value.insuranceId));
                }
                setValue(value);
                setIndex(index);
                setOpenModal(true);
            }}
            onDelete={(index) => {
                const newValues = [...values];
                newValues.splice(index, 1);
                onChange(newValues);
            }}
        />
        <FormDialog
            open={openModal}
            onClose={handleCloseModal}
            onCancel={handleCloseModal}
            onSave={handleSaveModal}
            i18nKeyTitle="clients.insurance.title"
        >
            {!institutions ?
                <Stack sx={{ flexGrow: 1, padding: 2 }} alignItems="center">
                    <CircularProgress />
                </Stack>
                :
                <>
                    <FormAutocomplete
                        value={selectedInstitution}
                        editable={editable}
                        onChange={(newValue) => {
                            setSelectedInstitution(newValue);
                            setValue({ ...value, insuranceId: newValue.id, shortname: newValue.shortname });
                        }}
                        i18nKeyLabel="clients.insurance.label"
                        i18nKeyPlaceholder="clients.insurance.placeholder"
                        options={sortedInstitutions}
                        getOptionLabel={(option) => option.shortname}
                        valueText={value ? value.shortname : null}
                        disabled={!!value?.persisted}
                        showValidation={showValidation}
                        error={!!errInstitution}
                        i18nErrorKey={errInstitution}
                        i18nErrorValue="Invalid Institution"
                        required={true}
                    />

                    <FormTextField
                        value={value?.insuranceNumber}
                        editable={editable}
                        onChange={(event) => {
                            setValue({ ...value, insuranceNumber: event.target.value });
                        }}
                        i18nKeyLabel="clients.insurance-number.label"
                        i18nKeyPlaceholder="clients.insurance-number.placeholder"
                        valueText={value?.insuranceNumber}
                        disabled={false}
                        showValidation={showValidation}
                        error={!!errInstitutionNumber}
                        i18nErrorKey={errInstitutionNumber}
                        i18nErrorValue="Invalid Institution Number"
                        required={true}
                    />

                    <FormDateField
                        value={value?.validFrom ?? DateTime.now()}
                        editable={editable}
                        onChange={(newValue) => {
                            setValue({ ...value, validFrom: newValue });
                        }}
                        i18nKeyLabel="clients.insurance-valid-from.label"
                        i18nKeyPlaceholder="clients.insurance-valid-from.placeholder"
                        valueText={value?.validFrom ? t(i18nKeyDateFormat, { date: value.validFrom.toJSDate() }) : null}
                        disabled={false}
                        showValidation={showValidation}
                        error={!!errValidFrom}
                        i18nErrorKey={errValidFrom}
                        i18nErrorValue="Invalid Institution Valid From"
                        required={true}
                    />

                </>
            }
        </FormDialog>
    </>);
}

export default ClientInstitutionsField;
