import { Trans, useTranslation } from "react-i18next";
import Autocomplete from "../Autocomplete";

const EventEmployeeSelectionModal = ({
    isMultiSelection,
    isClientEvent,
    editable,
    event,
    employeeIds,
    setEmployeeIds,
    employeeId,
    setEmployeeId,
    employees,
    employeeNames,
    listOfEmployeesError,
    employeeIdError,
    isContactSelection
}) => {
    const { t } = useTranslation();


    const sortEmployees = (options) => {
        return options.sort((a, b) => {
            if (a.person && b.person) {
                if (a.person.last_name < b.person.last_name)
                    return -1;
                if (a.person.last_name > b.person.last_name)
                    return 1;
                if (a.person.first_name < b.person.first_name)
                    return -1;
                if (a.person.first_name > b.person.first_name)
                    return 1;
            }
            return 0;
        });
    }


    const renderEmployee = (option) => {
        return option && option.person
            ? `${option.person.last_name} ${option.person.first_name}`
            : "";
    }


    const filterEmployees = (options, inputValue) => {
        return options.filter((option) => {
            if (inputValue === "") {
                return true;
            }

            let found = false;

            inputValue.split(" ").filter(Boolean).forEach((word) => {
                if (option.person && option.person.first_name.toLowerCase().startsWith(word.toLowerCase()) ||
                    option.person && option.person.last_name.toLowerCase().startsWith(word.toLowerCase())
                ) {
                    found = true;
                }
            });

            return found;
        });
    };


    const renderEmployeeById = (employeeId) => {
        const employee = getEmployee(employeeId);
        return renderEmployee(employee);
    }


    const getEmployee = (id) => {
        let employee = null;

        if (employees) {
            employee = employees.data.find((employee) => employee.id === id)
        }

        if (!employee && event.employees.length > 0) {
            employee = event.employees.find((employee) => employee.id === id);
        }

        return employee;
    }


    const getEmployeeName = (id) => {
        if (!employeeNames || !employeeNames.data) {
            return "";
        }

        const employee = employeeNames.data.find((employee) => employee.employee_id.toString() === id.toString());

        return employee
            ? `${employee.last_name} ${employee.first_name}`
            : <Trans i18nKey="team-calendar.not-assigned">Not assigned</Trans>;
    }


    if (isMultiSelection) {
        const employeeOptions = employees.data.filter(
            emp => !employeeIds.some(employeeId => emp.id === employeeId)
        );

        return (
            <div
                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200">
                <label htmlFor="employees"
                    className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                    {isContactSelection
                        ? <Trans i18nKey="team-calendar.event-modal.contact">contact</Trans>
                        : <Trans i18nKey="team-calendar.event-modal.employees">employees</Trans>
                    }
                </label>

                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {editable ? (
                        <div>
                            <div className="mt-2">
                                {employeeIds.map((employeeId, index) => (
                                    <div key={"employee_" + employeeId}
                                        className="flex items-center justify-between py-1">
                                        {renderEmployeeById(employeeId)}
                                        <button
                                            className="text-red-500 hover:text-red-700"
                                            onClick={() => {
                                                setEmployeeIds(employeeIds.filter(id => id !== employeeId));
                                            }}
                                        >
                                            <svg className="h-4 w-4" fill="currentColor"
                                                viewBox="0 0 20 20">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M14.293 5.293a1 1 0 0 0-1.414 0L10 8.586 6.707 5.293a1 1 0 0 0-1.414 1.414L8.586 10l-3.293 3.293a1 1 0 1 0 1.414 1.414L10 11.414l3.293 3.293a1 1 0 0 0 1.414-1.414L11.414 10l3.293-3.293a1 1 0 0 0 0-1.414z"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <Autocomplete
                                options={employeeOptions}
                                error={listOfEmployeesError}
                                onSelect={(employee) => setEmployeeIds([...employeeIds, employee.id])}
                                renderOption={renderEmployee}
                                renderValue={renderEmployee}
                                filterFunction={filterEmployees}
                                sortFunction={sortEmployees}
                                placeholder={t('team-calendar.event-modal.add-employee')}
                                resetAfterSelection={true}
                            />
                        </div>
                    ) : (
                        <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                            {
                                employeeIds.map((employeeId, index) => {
                                    return <div key={"employee_" + employeeId}>{renderEmployeeById(employeeId)}</div>
                                })
                            }
                        </div>
                    )
                    }
                </div>
            </div>
        )
    }


    return (
        <div
            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
            <label htmlFor="employee"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                {isContactSelection
                    ? <Trans i18nKey="team-calendar.event-modal.contact">contact</Trans>
                    : <Trans i18nKey="team-calendar.event-modal.employee">Employee</Trans>
                }
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                {editable ?
                    <div className="sm:grid-row-2">
                        <select
                            id="employee"
                            name="employee"
                            value={employeeId}
                            onChange={(e) => setEmployeeId(e.target.value)}
                            className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${employeeIdError ? "border-red-500" : "border-gray-300"}`}
                        >
                            {isClientEvent
                                ?
                                <option value={-1}>
                                    <Trans i18nKey="team-calendar.event-modal.all-employees-of-team">all employees of team</Trans>
                                </option>
                                :
                                <option disabled value={-1}>
                                    {isContactSelection
                                        ? <Trans i18nKey="team-calendar.event-modal.select-contact">select a contact</Trans>
                                        : <Trans i18nKey="team-calendar.event-modal.select-employee">select an employee</Trans>
                                    }
                                </option>
                            }
                            {employees && sortEmployees(employees.data).map(employee => (
                                <option key={employee.id} value={employee.id}>
                                    {employee.person.last_name} {employee.person.first_name}
                                </option>
                            ))}
                        </select>
                        {employeeIdError &&
                            <p className="mt-2 text-sm text-red-600">
                                {isContactSelection
                                    ? <Trans i18nKey="team-calendar.event-modal.error.select-contact">
                                        Please select a contact.
                                    </Trans>
                                    : <Trans i18nKey="team-calendar.event-modal.error.select-employee">
                                        Please select an employee.
                                    </Trans>
                                }
                            </p>
                        }
                    </div>
                    :
                    <div
                        className="text-sm text-gray-700 sm:mt-px sm:pt-2">{getEmployeeName(employeeId)}</div>
                }
            </div>
        </div>
    )


};

export default EventEmployeeSelectionModal;