import { useState } from "react";
import { useTranslation } from "react-i18next";
import Drawer from '@mui/material/Drawer';
import TeamCalendarMemberFilter from "./TeamCalendarMemberFilter";
import EventsExportModal from "../EventsExportModal";
import ImportModal from "../ImportModal";
import useApi from "../../hooks/useApi";

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TeamCalendarGeneralFilter from "./TeamCalendarGeneralFilter";
import TeamCalendarFilterCalendar from "./TeamCalendarFilterCalendar";
import TeamCalendarActiveFilterBox from "./TeamCalendarActiveFilterBox";
import { useCalendarViewStore } from "../../store/CalendarViewStore";

const TeamCalendarFilterDrawer = ({
    isLargeScreen,
    filter,
    teamMembers,
    setFilter,
    handleGoToDateClick,
}) => {
    const { t } = useTranslation();
    const { convertEvents, importEvents } = useApi();

    const permissionsMeta = document.querySelector('meta[name="permissions"]');
    const permissions = permissionsMeta ? permissionsMeta.content.split(',') : [];
    const canEvents = permissions.includes('events');
    const canExportEvents = permissions.includes('events.export');
    const canImportEvents = permissions.includes('events.import');

    const [isEventsExportModalOpen, setIsEventsExportModalOpen] = useState(false);
    const [isEventsImportModalOpen, setIsEventsImportModalOpen] = useState(false);

    const isFilterDrawerOpen = useCalendarViewStore(state => state.isFilterDrawerOpen);
    const toggleFilterDrawer = useCalendarViewStore(state => state.toggleFilterDrawer);

    const downloadEventsHandler = () => {
        setIsEventsExportModalOpen(true);
    }

    const importEventsHandler = () => {
        setIsEventsImportModalOpen(true);
    }

    const handleEventsConvert = (file, onFinish) => {
        convertEvents(file, onFinish);
    }

    const handleEventsImport = (file, onFinish, onSuccess) => {
        importEvents(file, onFinish, onSuccess);
    }

    return (
        <Drawer
            sx={{
                width: isLargeScreen ? '256px' : '100%',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: isLargeScreen ? '256px' : '100%',
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={isFilterDrawerOpen}
            onClose={() => toggleFilterDrawer(false)}
        >
            <List
                sx={{ width: '100%', bgcolor: 'background.paper', paddingTop: '0px', }}
                component="nav"
            >
                <ListItemButton
                    sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 10,
                        backgroundColor: 'lightGrey',
                        '&:hover': {
                            backgroundColor: 'whiteSmoke',
                        },
                    }}
                    onClick={() => toggleFilterDrawer(false)}
                >
                    <ListItemText
                        sx={{ textAlign: 'center' }}
                        primary={t("team-calendar.close-filter", "Close")}
                    />
                </ListItemButton>

                <TeamCalendarActiveFilterBox filter={filter} setFilter={setFilter} />

                <TeamCalendarFilterCalendar handleGoToDateClick={handleGoToDateClick} />
                <TeamCalendarMemberFilter canEvents={canEvents} teamMembers={teamMembers} />
                <TeamCalendarGeneralFilter filter={filter} setFilter={setFilter} />

                {canExportEvents &&
                    <ListItemButton sx={{ position: 'relative' }} onClick={downloadEventsHandler}>
                        <ListItemText
                            sx={{ textAlign: 'center' }}
                            primary={t("team-calendar.events-export.title", "Export")}
                        />
                    </ListItemButton>
                }

                {canImportEvents &&
                    <ListItemButton sx={{ position: 'relative' }} onClick={importEventsHandler}>
                        <ListItemText
                            sx={{ textAlign: 'center' }}
                            primary={t("team-calendar.events-import", "Import")}
                        />
                    </ListItemButton>
                }

            </List>

            <EventsExportModal
                isOpen={isEventsExportModalOpen}
                onClose={() => setIsEventsExportModalOpen(false)}
            />

            <ImportModal
                isOpen={isEventsImportModalOpen}
                onClose={() => setIsEventsImportModalOpen(false)}
                title={t("employees.import-events", "Import Events")}
                handleConvert={handleEventsConvert}
                handleImport={handleEventsImport}
            />
        </Drawer>
    )
};

export default TeamCalendarFilterDrawer;