import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    h1 {
        font-size: 2.25rem;
        line-height: 2.5rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    h2 {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    h3 {
        font-size: 1.25rem;
        line-height: 1.75rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    h4 {
        font-size: 1.125rem;
        line-height: 1.75rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    li {
        display: list-item;
        list-style-type: disc;
        margin-left: 48px;
    }
`;

const ReactMarkdownStyledDiv = ({ children }) => {
    return (
        <div className="container">
            <GlobalStyles />
            {children}
        </div>);
}

export default ReactMarkdownStyledDiv;