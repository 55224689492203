import { useState } from 'react';
import useApi from "../../hooks/useApi";
import useUser from "../../hooks/useUser";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ConfirmationModal from "../ConfirmationModal";
import EventDurationSelectionModal from './EventDurationSelectionModal';
import RecurrenceConfirmationModal from './RecurrenceConfirmationModal';
import EventTitleSelectionModal from './EventTitleSelectionModal';
import EventLocationSelectionModal from './EventLocationSelectionModal';
import EventNotesSelectionModal from './EventNotesSelectionModal';
import EventInternalEventTypeSelectionModal from './EventInternalEventTypeSelectionModal';
import EventEmployeeSelectionModal from './EventEmployeeSelectionModal';
import EventPermissionHandler from './EventPermissionHandler';
import EventPropertiesSelectionModal from './EventPropertiesSelectionModal';
import EventEditActionModal from './actions/EventEditActionButton';
import EventDuplicateActionModal from './actions/EventDuplicateActionButton';
import EventConfirmActionButton from './actions/EventConfirmActionButton';
import EventDeleteActionButton from './actions/EventDeleteActionButton';
import EventSaveCancelActions from './EventSaveCancelActions';

const EventInternalModal = ({
    event,                      // Related event, null if no id
    editable, setEditable,      // Check edit mode and switch if edit button clicked
    getStartForDate,            // For calendar date click
    getEndForDate,              // For calendar date click
    duplicate, setDuplicate,    // If click on duplicate
    handleAction,               // Handle any action
}) => {
    const { t } = useTranslation();
    const { getTeamId } = useUser();

    const {
        queryEmployeesSimple,
        queryActiveEventProperties,
        queryActiveInternalEventTypes,
    } = useApi();

    const teamId = getTeamId();

    const {
        canEvents,
    } = EventPermissionHandler(event, null);

    const initialStatus = event?.status;
    const initialEmployees = event?.employees ?? [];
    const initialTitle = event?.title ?? "";
    const initialLocation = event?.location ?? "";
    const initialStart = event?.start && !event?.recurrence
        ? moment(event?.start).format('YYYY-MM-DDTHH:mm')
        : getStartForDate;
    const initialEnd = event?.end && !event?.recurrence
        ? moment(event?.end).format('YYYY-MM-DDTHH:mm')
        : (event?.start && event?.end
            ? getEndForDate(true, moment(event.end).diff(moment(event.start), 'ms'))
            : getEndForDate
        );
    const initialNotes = event?.note ?? "";
    const initialEventProperties = event?.event_properties ?? [];
    const initialInternalEventType = event?.internal_event_type;

    const initialRecurrenceType = event?.recurrence?.type ?? 'none';
    const initialRecurrenceFrequency = event?.recurrence?.frequency ?? 1;
    const initialWeekdaysRecurrence = event?.recurrence?.weekdays ?? [true, true, true, true, true, true, true];
    const initialEndsNever = !event?.recurrence?.endsAt;
    const initialEndsAt = event?.recurrence?.endsAt
        ? moment(event?.recurrence.endsAt).format('YYYY-MM-DD')
        : "";

    const [status, setStatus] = useState(initialStatus);
    const [employees, setEmployees] = useState(initialEmployees);
    const [title, setTitle] = useState(initialTitle);
    const [location, setLocation] = useState(initialLocation);
    const [start, setStart] = useState(initialStart);
    const [end, setEnd] = useState(initialEnd);
    const [notes, setNotes] = useState(initialNotes);
    const [eventProperties, setEventProperties] = useState(initialEventProperties);
    const [internalEventType, setInternalEventType] = useState(initialInternalEventType);

    const [recurrenceType, setRecurrenceType] = useState(initialRecurrenceType);
    const [recurrenceFrequency, setRecurrenceFrequency] = useState(initialRecurrenceFrequency);
    const [weekdaysRecurrence, setWeekdaysRecurrence] = useState(initialWeekdaysRecurrence);
    const [startsAt, setStartsAt] = useState(initialStart);
    const [endsNever, setEndsNever] = useState(initialEndsNever);
    const [endsAt, setEndsAt] = useState(initialEndsAt);

    const [employeesError, setEmployeesError] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [startError, setStartError] = useState(false);
    const [endError, setEndError] = useState(false);
    const [eventPropertiesError, setEventPropertiesError] = useState(false);
    const [internalEventTypeError, setInternalEventTypeError] = useState(false);
    const [weekdaysRecurrenceError, setWeekdaysRecurrenceError] = useState(false);
    const [endsAtError, setEndsAtError] = useState(false);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showRecurrenceConfirmationModal, setShowRecurrenceConfirmationModal] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState("");
    const [confirmationModalData, setConfirmationModalData] = useState(null);

    const { data: employeesOptions = [] } = queryEmployeesSimple(editable);
    const { data: eventPropertiesOptions = [] } = queryActiveEventProperties(editable);
    const { data: internalEventTypesOptions = [] } = queryActiveInternalEventTypes(editable);

    const resetConfirmationModal = () => {
        setShowConfirmationModal(false);
        setShowRecurrenceConfirmationModal(false);
        setConfirmationModalMessage("");
        setConfirmationModalData(null);
    }

    const reset = () => {
        setStatus(initialStatus);
        setEmployees(initialEmployees);
        setTitle(initialTitle);
        setLocation(initialLocation);
        setStart(initialStart);
        setEnd(initialEnd);
        setNotes(initialNotes);
        setEventProperties(initialEventProperties);

        setRecurrenceType(initialRecurrenceType);
        setRecurrenceFrequency(initialRecurrenceFrequency);
        setWeekdaysRecurrence(initialWeekdaysRecurrence);
        setStartsAt(initialStart);
        setEndsNever(initialEndsNever);
        setEndsAt(initialEndsAt);

        setEmployeesError(false);
        setTitleError(false);
        setLocationError(false);
        setStartError(false);
        setEndError(false);
        setEventPropertiesError(false);
        setInternalEventTypeError(false);
        setWeekdaysRecurrenceError(false);
        setEndsAtError(false);

        resetConfirmationModal();
    }

    const validateField = (isError, setError) => {
        setError(isError);
        const isValid = !isError;
        return isValid;
    }

    const validateTimestamps = () => {
        let valid = true;

        if (moment(start).isSameOrAfter(moment(end))) {
            setStartError(true);
            setEndError(true);
            valid = false;
        }

        if (moment(endsAt).isBefore(moment(start), 'day') || moment(endsAt).isBefore(moment(end), 'day')) {
            setEndsAtError(true);
            valid = false;
        }

        return valid;
    }


    const validate = () => {
        let valid = true;

        if (!internalEventType) {
            setInternalEventTypeError(true);
            valid = false;
        } else {
            setInternalEventTypeError(false);
        }

        switch (recurrenceType) {
            case 'daily':
                valid = validateField(!endsNever && endsAt === '', setEndsAtError) && valid;
                break;
            case 'weekly':
                valid = validateField(weekdaysRecurrence.length !== 7, setWeekdaysRecurrenceError) && valid;
                valid = validateField(!endsNever && endsAt === '', setEndsAtError) && valid;
                break;
            default:
                break;
        }

        valid = validateField(start === '', setStartError) && valid;
        valid = validateField(end === '', setEndError) && valid;
        valid = validateTimestamps() && valid;

        return valid;
    }


    const collectRequestRecurrenceData = (data) => {
        switch (recurrenceType) {
            case 'none':
                return;
            case 'daily':
                data.recurrenceType = 'daily';
                data.recurrenceFrequency = recurrenceFrequency;
                data.recurrenceChanged = event
                    && (initialRecurrenceType !== data.recurrenceType
                        || initialRecurrenceFrequency !== data.recurrenceFrequency);
                break;
            case 'weekly':
                data.recurrenceType = 'weekly';
                data.recurrenceFrequency = recurrenceFrequency;
                data.weekdaysRecurrence = weekdaysRecurrence;
                data.recurrenceChanged = event
                    && (initialRecurrenceType !== data.recurrenceType
                        || initialRecurrenceFrequency !== data.recurrenceFrequency
                        || initialWeekdaysRecurrence !== data.weekdaysRecurrence);
                break;
        }

        data.endsAt = endsNever ? null : endsAt;
    }


    const collectRequestData = () => {
        let data = {};

        data.id = duplicate ? null : event?.id;
        data.event_type_id = "INTERNAL";

        data.note = notes;
        data.team_id = teamId;

        data.start = start;
        data.end = end;

        data.all_day = 0;
        data.employee_ids = employees?.map(employee => employee.id) ?? [];
        data.internal_event_type_id = internalEventType?.id ?? '';
        data.location = location;
        data.status = 'NOT_PERFORMED';
        data.title = title;
        data.event_property_ids = eventProperties?.map(eventProperty => (eventProperty.id)) ?? [];

        collectRequestRecurrenceData(data);
        return data;
    }


    const collectRequestDataForAction = (inputAction, inputStatus = null) => {
        return {
            action: inputAction,
            status: inputStatus ?? status,
            id: event.id,
            employees: employees?.map(
                employee => ({
                    id: employee.id,
                    start: employee.start,
                    end: employee.end,
                })
            ) ?? [],
            start: start,
            end: end,
        }
    }


    const handleError422 = (errors) => {
        if (errors.user_id) {
            if (errors.user_id.includes("validation.events.user_id_unique")) {
                setStartError(true);
                setEndError(true);
            } else {
                setEmployeesError(true);
            }
        }
        if (errors.title) {
            setTitleError(true);
        }
        if (errors.location) {
            setLocationError(true);
        }
        if (errors.start) {
            setStartError(true);
        }
        if (errors.end) {
            setEndError(true);
        }
        if (errors.event_property_ids) {
            setEventPropertiesError(true);
        }
        if (errors.internal_event_type) {
            setInternalEventType(true);
        }
    }

    const handleSave = () => {
        const isValid = validate();

        if (!isValid) {
            return;
        }

        const data = collectRequestData();

        if (event?.id && event?.recurrence && recurrenceType != 'none') {
            handleEditRecurringEvent(data);
            return;
        }

        const action = data.id ? 'update' : 'save';
        handleAction({ action: action, data: data }, resetConfirmationModal, null, handleError422);
    }

    const handleEditEvent = () => {
        setEditable(true);
    }

    const handleEditRecurringEvent = (data) => {
        setConfirmationModalMessage(t(
            'team-calendar.confirmation-modal.choose-recurrence-change',
            'On which elements of recurrence do you want to apply the changes?'
        ))
        setConfirmationModalData({ action: 'update', data: data });
        setShowRecurrenceConfirmationModal(true);
    }

    const handleDuplicateEvent = () => {
        setDuplicate(true);
        setStatus(null);
        setStart('');
        setEnd('');
        setRecurrenceType('none');
        setEndsAt('');
        setEditable(true);
    }


    const handleEventConfirmed = () => {
        setConfirmationModalMessage(t('team-calendar.confirmation-modal.message-confirmed', 'Are you sure you want to confirm this appointment?'))
        setConfirmationModalData(collectRequestDataForAction('status', 'CONFIRMED'));
        setShowConfirmationModal(true);
    }


    const handleEventCancelled = () => {
        if (event?.recurrence) {
            setConfirmationModalMessage(t('team-calendar.confirmation-modal.choose-recurrence-deletion', 'Which elements of recurrence do you want to delete?'))
            setConfirmationModalData(collectRequestDataForAction('delete'));
            setShowRecurrenceConfirmationModal(true);
            return;
        }

        setConfirmationModalMessage(t('team-calendar.confirmation-modal.message-cancelled', 'Are you sure you want to delete this appointment?'))
        setConfirmationModalData(collectRequestDataForAction('delete'));
        setShowConfirmationModal(true);
    }


    const isAlreadyPerformed = status === 'PERFORMED' || status === 'CONFIRMED';
    const isAlreadyConfirmed = status === 'CONFIRMED';
    const isPastEvent = moment(start).isBefore(moment());
    const canHaveStatusChange = event?.employee_ids.length != 0 && isPastEvent;


    const mayEditEvent = event?.permissions?.mayEdit;
    const mayDeleteEvent = event?.permissions?.mayDelete;


    const Actions = () => {
        return (
            <div className="flex items-center justify-end">
                <div className='flex flex-grow space-x-2'>
                    <EventDeleteActionButton
                        enabled={mayDeleteEvent}
                        onClick={handleEventCancelled}
                    />
                </div>
                <div className="flex space-x-2">
                    <EventEditActionModal
                        enabled={mayEditEvent}
                        onClick={handleEditEvent}
                    />
                    <EventDuplicateActionModal
                        enabled={mayEditEvent}
                        onClick={handleDuplicateEvent}
                    />
                    <EventConfirmActionButton
                        enabled={!isAlreadyConfirmed && canHaveStatusChange}
                        onClick={handleEventConfirmed}
                    />
                </div>
            </div>
        );
    }


    return (
        <>
            <div className="mt-2">
                {/*<form>*/}
                <div className="space-y-4">
                    {!editable &&
                        <Actions />
                    }

                    <EventEmployeeSelectionModal
                        isMultiSelection={true}
                        isClientEvent={false}
                        editable={editable}
                        employeesOptions={employeesOptions}
                        employees={employees}
                        setEmployees={setEmployees}
                        employeesError={employeesError}
                        isContactSelection={false}
                    />

                    <EventTitleSelectionModal
                        editable={editable}
                        title={title}
                        setTitle={setTitle}
                        titleError={titleError}
                    />

                    <EventLocationSelectionModal
                        editable={editable}
                        location={location}
                        setLocation={setLocation}
                        locationError={locationError}
                    />

                    <EventDurationSelectionModal
                        isRecurrence={!!event?.recurrence}
                        editable={editable} eventTypeId={"INTERNAL"}
                        start={start} setStart={setStart} startError={startError}
                        end={end} setEnd={setEnd} endError={endError}
                        recurrenceType={recurrenceType} setRecurrenceType={setRecurrenceType}
                        recurrenceFrequency={recurrenceFrequency} setRecurrenceFrequency={setRecurrenceFrequency}
                        weekdaysRecurrence={weekdaysRecurrence} setWeekdaysRecurrence={setWeekdaysRecurrence} weekdaysRecurrenceError={weekdaysRecurrenceError}
                        endsNever={endsNever} setEndsNever={setEndsNever}
                        endsAt={endsAt} setEndsAt={setEndsAt} endsAtError={endsAtError}
                        startsAt={startsAt}
                    />

                    <EventPropertiesSelectionModal
                        editable={editable}
                        eventPropertiesOptions={eventPropertiesOptions}
                        selectedEventProperties={eventProperties}
                        setSelectedEventProperties={setEventProperties}
                        selectedEventPropertiesError={eventPropertiesError}
                    />

                    <EventNotesSelectionModal
                        editable={editable}
                        notes={notes}
                        setNotes={setNotes}
                    />

                    <EventInternalEventTypeSelectionModal
                        editable={editable}
                        internalEventTypes={internalEventTypesOptions}
                        internalEventType={internalEventType}
                        setInternalEventType={setInternalEventType}
                        internalEventTypeError={internalEventTypeError}
                    />
                </div>

                <ConfirmationModal
                    isOpen={showConfirmationModal}
                    onClose={resetConfirmationModal}
                    onConfirm={(data, option) => handleAction(data, resetConfirmationModal, option, handleError422)}
                    data={confirmationModalData}
                    message={confirmationModalMessage}
                />
                <RecurrenceConfirmationModal
                    isOpen={showRecurrenceConfirmationModal}
                    onClose={resetConfirmationModal}
                    onConfirm={(data, option) => handleAction(data, resetConfirmationModal, option, handleError422)}
                    data={confirmationModalData}
                    message={confirmationModalMessage}
                />
            </div>

            <EventSaveCancelActions
                editable={editable}
                handleSave={handleSave}
                handleCancel={() => handleAction({ action: 'cancel' }, reset, null, handleError422)}
            />
        </>
    );
};

export default EventInternalModal;
