import { Box, CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material";
import useApi from "../../hooks/useApi";
import moment from "moment";
import { grey } from "@mui/material/colors";
import { Trans } from "react-i18next";
import 'moment/locale/de'; // Import the German locale
import { useCalendarViewStore } from "../../store/CalendarViewStore";

// Set the locale to German
moment.locale('de'); // You can set this globally in your application, if needed

const NotPerformedEvents = () => {
    const { queryDashboardNotPerformedEvents } = useApi();
    const { data: events, isLoading } = queryDashboardNotPerformedEvents();

    const setSelectedId = useCalendarViewStore(state => state.setSelectedId);
    const setSelectedType = useCalendarViewStore(state => state.setSelectedType);
    const toggleEventModal = useCalendarViewStore(state => state.toggleEventModal);

    const handleEventClick = (event) => {
        setSelectedId(event.id);
        setSelectedType(event.event_type_id);
        toggleEventModal(true);
    }

    return <Paper elevation={0} sx={{ height: '400px' }}>
        <Box sx={{ padding: 2 }}>
            <Typography variant="h6"><Trans i18nKey="dashboard.not-performed-events.title">Not Performed Events</Trans>{events?.length > 0 ? " (" + events.length + ")" : ""}</Typography>
        </Box>
        <Divider />
        {isLoading ?
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '300px',
            }}>
                <CircularProgress />
            </Box>
            :
            <Box sx={{ padding: 2, overflow: 'auto', height: '340px' }}>
                {events?.length > 0 ? events.map((event, index) => {
                    return <Box key={index} sx={{ marginBottom: 2, cursor: 'pointer' }} onClick={() => handleEventClick(event)}>
                        <Grid container>
                            <Grid item xs={8} md={6}>
                                <Typography sx={{ overflowWrap: 'break-word' }} variant="body1">{event.title}</Typography>
                                <Typography sx={{ overflowWrap: 'break-word' }} color={grey.A700} variant="body1">{event.location}</Typography>
                            </Grid>
                            <Grid item xs={4} md={6} sx={{ textAlign: 'right' }}>
                                <Typography color={grey.A700}
                                    variant="body1">{moment(event.start).format('dddd, DD.MM.YYYY')}</Typography>
                                <Typography color={grey.A700}
                                    variant="body1">{moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}</Typography>
                            </Grid>

                        </Grid>
                    </Box>
                }) : <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '280px',
                }}>
                    <Typography variant="body1"><Trans i18nKey="dashboard.not-performed-events.no-events">No
                        events</Trans></Typography>
                </Box>
                }
            </Box>
        }
    </Paper>
}

export default NotPerformedEvents;
