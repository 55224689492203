import React from "react";
import {Trans} from "react-i18next";
import GridDropdown from "./GridDropdown";

const TeamCalendarNavigation = ({
    gridButtonHandler,
    handlePrevClick,
    handleTodayClick,
    handleNextClick
}) => {
    return (
        <div className="flex justify-end flex-grow">
            <GridDropdown gridHandler={gridButtonHandler} />
            <div className="md:hidden lg:mx-1 lg:w-px"></div>
            <div className="hidden md:block mx-2 w-px"></div>
            <div className="flex items-center rounded-md shadow-sm flex-shrink-0">
                <button type="button" onClick={handlePrevClick}
                        className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-1 md:pr-2 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50">
                    <span className="sr-only">Previous</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                            aria-hidden="true" className="h-5 w-5">
                        <path fillRule="evenodd"
                                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                clipRule="evenodd"></path>
                    </svg>
                </button>
                <button type="button" onClick={handleTodayClick}
                        className="py-2 block border-t border-b border-gray-300 bg-white px-0 md:px-3.5 text-sm font-medium text-gray-400 hover:bg-gray-50 hover:text-gray-900 focus:relative">
                    <span className="hidden md:block text-gray-700"><Trans i18nKey="team-calendar.today">Today</Trans></span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="sm:block md:hidden w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                    </svg>
                </button>
                <button type="button" onClick={handleNextClick}
                        className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 sm:pl-1 pl-1 md:pl-2 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50">
                    <span className="sr-only">Next</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                            aria-hidden="true" className="h-5 w-5">
                        <path fillRule="evenodd"
                                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                clipRule="evenodd"></path>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default TeamCalendarNavigation;