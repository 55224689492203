import { Trans, useTranslation } from "react-i18next";
import moment from "moment";

const EventDurationSelectionModal = ({
    isRecurrence,
    editable, eventTypeId,
    start, setStart, startError,
    end, setEnd, endError,
    recurrenceType, setRecurrenceType,
    recurrenceFrequency, setRecurrenceFrequency,
    weekdaysRecurrence, setWeekdaysRecurrence, weekdaysRecurrenceError,
    endsNever, setEndsNever,
    endsAt, setEndsAt, endsAtError,
    startsAt,
}) => {
    const { t } = useTranslation();


    const RECURRENCE_OPTIONS = ['none', 'daily', 'weekly'];


    const WEEK_DAYS_OPTIONS = [
        { id: 0, symbol: 'mo' },
        { id: 1, symbol: 'tu' },
        { id: 2, symbol: 'we' },
        { id: 3, symbol: 'th' },
        { id: 4, symbol: 'fr' },
        { id: 5, symbol: 'sa' },
        { id: 6, symbol: 'su' },
    ];


    const onChangeStart = (e) => {
        setStart(e.target.value);
    }


    const onChangedStart = (e) => {
        let start = moment(e.target.value);

        if (end !== "" && start.isBefore(end)) {
            return;
        }

        switch (eventTypeId) {
            case "CLIENT_APPOINTMENT":
            case "INTERNAL":
            case "PUBLIC":
                setEnd(start.add(1, 'hours').format('YYYY-MM-DDTHH:mm'));
                break;
            case "SICK":
            case "HOLIDAY":
            default:
                setEnd(start.format('YYYY-MM-DD'));
                break;
        }
    }


    const onChangeEnd = (e) => {
        setEnd(e.target.value);
    }


    const onChangedEnd = (e) => {
        let end = moment(e.target.value)

        if (start !== "" && end.isAfter(start)) {
            return;
        }

        switch (eventTypeId) {
            case "CLIENT_APPOINTMENT":
            case "INTERNAL":
            case "PUBLIC":
                setStart(end.subtract(1, 'hours').format('YYYY-MM-DDTHH:mm'));
                break;
            case "SICK":
            case "HOLIDAY":
            default:
                setStart(end.format('YYYY-MM-DD'));
                break;
        }
    }


    const onChangeRecurrenceType = (e) => {
        setRecurrenceType(e.target.value);
        setRecurrenceFrequency(1);
    }


    const onChangeRecurrenceFrequency = (e) => {
        setRecurrenceFrequency(e.target.value);
    }


    const onChangeEndsAt = (e) => {
        setEndsAt(e.target.value);
    }


    const onChangeEndsNever = (e) => {
        switch (e.target.id) {
            case 'ends-never':
                setEndsNever(true);
                setEndsAt("")
                break;
            case 'ends-at-date':
                setEndsNever(false);
                break;
            default:
            // Should never happen
        }
    }


    const onClickWeekDay = (dayId) => {
        let newDaysList = [...weekdaysRecurrence];
        newDaysList[dayId] = !newDaysList[dayId];
        setWeekdaysRecurrence(newDaysList);
    }


    return (
        <div className="space-y-4">
            <div
                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                <label htmlFor="start-time"
                    className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                    <Trans
                        i18nKey="team-calendar.event-modal.start.label">Start</Trans>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {editable ?
                        <div>
                            <input
                                type={["CLIENT_APPOINTMENT", "INTERNAL", "PUBLIC"].includes(eventTypeId) ? "datetime-local" : "date"}
                                id="start-time"
                                name="start-time"
                                value={start}
                                onChange={onChangeStart}
                                onBlur={onChangedStart}
                                className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${startError ? "border-red-500" : "border-gray-300"}`}
                            />
                            {startError &&
                                <p className="mt-2 text-sm text-red-600">
                                    <Trans i18nKey="team-calendar.event-modal.error.select-start">
                                        Please select a valid start date in format dd.MM.YYYY HH:mm.
                                    </Trans>
                                </p>
                            }
                        </div>
                        :
                        ["CLIENT_APPOINTMENT", "INTERNAL", "PUBLIC"].includes(eventTypeId) ?
                            <div
                                className="text-sm text-gray-700 sm:mt-px sm:pt-2">{start ? t('team-calendar.event-modal.end.date-time', { date: moment(start).toDate() }) : '-'}</div>
                            :
                            <div
                                className="text-sm text-gray-700 sm:mt-px sm:pt-2">{start ? t('team-calendar.event-modal.end.date', { date: moment(start).toDate() }) : '-'}</div>
                    }
                </div>
            </div>
            <div
                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                <label htmlFor="end-time"
                    className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                    <Trans
                        i18nKey="team-calendar.event-modal.end.label">End</Trans>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {editable ?
                        <div>
                            <input
                                type={["CLIENT_APPOINTMENT", "INTERNAL", "PUBLIC"].includes(eventTypeId) ? "datetime-local" : "date"}
                                id="end-time"
                                name="end-time"
                                value={end}
                                onChange={onChangeEnd}
                                onBlur={onChangedEnd}
                                className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${endError ? "border-red-500" : "border-gray-300"}`}
                            />
                            {endError &&
                                <p className="mt-2 text-sm text-red-600">
                                    <Trans i18nKey="team-calendar.event-modal.error.select-end">
                                        Please select a valid end date in format dd.MM.YYYY HH:mm.
                                    </Trans>
                                </p>
                            }
                        </div>
                        :
                        ["CLIENT_APPOINTMENT", "INTERNAL", "PUBLIC"].includes(eventTypeId) ?
                            <div
                                className="text-sm text-gray-700 sm:mt-px sm:pt-2">{end ? t('team-calendar.event-modal.end.date-time', { date: moment(end).toDate() }) : '-'}</div>
                            :
                            <div
                                className="text-sm text-gray-700 sm:mt-px sm:pt-2">{end ? t('team-calendar.event-modal.end.date', { date: moment(end).toDate() }) : '-'}</div>
                    }
                </div>
            </div>
            {["CLIENT_APPOINTMENT", "INTERNAL", "PUBLIC"].includes(eventTypeId) &&
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                    <label htmlFor="recurrence" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        <Trans i18nKey="team-calendar.event-modal.recurrence.label">Recurrence</Trans>
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        {editable ?
                            <select
                                id="recurrence"
                                name="recurrence"
                                value={recurrenceType}
                                onChange={onChangeRecurrenceType}
                                className="mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none sm:text-sm border-gray-300 capitalize"
                            >
                                {RECURRENCE_OPTIONS.map(option => (
                                    <option key={option} value={option}>
                                        <Trans i18nKey={`team-calendar.event-modal.recurrence.${option}`}>{option}</Trans>
                                    </option>
                                ))}
                            </select>
                            :
                            <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                                <Trans i18nKey={`team-calendar.event-modal.recurrence.${recurrenceType}`}>{recurrenceType}</Trans>
                            </div>
                        }
                    </div>
                </div>
            }
            {recurrenceType === 'daily' &&
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200">
                    <label htmlFor="recurrence-daily-number-input" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        <Trans i18nKey={`team-calendar.event-modal.recurrence.repeat-prefix-label`}>Repeat all</Trans>
                    </label>
                    {editable ?
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                id="recurrence-daily-number-input"
                                type="number"
                                min="1"
                                value={recurrenceFrequency}
                                onChange={onChangeRecurrenceFrequency}
                                className="border-gray-300 mr-2 w-1/2"
                            />
                            <label htmlFor="recurrence-daily-number-input">
                                <Trans i18nKey={`team-calendar.event-modal.recurrence.days-label`}>day(s)</Trans>
                            </label>
                        </div>
                        :
                        <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                            {recurrenceFrequency} <Trans i18nKey={`team-calendar.event-modal.recurrence.days-label`}>day(s)</Trans>
                        </div>
                    }
                </div>
            }
            {recurrenceType === 'weekly' && // weekly
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                    <label htmlFor="recurrence-weekly-number-input" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        <Trans i18nKey={`team-calendar.event-modal.recurrence.repeat-prefix-label`}>Repeat all</Trans>
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2 sm:grid-row-2">
                        {editable ?
                            <div>
                                <input
                                    id="recurrence-weekly-number-input"
                                    type="number"
                                    min="1"
                                    value={recurrenceFrequency}
                                    onChange={onChangeRecurrenceFrequency}
                                    className="border-gray-300 mr-2 w-1/2"
                                />
                                <label htmlFor="recurrence-weekly-number-input">
                                    <Trans i18nKey={`team-calendar.event-modal.recurrence.weeks-label`}>week(s)</Trans>
                                </label>
                            </div>
                            :
                            <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                                {recurrenceFrequency} <Trans i18nKey={`team-calendar.event-modal.recurrence.weeks-label`}>week(s)</Trans>
                            </div>
                        }
                        <div className="sm:grid-rows-2">
                            <div
                                id="recurrence-week-days-selector"
                                className={`mt-4 flex justify-between ${weekdaysRecurrenceError ? "border-solid border-2 border-red-500 p-0.5" : "border-none"}`}
                            >
                                {WEEK_DAYS_OPTIONS.map((day) => (
                                    <button
                                        key={'weekday-' + day.id}
                                        onClick={() => onClickWeekDay(day.id)}
                                        title={'weekday-' + day.id}
                                        disabled={!editable}
                                        className={`w-6 h-6 text-[10px] rounded-full capitalized ${weekdaysRecurrence[day.id] ? "bg-sky-300" : "bg-slate-300"}`}
                                    >
                                        <Trans i18nKey={`team-calendar.event-modal.recurrence.day-${day.id}-symbol`}>{day.symbol}</Trans>
                                    </button>
                                ))}
                            </div>
                            {weekdaysRecurrenceError &&
                                <p className="mt-2 text-sm text-red-600">
                                    <Trans i18nKey="team-calendar.event-modal.error.select-weekdays-recurrence">
                                        Please select at least one day.
                                    </Trans>
                                </p>
                            }
                        </div>
                    </div>
                </div>
            }
            {recurrenceType !== 'none' && isRecurrence &&
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                    <label htmlFor="starts-at"
                        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        <Trans i18nKey={'team-calendar.event-modal.recurrence.starts-at-label'}>Starts</Trans>
                    </label>
                    <div id="starts-at" className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                        <Trans i18nKey={`team-calendar.event-modal.recurrence.at`}>At </Trans>
                        {t('team-calendar.event-modal.start.date', { date: moment(startsAt).toDate() })}
                    </div>
                </div>
            }
            {recurrenceType !== 'none' &&
                <div
                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                    <label htmlFor="ends-at"
                        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-1">
                        <Trans i18nKey={'team-calendar.event-modal.recurrence.ends-at-label'}>Ends</Trans>
                    </label>
                    <div id="ends-at" className="mt-1 sm:mt-0 sm:col-span-2">
                        {editable ?
                            <div className="sm:grid-row-2">
                                <div>
                                    <input type="radio" id="ends-never" name="ends-never-selection" className="border-gray-300 mr-2 leading-5" value={endsNever} checked={endsNever} onChange={onChangeEndsNever} />
                                    <label htmlFor="ends-at-never" className="mr-2 leading-5">
                                        <Trans i18nKey={`team-calendar.event-modal.recurrence.ends-at-never-label`}>Never</Trans>
                                    </label>
                                </div>
                                <div className="sm:grid-col-3 mt-2">
                                    <input type="radio" id="ends-at-date" name="ends-never-selection" className="border-gray-300 mr-2" value={!endsNever} checked={!endsNever} onChange={onChangeEndsNever} />
                                    <label htmlFor="ends-at" className="mr-2">
                                        <Trans i18nKey={`team-calendar.event-modal.recurrence.at_simple`}>At</Trans>
                                    </label>
                                    <input
                                        type="date"
                                        name="ends-at"
                                        value={endsAt}
                                        onChange={onChangeEndsAt}
                                        disabled={endsNever}
                                        className={`mt-1 w-1/2 py-2 px-3 border bg-white shadow-sm focus:outline-none sm:text-sm ${endsAtError ? "border-red-500" : "border-gray-300"}`}
                                    />
                                    {endsAtError &&
                                        <p className="mt-2 text-sm text-red-600">
                                            <Trans i18nKey="team-calendar.event-modal.error.select-ends-at">
                                                Please select a date for end of recurrence in format dd.MM.YYYY HH:mm.
                                            </Trans>
                                        </p>
                                    }
                                </div>
                            </div>
                            :
                            <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                                {endsNever ?
                                    <Trans i18nKey={'team-calendar.event-modal.recurrence.ends-at-never-label'}>Never</Trans>
                                    :
                                    <div>
                                        <Trans i18nKey={`team-calendar.event-modal.recurrence.at`}>At </Trans>
                                        {t('team-calendar.event-modal.end.date', { date: moment(endsAt).toDate() })}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default EventDurationSelectionModal;
