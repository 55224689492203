import {useQuery} from "react-query";
import axios from "axios";
import useRouting from "./useRouting";

const useApi = () => {
    const path = '/api/v1';
    const routing = useRouting();

    const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenMeta ? csrfTokenMeta.content : null;

    const responseSuccessHandler = response => {
        return response;
    };

    const responseErrorHandler = async (error) => {
        if (error && error.response) {
            if (error.response.status === 401) {
                window.location.href = '/login';
            }
            if (error.response.status === 403) {
                window.location.href = '/unauthorized';
            }
        }
        return Promise.reject(error);
    };

    axios.interceptors.response.use(
        response => responseSuccessHandler(response),
        error => responseErrorHandler(error)
    );

    let config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': csrfToken
        },
    };

    let fileUploadConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': csrfToken
        },
    }

    const queryEvents = (employeeId, range, onSuccess = null) => {
        if (!range.start || !range.end) {
            return useQuery(
                ["events", employeeId, range],
                async () => {
                    return [];
                }
            );
        }
        return useQuery(
            ["events", employeeId, range],
            async () => {
                config = {
                    ...config,
                    params: {
                        start: range.start,
                        end: range.end
                    }
                }
                const response = await axios.get(path + "/events", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const queryDashboardEvents = (onSuccess = null) => {
        return useQuery(
            ["dashboard-events"],
            async () => {
                const response = await axios.get(path + "/dashboard/events", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryEventTypes = (onSuccess = null) => {
        return useQuery(
            ["event-types"],
            async () => {
                const response = await axios.get(path + "/events/event-types", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryInternalEventTypes = (pageSize, page, sortField, sortOrder, search = '', onSuccess = null) => {
        return useQuery(
            ["internal-event-types", pageSize, page, sortField, sortOrder, search],
            async () => {
                let url = path + "/internal-event-types?pageSize=" + pageSize + "&page=" + page + '&search=' + search;
                if (sortField && sortOrder) {
                    url += '&sortField=' + sortField + '&sortOrder=' + sortOrder;
                }
                const response =  await axios.get(url, config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryInternalEventType = (id, onSuccess = null) => {
        return useQuery(
            ["internal-event-types", id],
            async () => {
                if (id === 'new') {
                    return {};
                }
                const response =  await axios.get(path + "/internal-event-types/" + id, config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };
    const saveInternalEventType = async (data) =>{
        const response = await axios.post(path + '/internal-event-types' , data, config);
        return response.data;

    };

    const updateInternalEventType = async (data) =>{
        const response = await axios.patch(path + '/internal-event-types/' + data.id, data, config);
        return response.data;
    };

    const saveOrUpdateInternalEventType = async (data) => {
        if (data.id && data.id !== "new") {
            return updateInternalEventType(data);
        } else {
            return saveInternalEventType(data);
        }
    }
    
    const queryActiveInternalEventTypes = (onSuccess = null) => {
        return useQuery(
            ["internal-event-types"],
            async () => {
                const response = await axios.get(path + "/events/internal-event-types", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryTeamMembers = (teamId, onSuccess = null) => {
        return useQuery(
            ["team-members"],
            async () => {
                const response = await axios.get(path + "/teams/" + teamId + "/members", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };


    const queryEmployeesByTeam = (teamId, onSuccess = null) => {
        return useQuery(
            ["employees", teamId],
            async () => {
                const response = await axios.get(path + "/teams/" + teamId + "/members", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryEmployee = (id, onSuccess = null) => {
        return useQuery(
            ["employee", id],
            async () => {
                if (id === 'new') {
                    return {};
                }
                const response = await axios.get(path + "/employees/" + id, config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const saveEmployee = async (data) => {
        const response = await axios.post(path + '/employees', data, config);
        return response.data;

    };

    const updateEmployee = async (data) => {
        const response = await axios.patch(path + '/employees/' + data.id, data, config);
        return response.data;
    };

    const deleteEmployee = async (id) => {
        const response = await axios.delete(path + '/employees/' + id, config);
        return response.data;
    }

    const queryAllEmployees = (onSuccess = null) => {
        return useQuery(
            ["all-employees"],
            async () => {
                const response = await axios.get(path + "/employees", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const queryEventEmployees = (employeeId, onSuccess = null) => {
        return useQuery(
            ["event-employees", employeeId],
            async () => {
                const response = await axios.get(path + "/events/employees", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        )
    }

    const queryEmployees = (
        pageSize = null, 
        page = null, 
        sortField = null, 
        sortOrder= null, 
        search = null, 
        filter = null,
        onSuccess = null,
    ) => {
        return useQuery(
            ["employees", pageSize, page, sortField, sortOrder, search, filter],
            async () => {
                config = {
                    ...config,
                    params: {
                        pageSize: pageSize,
                        page: page,
                        search: search,
                        sortField: sortField,
                        sortOrder: sortOrder,
                        filter: filter,
                    }
                }
                const response = await axios.get(path + "/employees", config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryEmployeeNames = (onSuccess = null) => {
        return useQuery(
            ["employeeNames"],
            async () => {
                const response = await axios.get(path + "/employees/names", config);
                return response;
            },
            {onSuccess: onSuccess}
        )
    }

    const saveOrUpdateEmployee = async (data) => {
        if (data.id && data.id !== "new") {
            return updateEmployee(data);
        } else {
            return saveEmployee(data);
        }
    }

    const queryRoles = (onSuccess = null) => {
        return useQuery(
            ["roles"],
            async () => {
                const response = await axios.get(path + "/employees/roles", config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const queryPatients = (employeeId, teamId, onSuccess = null) => {
        if (!employeeId && !teamId || parseInt(employeeId) === -1 && !teamId) {
            return useQuery(
                ["patients"],
                async () => {
                    const response = await axios.get(path + "/clients", config);
                    return response.data.data;
                },
                {onSuccess: onSuccess}
            );
        }
        if (!employeeId || parseInt(employeeId) === -1) {
            return useQuery(
                ["patients-team", teamId],
                async () => {
                    const response = await axios.get(path + "/teams/" + teamId + "/clients", config);
                    return response.data.data;
                },
                {onSuccess: onSuccess}
            );
        }

        return useQuery(
            ["patients", employeeId],
            async () => {
                const response = await axios.get(path + "/employees/" + employeeId + "/clients", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };


    const queryPrimaryPatientsIds = (employeeId, onSuccess = null) => {
        if (!employeeId) {
            return useQuery(
                ["primaryPatientsIds", employeeId],
                async () => {
                    return [];
                }
            );
        }

        return useQuery(
            ["primaryPatientsIds", employeeId],
            async () => {
                const response = await axios.get(path + "/employees/" + employeeId + "/clients/primary/ids", config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    }


    const queryServices = (onSuccess = null) => {
        return useQuery(
            ["services"],
            async () => {
                const response = await axios.get(path + "/services", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryEligibleServices = (budgetId, onSuccess = null) => {
            return useQuery(
                ["services", budgetId],
                async () => {
                    const response = await axios.get(path + "/budgets/" + budgetId + "/services", config);
                    return response.data.data;
                },
                {onSuccess: onSuccess,
                 enabled: budgetId != -1
                }
            );
    };

    const queryBudgets = (onSuccess = null) => {
        return useQuery(
            ["budgets"],
            async () => {
                const response = await axios.get(path + "/budgets", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryActiveBudgets = (client, onSuccess = null) => {
      return useQuery(
                client ? ["active-budgets", client.id] : [],
                async () => {
                    const response = await axios.get(path + "/clients/" + client.id + "/active-budgets/", config);
                    return response.data;
                },
                {onSuccess: onSuccess,
                 enabled: !!client
                }
            );
    }


    const queryExistingClientNumbers = (onSuccess = null) => {
        return useQuery(
                  ["client-numbers"],
                  async () => {
                      const response = await axios.get(path + "/clients/client-numbers", config);
                      return response.data;
                  },
                  {
                    onSuccess: onSuccess,
                  }
              );
      }


    const queryClientBudgets = (clientId, pageSize, page, sortField, sortOrder, search = '', onSuccess = null) => {
        return useQuery(
            ["client-budgets", clientId, pageSize, page, sortField, sortOrder, search],
            async () => {
                let url = path + "/clients/" + clientId + "/budgets?pageSize=" + pageSize + "&page=" + page + '&search=' + search;
                if (sortField && sortOrder) {
                    url += '&sortField=' + sortField + '&sortOrder=' + sortOrder;
                }
                const response = await axios.get(url, config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryClientBudget = (clientId, budgetId, onSuccess = null) => {
        return useQuery(
            ["client-budget", clientId, budgetId],
            async () => {
                if (budgetId === 'new') {
                    return {};
                }
                const response = await axios.get(path + "/clients/" + clientId + "/budgets/" + budgetId, config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const saveClientBudget = async (clientId, data) => {
        const response = await axios.post(path + '/clients/' + clientId + '/budgets/create', data, config);
        return response.data;
    }
    const updateClientBudget = async (clientId, data) => {
        const response = await axios.patch(path + '/clients/' + clientId + '/budgets/' + data.id, data, config);
        return response.data;
    }
    const saveOrUpdateClientBudget = async (clientId, data) => {
        if (data.id && data.id !== "new") {
            return updateClientBudget(clientId, data)
        } else {
            return saveClientBudget(clientId, data);
        }
    }

    const queryInvoiceReceivers = (clientId,pageSize, page, sortField, sortOrder, search = '', onSuccess = null) => {
        return useQuery(
            ["invoice-receivers",clientId, pageSize, page, sortField, sortOrder, search],
            async () => {
                let url = path + "/clients/" + clientId + "/invoice-receivers?pageSize=" + pageSize + "&page=" + page + '&search=' + search;
                if (sortField && sortOrder) {
                    url += '&sortField=' + sortField + '&sortOrder=' + sortOrder;
                }
                const response =  await axios.get(url, config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryInvoiceReceiver = (clientId,invoiceReceiverId, onSuccess = null) => {
        return useQuery(
            ["client-budget", clientId, invoiceReceiverId],
            async () => {
                if (invoiceReceiverId === 'new') {
                    return {};
                }
                const response =  await axios.get(path + "/clients/" + clientId + "/invoice-receivers/" + invoiceReceiverId, config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const saveInvoiceReceiver = async (clientId,data) =>{
        const response = await axios.post(path + '/clients/' +clientId+ '/invoice-receivers' , data, config);
        return response.data;
    }
    const updateInvoiceReceiver = async (clientId,data) =>{
        const response = await axios.patch(path + '/clients/' +clientId+ '/invoice-receivers/' + data.id, data, config);
        return response.data;
    }
    const saveOrUpdateInvoiceReceiver = async (clientId,data) => {
        if (data.id && data.id !== "new") {
            return updateInvoiceReceiver(clientId,data)
        } else {
            return saveInvoiceReceiver(clientId,data);
        }
    }

    const queryClientProtocols = (clientId, pageSize, page, sortField, sortOrder, search = '', onSuccess = null) => {
        return useQuery(
            ["client-protocols", clientId, pageSize, page, sortField, sortOrder, search],
            async () => {
                config = {
                    ...config,
                    params: {
                        pageSize: pageSize,
                        page: page,
                        search: search,
                        sortField: sortField,
                        sortOrder: sortOrder
                    }
                }
                const response = await axios.get(path + "/clients/" + clientId + "/protocols", config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const queryClientProtocol = (clientId, protocolId, onSuccess = null) => {
        return useQuery(
            ["client-protocol", clientId, protocolId],
            async () => {
                if (protocolId === 'new') {
                    return {};
                }
                const response = await axios.get(path + "/clients/" + clientId + "/protocols/" + protocolId, config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const saveClientProtocol = async (clientId, data) => {
        const response = await axios.post(path + '/clients/' + clientId + '/protocols/', data, config);
        return response.data;
    }

    const updateClientProtocol = async (clientId, data) => {
        const response = await axios.patch(path + '/clients/' + clientId + '/protocols/' + data.id, data, config);
        return response.data;
    }

    const saveOrUpdateClientProtocol = async (clientId, data) => {
        if (data.id && data.id !== "new") {
            return updateClientProtocol(clientId, data);
        } else {
            return saveClientProtocol(clientId, data);
        }
    }


    const queryPhyisicians = (onSuccess = null) => {
        return useQuery(
            ["physicians"],
            async () => {
                const response =  await axios.get(path + "/physicians", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const queryTeams = (onSuccess = null) => {
        return useQuery(
            ["teams"],
            async () => {
                const response =  await axios.get(path + "/teams", config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };


    const saveOrUpdateEvent = async (data) => {
        if (data.id) {
            return updateEvent(data);
        } else {
            return saveEvent(data);
        }
    }

    const saveEvent = async (data) => {
        const response = await axios.post(path + '/events', data, config);
        return response.data;
    };

    const updateEvent = async (data) => {
        const response = await axios.patch(path + '/events/' + data.id, data, config);
        return response.data;
    };

    const queryEvent = (id, onSuccess = null) => {
        if (!id) {
            return useQuery(
                ["event", id],
                async () => {
                    return null;
                }
            );
        }

        return useQuery(
            ["event", id],
            async () => {
                const response =  await axios.get(path + "/events/" + id, config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const updateEventStatus = async (eventId, status, employeeIds, start, end) => {
        const response = await axios.post(
            path + '/events/' + eventId + '/update-status', 
            { status: status, employee_ids: employeeIds, subStart: start, subEnd: end }, 
            config
        );
        return response.data;
    };

    const deleteEvent = async (eventId) => {
        const response = await axios.delete(path + '/events/' + eventId, config);
        return response?.data;
    }

    const deleteRecurringEvent = async (eventId, option, start, end) => {
        const response = await axios.post(path + '/events/' + eventId + '/update-status',
            {
                action: 'delete',
                option: option,
                start: start,
                end: end,
            },
            config
        );
        return response?.data;
    }

    const queryInstitutions = (pageSize, page, sortField, sortOrder, search = '', onSuccess = null) => {
        return useQuery(
            ["institutions", pageSize, page, sortField, sortOrder, search],
            async () => {
                let url = path + "/institutions?pageSize=" + pageSize + "&page=" + page + '&search=' + search;
                if (sortField && sortOrder) {
                    url += '&sortField=' + sortField + '&sortOrder=' + sortOrder;
                }
                const response =  await axios.get(url, config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryInstitution = (id, onSuccess = null) => {
        return useQuery(
            ["institution", id],
            async () => {
                if (id === 'new') {
                    return {};
                }
                const response =  await axios.get(path + "/institutions/" + id, config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };
    const saveInstitution = async (data) =>{
        const response = await axios.post(path + '/institutions' , data, config);
        return response.data;

    };

    const updateInstitution = async (data) =>{
        const response = await axios.patch(path + '/institutions/' + data.id, data, config);
        return response.data;
    };

    const saveOrUpdateInstitution = async (data) => {
        if (data.id && data.id !== "new") {
            return updateInstitution(data);
        } else {
            return saveInstitution(data);
        }
    }

    const queryCareDegrees = (onSuccess = null) => {
        return useQuery(
            ["care-degrees"],
            async () => {
                const response =  await axios.get(path + "/care-degrees", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const queryClients = (
        pageSize = null, 
        page = null, 
        sortField = null, 
        sortOrder= null, 
        search = null, 
        filter = null,
        onSuccess = null,
    ) => {
        return useQuery(
            ["clients", pageSize, page, sortField, sortOrder, search, filter],
            async () => {
                config = {
                    ...config,
                    params: {
                        pageSize: pageSize,
                        page: page,
                        search: search,
                        sortField: sortField,
                        sortOrder: sortOrder,
                        filter: filter,
                    }
                }
                const response =  await axios.get(path + "/clients", config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryClientContacts = (
        clientId, 
        pageSize = null, 
        page = null, 
        sortField = null, 
        sortOrder = null, 
        search = null, 
        filter = null,
        onSuccess = null
    ) => {
        return useQuery(
            ["client-contacts", clientId, pageSize, page, sortField, sortOrder, search, filter],
            async () => {
                config = {
                    ...config,
                    params: {
                        pageSize: pageSize,
                        page: page,
                        search: search,
                        sortField: sortField,
                        sortOrder: sortOrder,
                        filter: filter,
                    }
                }
                const response =  await axios.get(path + "/clients/" + clientId + "/contacts", config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryClientContact = (clientId,contactId, onSuccess = null) => {
        return useQuery(
            ["client-contact", clientId, contactId],
            async () => {
                if (contactId === 'new') {
                    return {};
                }
                const response =  await axios.get(path + "/clients/" + clientId + "/contacts/" + contactId, config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const saveClientContact = async (clientId,data) =>{
        const response = await axios.post(path + '/clients/' +clientId+ '/contacts/create' , data, config);
        return response.data;
    }
    const updateClientContact = async (clientId,data) =>{
        const response = await axios.patch(path + '/clients/' +clientId+ '/contacts/' + data.id, data, config);
        return response.data;
    }
    const saveOrUpdateClientContact = async (clientId,data) => {
        if (data.id && data.id !== "new") {
            return updateClientContact(clientId,data);
        } else {
            return saveClientContact(clientId,data);
        }
    }


    const queryClient = (id, onSuccess = null) => {
        return useQuery(
            ["client", id],
            async () => {
                if (id === 'new') {
                    return {};
                }
                const response =  await axios.get(path + "/clients/" + id, config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const saveClient = async (data) =>{
        const response = await axios.post(path + '/clients' , data, config);
        return response.data;
    }

    const updateClient = async (data) =>{
        const response = await axios.patch(path + '/clients/' + data.id, data, config);
        return response.data;
    }

    const saveOrUpdateClient = async (data) => {
        if (data.id && data.id !== "new") {
            return updateClient(data);
        } else {
            return saveClient(data);
        }
    }

    const queryClientsWithoutEvents = (onSuccess = null) => {
        return useQuery(
            ["clients-without-events"],
            async () => {
                const response =  await axios.get(path + "/clients/without-events", config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const downloadEvents = (data) => {
        let url = path + '/events/export?start=' + data.start + '&end=' + data.end;
        if (data.type) {
            url += '&type=' + data.type;
        }
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    const exportEmployees = (data) => {
        let url = path + '/employees/export';
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    const convertEmployees = async (file, onFinish) => {
        let response = null;

        try {
            const formData = new FormData();
            formData.append('file', file);

            response = await axios.post(path + '/employees/convert' , formData, fileUploadConfig);

            if (response.error) {
                return;
            }
            
            downloadFile(response?.data, 'employees_conversion.csv');
        } finally {
            onFinish(response?.error);
        } 
    }


    const importEmployees = async (file, onFinish, onSuccess, refetch) => {
        let response = null;

        try {
            const formData = new FormData();
            formData.append('file', file);

            response = await axios.post(path + '/employees/import' , formData, fileUploadConfig);
        } finally {
            const error = response?.data?.error;

            if (error) {
                onFinish(error);
                return;
            }

            refetch();
            onFinish(null);
            onSuccess();
        }
    }


    const exportClients = (data) => {
        let url = path + '/clients/export';
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }


    const convertClients = async (file, onFinish) => {
        let response = null;

        try {
            const formData = new FormData();
            formData.append('file', file);

            response = await axios.post(path + '/clients/convert' , formData, fileUploadConfig);

            if (response.error) {
                return;
            }
            
            downloadFile(response?.data, 'clients_conversion.csv');
        } finally {
            onFinish(response?.error);
        } 
    }


    const importClients = async (file, onFinish, onSuccess, refetch) => {
        let response = null;

        try {
            const formData = new FormData();
            formData.append('file', file);

            response = await axios.post(path + '/clients/import' , formData, fileUploadConfig);
        } finally {
            const error = response?.data?.error;

            if (error) {
                onFinish(error);
                return;
            }

            refetch();
            onFinish(null);
            onSuccess();
        }
    }

    const convertEvents = async (file, onFinish) => {
        let response = null;

        try {
            const formData = new FormData();
            formData.append('file', file);

            response = await axios.post(path + '/events/convert' , formData, fileUploadConfig);

            if (response.error) {
                return;
            }
            
            downloadFile(response?.data, 'events_conversion.csv');
        } finally {
            onFinish(response?.error);
        } 
    }

    const importEvents = async (file, onFinish, onSuccess, refetch) => {
        let response = null;

        try {
            const formData = new FormData();
            formData.append('file', file);

            response = await axios.post(path + '/events/import' , formData, fileUploadConfig);
        } finally {
            const error = response?.data?.error;

            if (error) {
                onFinish(error);
                return;
            }

            refetch();
            onFinish(null);
            onSuccess();
        }
    }


    const downloadFile = (data, filename) => {
        if (!data) {
            return;
        }

        const fileBlob = new Blob(
            [data], 
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
        );

        const link = document.createElement('a');
        link.href = URL.createObjectURL(fileBlob);
        link.download = filename;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
    }


    // const downloadEvents = async (data) => {
    //     const response = await axios.post(path + '/events/export', data, config);
    //     return response.data;
    // };

    return {
        queryEvents,
        queryDashboardEvents,
        queryEventTypes,
        queryActiveInternalEventTypes,
        queryTeamMembers,
        queryTeams,
        queryEmployee,
        queryEmployeesByTeam,
        queryAllEmployees,
        queryEventEmployees,
        queryEmployees,
        queryEmployeeNames,
        deleteEmployee,
        saveOrUpdateEmployee,
        queryRoles,
        queryPatients,
        queryPrimaryPatientsIds,
        queryServices,
        queryEligibleServices,
        queryBudgets,
        queryActiveBudgets,
        queryExistingClientNumbers,
        queryClientBudgets,
        queryClientBudget,
        saveOrUpdateClientBudget,
        queryInvoiceReceivers,
        queryInvoiceReceiver,
        saveOrUpdateInvoiceReceiver,
        queryClientProtocols,
        queryClientProtocol,
        saveOrUpdateClientProtocol,
        queryPhyisicians,
        saveEvent,
        updateEvent,
        saveOrUpdateEvent,
        queryEvent,
        updateEventStatus,
        deleteEvent,
        deleteRecurringEvent,
        queryInstitutions,
        queryInstitution,
        updateInstitution,
        saveInstitution,
        saveOrUpdateInstitution,
        queryInternalEventTypes,
        queryInternalEventType,
        saveOrUpdateInternalEventType,
        queryCareDegrees,
        queryClients,
        queryClient,
        queryClientContacts,
        queryClientContact,
        saveOrUpdateClientContact,
        saveOrUpdateClient,
        queryClientsWithoutEvents,
        downloadEvents,
        exportEmployees,
        exportClients,
        convertEmployees,
        importEmployees,
        convertClients,
        importClients,
        convertEvents,
        importEvents,
    };
};
export default useApi;
