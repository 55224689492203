import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Trans } from "react-i18next";
import { useCalendarViewStore } from '../../store/CalendarViewStore';

const EventTypeConfirmationModal = ({

}) => {


    const EVENT_TYPES = [
        'client_appointment', 
        'group',
        'internal', 
        'holiday', 
        'sick', 
        'public'
    ];


    const setSelectedDate = useCalendarViewStore(state => state.setSelectedDate);
    const isEventTypeConfirmationModalOpen = useCalendarViewStore(state => state.isEventTypeConfirmationModalOpen);
    const toggleEventTypeConfirmationModal = useCalendarViewStore(state => state.toggleEventTypeConfirmationModal);
    const toggleEventModal = useCalendarViewStore(state => state.toggleEventModal);
    const setSelectedId = useCalendarViewStore(state => state.setSelectedId);
    const setSelectedType = useCalendarViewStore(state => state.setSelectedType);


    const onSelect = (eventType) => {
        setSelectedId(null);
        setSelectedType(eventType);

        toggleEventTypeConfirmationModal(false);
        toggleEventModal(true);
    }


    const onClose = () => {
        setSelectedDate(null);
        
        toggleEventTypeConfirmationModal(false);
    }


    return (
        <div>
            <Transition.Root appear show={isEventTypeConfirmationModalOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-20 inset-0 overflow-y-auto"
                    onClose={() => onClose(null)}
                >
                    <div className="flex items-center justify-center min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <span
                            className="inline-block align-bottom bg-white rounded-lg px-4 py-3 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                        >
                            <div className="mt-2 mb-4">
                                <Trans i18nKey={"team-calendar.event-type-selection"}>
                                    Which type of event do you want to create?
                                </Trans>
                            </div>
                            <div className="mt-4 flex flex-col justify-evenly">

                                {EVENT_TYPES.map((eventType) => (
                                    <button
                                        key={"event-type." + eventType}
                                        type="button"
                                        className="my-1 flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={() => onSelect(eventType)}
                                    >
                                        <Trans i18nKey={"team-calendar.event-types." + eventType}>
                                            {eventType}
                                        </Trans>
                                    </button>
                                ))}

                                <button
                                    type="button"
                                    className="my-1 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                    onClick={() => onClose(null)}
                                >
                                    <Trans i18nKey="team-calendar.confirmation-modal.cancel">Cancel</Trans>
                                </button>

                            </div>
                        </span>
                    </div>
                </Dialog>
            </Transition.Root>

        </div>
    );


};

export default EventTypeConfirmationModal;
