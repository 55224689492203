import useApi from "../../hooks/useApi";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React from "react";
import FormTable from "./../forms/FormTable";
import ImportModal from "../ImportModal";
import { useEmployeesViewStore } from "../../store/EmployeesViewStore";
import { getGridStringOperators } from "@mui/x-data-grid";

const Employees = () => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { queryEmployees, exportEmployees, convertEmployees, importEmployees } = useApi();

    const stringOperators = getGridStringOperators().filter((operator => ['contains'].includes(operator.value)));

    const [columns] = React.useState([
        {
            field: 'name',
            headerName: 'Name',
            editable: false,
            flex: 1,
            headerClassName: 'data-grid-header',
            valueGetter: (params) => {
                return params.row.person
                    ? `${params.row.person.last_name} ${params.row.person.first_name}`
                    : '';
            },
            renderCell: (params) => {
                const fullName = params.row.person
                    ? `${params.row.person.last_name} ${params.row.person.first_name}`
                    : '';
                return <b>{fullName}</b>;
            },
            filterOperators: stringOperators,
        },
        {
            field: 'team',
            headerName: 'Team',
            editable: false,
            flex: 1,
            headerClassName: 'data-grid-header',
            valueGetter: (params) => {
                return params.row.teams ? params.row.teams.map(team => team.name).join('|') : '';
            },
            renderCell: (params) => {
                const teams = params.row.teams ? params.row.teams.map(team => team.name).join('<br>') : '';
                return <div dangerouslySetInnerHTML={{ __html: teams }} />;
            },
            filterOperators: stringOperators,
        },
        {
            field: 'email',
            headerName: t('employees.company-address-email', 'Company email address (login)'),
            editable: false,
            flex: 1,
            headerClassName: 'data-grid-header',
            valueGetter: (params) => {
                return params.row.email ?? '';
            },
            filterOperators: stringOperators,
        },
        {
            field: 'phone',
            headerName: t('employees.phone', 'Phone'),
            editable: false,
            flex: 1,
            headerClassName: 'data-grid-header',
            valueGetter: (params) => {
                const phone = params.row.phone;
                const phoneNumber = phone?.phone_number ?? '';
                return phoneNumber;
            },
            filterOperators: stringOperators,
        },
        {
            field: 'roles',
            headerName: t('employees.roles.label', 'Roles'),
            editable: false,
            flex: 1,
            sortable: false,
            headerClassName: 'data-grid-header',
            valueGetter: (params) => {
                return params.row.roles ? params.row.roles.map(role => t(`employees.role.${role.name}`, role.name)).join('|') : '';
            },
            renderCell: (params) => {
                const roles = params.row.roles ? params.row.roles.map(role => t(`employees.role.${role.name}`, role.name)).join('<br>') : '';
                return <div dangerouslySetInnerHTML={{ __html: roles }} />;
            },
            filterOperators: stringOperators,
        },
    ]);

    const [searchText, setSearchText] = React.useState(searchParams.get('search') ?? '');

    const sortModel = useEmployeesViewStore(state => state.sortModels);
    const updateSortModels = useEmployeesViewStore(state => state.updateSortModels);

    const filterModelItems = useEmployeesViewStore(state => state.filterModelItems);
    const updateFilterModelItems = useEmployeesViewStore(state => state.updateFilterModelItems);

    const paginationModel = useEmployeesViewStore(state => state.paginationModel);
    const updatePaginationModel = useEmployeesViewStore(state => state.updatePaginationModel);

    const { data: employees = null, isLoading, refetch: employeesRefetch } = queryEmployees(
        paginationModel.pageSize,
        paginationModel.page + 1,
        sortModel.length > 0 && sortModel[0].field ? sortModel[0].field : null,
        sortModel.length > 0 && sortModel[0].sort ? sortModel[0].sort : null,
        searchText,
        filterModelItems,
    );

    const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);

    const columnVisibility = useEmployeesViewStore(state => state.columnVisibility);
    const updateColumnVisibility = useEmployeesViewStore(state => state.updateColumnVisibility);
    const handleScreenSizeChange = useEmployeesViewStore(state => state.handleScreenSizeChange);

    const handleRowClick = (params) => {
        navigate(`/employees/${params.id}`);
    }

    const handleNewEmployeesClick = () => {
        navigate(`/employees/new`);
    }

    const handleExportEmployeesClick = () => {
        exportEmployees();
    }

    const handleImportEmployeesClick = () => {
        setIsImportModalOpen(true);
    }


    const handleEmployeesConvert = (file, onFinish) => {
        convertEmployees(file, onFinish);
    }


    const handleEmployeesImport = (file, onFinish, onSuccess) => {
        importEmployees(file, onFinish, onSuccess, employeesRefetch);
    }

    return (
        <>
            <FormTable
                createButtonLabel={t("employees.add-employee", "New Employee")}
                columns={columns}
                data={employees}
                paginationModel={paginationModel}
                setPaginationModel={updatePaginationModel}
                onSearchTextChange={setSearchText}
                onRowClick={handleRowClick}
                onNewClick={handleNewEmployeesClick}
                sortModel={sortModel}
                setSortModel={updateSortModels}
                filterModel={{ items: filterModelItems }}
                setFilterModel={updateFilterModelItems}
                isLoading={isLoading}
                columnVisibility={columnVisibility}
                setColumnVisibility={updateColumnVisibility}
                createPermissions={["employees.create", "employees"]}
                onExportClick={handleExportEmployeesClick}
                exportButtonLabel={t("employees.export-employees", "Export Employees")}
                exportPermissions={["employees.export", "employees"]}
                onImportClick={handleImportEmployeesClick}
                importButtonLabel={t("employees.import-employees", "Import Employees")}
                importPermissions={["employees.import", "employees"]}
                onScreenSizeChange={handleScreenSizeChange}
            />
            <ImportModal
                isOpen={isImportModalOpen}
                onClose={() => setIsImportModalOpen(false)}
                title={t("employees.import-employees", "Import Employees")}
                handleConvert={handleEmployeesConvert}
                handleImport={handleEmployeesImport}
            />
        </>
    );
}

export default Employees;
