import { useTranslation } from "react-i18next";
import useConfig from "../../hooks/useConfig";
import moment from "moment";

/**
 * Helper file to update input events based on input team selection.
 * Input events is either an array of events or an array where events are grouped by users, i.e., events can occur multiple times.
 * @returns helper function.
 */
const useTeamCalendarEventUpdater = () => {
    const { eventColors } = useConfig();


    const RRULE_WEEKDAYS = [
        { id: 0, symbol: 'MO' },
        { id: 1, symbol: 'TU' },
        { id: 2, symbol: 'WE' },
        { id: 3, symbol: 'TH' },
        { id: 4, symbol: 'FR' },
        { id: 5, symbol: 'SA' },
        { id: 6, symbol: 'SU' },
    ];


    const filterEventsByTeamSelection = (events, teamSelection, assignedEmployees, assignedPatients) => {
        const considerUnassignedEvents = teamSelection.includes(-1);
        const considerOtherEvents = assignedEmployees.length > 0 && teamSelection.includes(-2);
        const considerPublicEvents = teamSelection.includes(-3);

        let filteredEvents = [];
        let knownEventIds = [];

        for (const event of events) {
            if (knownEventIds.includes(event.id) || !event.employee_ids) {
                continue;
            }

            knownEventIds.push(event.id);

            const userIdIsSelected = teamSelection.find(userId => event.employee_ids.includes(userId)) && event.event_type_id !== "PUBLIC";
            const unassignedEventIsInTeamSelection = considerUnassignedEvents && event.employee_ids.length === 0;
            const otherEventIsInTeamSelection = considerOtherEvents
                && !userIdIsSelected
                && event.employee_ids.length > 0
                && !assignedEmployees.find(employee => event.employee_ids.find(employee_id => parseInt(employee_id) === parseInt(employee.id)))
                && assignedPatients.find(id => event.client_ids.find(client_id => parseInt(client_id) === parseInt(id)));
            const publicEventIsInSelection = considerPublicEvents && event.event_type_id === "PUBLIC";

            if (userIdIsSelected || unassignedEventIsInTeamSelection || otherEventIsInTeamSelection || publicEventIsInSelection) {
                filteredEvents.push(event);
            }
        }

        return filteredEvents;
    }


    const updateEventsForEmployeeUser = (events, teamSelection, assignedEmployees, assignedPatients) => {
        const filteredEvents = filterEventsByTeamSelection(events, teamSelection, assignedEmployees, assignedPatients);
        let result = [];

        for (const event of filteredEvents) {
            event.eventColor = event.event_type_id === "INTERNAL"
                ? eventColors.internal
                : event.employee_colors.length > 0 ? event.employee_colors[0] : eventColors.notAssigned;

            event.color = 'transparent';
            event.allDay = event.all_day === 1;
            result.push(event);
        }

        return result;
    }


    const updateEventsByTeamSelection = (events, teamSelection, assignedEmployees, assignedPatients, range = null) => {
        if (!events) {
            return;
        }

        let result = updateEventsForEmployeeUser(events, teamSelection, assignedEmployees, assignedPatients);

        for (let event of result) {
            if (event.allDay && !event.hasAllDayAdaption) {
                event.end = moment(event.end).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
                event.hasAllDayAdaption = true;
            }
        }

        if (range) {
            const rangeStart = moment(range.start);
            const rangeEnd = moment(range.end).add(1, 'day');

            for (let event of result) {
                if (!event.allDay) {
                    continue;
                }

                const eventStart = moment(event.start);
                const eventEnd = moment(event.end);

                event.start = moment.max([rangeStart, eventStart]).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
                event.end = moment.min([rangeEnd, eventEnd]).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
            }
        }

        return result;
    }


    const parseWeekdays = (weekdays) => {
        let parsedWeekdays = [];

        for (let i = 0; i <= 6; i++) {
            if (weekdays[i]) {
                parsedWeekdays.push(RRULE_WEEKDAYS[i].symbol);
            }
        }

        return parsedWeekdays;
    }


    function paddingZeros(number, resultLength) {
        let output = number.toString();

        while (output.length < resultLength) {
            output = "0" + output;
        }

        return output;
    }


    const timeDifferenceAsDurationString = (startString, endString) => {
        const startInMillis = Date.parse(startString);
        const endInMillis = Date.parse(endString);

        const differenceInMinutes = Math.abs((endInMillis - startInMillis) / 1000 / 60);

        const hoursCount = Math.floor(differenceInMinutes / 60);
        const minutesCount = differenceInMinutes % 60;

        return paddingZeros(hoursCount, 2) + ":" + paddingZeros(minutesCount, 2);
    }


    const datetime = (dateString, timeString) => {
        if (!dateString) {
            return null;
        }

        const date = new Date(Date.parse(dateString));
        const time = timeString ? new Date(Date.parse(timeString)) : null;

        const years = date.getFullYear();
        const months = date.getMonth();
        const days = date.getDate();

        const hours = time?.getHours() ?? date.getHours() ?? 0;
        const minutes = time?.getMinutes() ?? date.getMinutes() ?? 0;
        const seconds = time?.getSeconds() ?? date.getSeconds() ?? 0;

        return new Date(Date.UTC(years, months, days, hours, minutes, seconds));
    };


    const buildExclusionDates = (exclusions, isAllDay, start) => {
        let exclusionDates = [];

        for (const exceptionDate of exclusions) {
            if (isAllDay) {
                exclusionDates.push(exceptionDate);
                continue;
            }

            try {
                const time = moment(start).local().format('HH:mm');
                const date = moment(exceptionDate).local().format('YYYY-MM-DD')
                exclusionDates.push(date + ' ' + time);
            } catch (exception) {
                exclusionDates.push(exceptionDate);
            }
        }

        return exclusionDates;
    }


    const updateEventsByRecurrence = (events) => {
        if (!events) {
            return;
        }

        for (const event of events) {
            if (!event.recurrence) {
                continue;
            }

            event.duration = timeDifferenceAsDurationString(event.start, event.end);
            const recurrence = event.recurrence;

            if (recurrence.exceptions) {
                event.exdate = buildExclusionDates(recurrence.exceptions, event.allDay, event.start);
            }

            switch (recurrence.type) {
                case 'none':
                    continue;
                case 'daily':
                    const rruleDaily = {
                        freq: 'daily',
                        interval: recurrence.frequency,
                        dtstart: datetime(event.start),
                        tzid: 'Europe/Berlin',
                        until: datetime(recurrence.endsAt, event.end),
                    };
                    event.rrule = rruleDaily;
                    continue;
                case 'weekly':
                    const rruleWeekly = {
                        freq: 'weekly',
                        interval: recurrence.frequency,
                        byweekday: parseWeekdays(recurrence.weekdays),
                        dtstart: datetime(event.start),
                        tzid: 'Europe/Berlin',
                        until: datetime(recurrence.endsAt, event.end),
                    }
                    event.rrule = rruleWeekly;
                    continue;
            }
        }
    }


    const filterEvents = (events, filter) => {
        if (!filter || filter.size === 0) {
            return events;
        }

        const hasTitleInFilter = (event) => {
            return !filter.get('title')
                || (event.title && event.title.toLowerCase().includes(filter.get('title').toLowerCase()));
        }

        const hasBudgetInFilter = (event) => {
            return !filter.get('budgetIds')
                || (event.budget_type_ids
                    && filter.get('budgetIds')
                        .find(budgetId => event.budget_type_ids
                            .find(budget_type_id => budget_type_id && parseInt(budget_type_id) === parseInt(budgetId))));
        }

        const hasEventTypeInFilter = (event) => {
            if (!filter.get('eventTypeIds')) {
                return true;
            }

            if (!event.event_type_id) {
                return false;
            }

            const matchingEventTypeIds = filter.get('eventTypeIds').filter((eventTypeId) =>
                eventTypeId.toLowerCase() === event.event_type_id.toLowerCase());

            return matchingEventTypeIds.length > 0;
        }

        const hasInternalEventTypeInFilter = (event) => {
            return !filter.get('internalEventTypeIds')
                || (event.internal_event_type_id
                    && filter.get('internalEventTypeIds').includes(event.internal_event_type_id));
        }

        const hasEventPropertyInFilter = (event) => {
            return !filter.get('eventPropertyIds')
                || (event.event_property_ids
                    && filter.get('eventPropertyIds')
                        .some(eventPropertyId => event.event_property_ids.includes(eventPropertyId)));
        }

        const hasPatientInFilter = (event) => {
            return !filter.get('patientIds')
                || (event.client_ids
                    && filter.get('patientIds')
                        .find(patientId => event.client_ids
                            .find(client_id => parseInt(client_id) === parseInt(patientId))));
        }

        const hasServiceInFilter = (event) => {
            return !filter.get('serviceIds')
                || (event.service_id && filter.get('serviceIds').includes(event.service_id));
        }

        return events.filter((event) => {
            return hasEventTypeInFilter(event)
                && hasBudgetInFilter(event)
                && hasInternalEventTypeInFilter(event)
                && hasEventPropertyInFilter(event)
                && hasPatientInFilter(event)
                && hasServiceInFilter(event)
                && hasTitleInFilter(event);
        });
    }


    return {
        updateEventsByTeamSelection,
        updateEventsByRecurrence,
        filterEvents,
    }
}

export default useTeamCalendarEventUpdater;