import useApi from "../../hooks/useApi";
import { useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import useRouting from "../../hooks/useRouting";
import FormTable from "../forms/FormTable";

const InternalEventTypes = (props) => {
    const isSmallScreen = window.innerWidth < 600;
    const [columnVisibility, setColumnVisibility] = React.useState({
        description: !isSmallScreen
    });
    const [searchParams] = useSearchParams();
    const routing = useRouting();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { queryInternalEventTypes } = useApi();
    const [columns, setColumns] = React.useState([
        {
            field: 'type',
            headerName: t('internal-event-types.type.label', 'Type'),
            renderCell: (params) => {
                return t(`internal-event-types.type.${params.value}`, params.value);
            },
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header'
        },
        {
            field: 'description',
            headerName: t('internal-event-types.description.label', 'Description'),
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header'
        },
        {
            field: 'active',
            headerName: t('internal-event-types.active.label', 'Active'),
            renderCell: (params) => {
                return params.value == 1 ? "Ja" : "Nein";
            },
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header'
        },
    ]);
    const [sortModel, setSortModel] = React.useState(searchParams.get('sortField') && searchParams.get('searchOrder') ? [{
        field: searchParams.get('sortField'),
        sort: searchParams.get('sortOrder') ?? 'asc'
    }] : []);
    const [searchText, setSearchText] = React.useState(searchParams.get('search') ?? '');
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : props.paginationModel.pageSize,
        page: searchParams.get('page') ? parseInt(searchParams.get('page')) : props.paginationModel.page,
    });
    const { data: internalEventTypes = null, isLoading } = queryInternalEventTypes(
        paginationModel.pageSize,
        paginationModel.page + 1,
        sortModel.length > 0 ? sortModel[0].field : null,
        sortModel.length > 0 ? sortModel[0].sort : null,
        searchText);

    const handleRowClick = (params, event) => {
        navigate(`/internal-event-types/${params.id}`);
    }

    const handleNewInternalEventTypeClick = (event) => {
        navigate(`/internal-event-types/new`);
    }

    const handleScreenSizeChange = (isSmallScreen) => {
        let newColumnVisibility = {
            ...columnVisibility,
        }
        newColumnVisibility.description = !isSmallScreen;
        setColumnVisibility(newColumnVisibility);
    }

    return (
        <FormTable
            createButtonLabel={t("internal-event-types.new-internal-event-type", "New InternalEventType")}
            columns={columns}
            data={internalEventTypes}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            onSearchTextChange={setSearchText}
            onRowClick={handleRowClick}
            onNewClick={handleNewInternalEventTypeClick}
            sortModel={sortModel}
            setSortModel={setSortModel}
            onScreenSizeChange={handleScreenSizeChange}
            isLoading={isLoading}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
        />
    );
}

export default InternalEventTypes;
