import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import moment from "moment";

const transformIntoDateString = (dateWithTimeInUTC) => {
    const timezoneOffset = (new Date(dateWithTimeInUTC)).getTimezoneOffset() * 60000;
    const currentDateWithTime = new Date(dateWithTimeInUTC - timezoneOffset);
    return currentDateWithTime.toISOString().slice(0, 10);
}

const CalendarViewStore = (set, get) => ({
    viewDate: transformIntoDateString(new Date()),
    viewType: 'week',
    gridType: 'timeGridWeek',
    hiddenDays: [],
    isSelectedInitialized: false,
    selected: [],
    updateViewDate: (newDate) => set(() => (
        { viewDate: transformIntoDateString(newDate.valueOf()), }
    )),
    updateGrid: (
        newViewType,
        newGridType,
        newHiddenDays,
    ) => set(() => (
        {
            viewType: newViewType,
            gridType: newGridType,
            hiddenDays: newHiddenDays,
        }
    )),
    initializeSelected: (selection) => {
        if (get().isSelectedInitialized) {
            return;
        }

        set(() => (
            {
                isSelectedInitialized: true,
                selected: [...selection],
            }
        ));
    },
    updateSelected: (selection) => set(() => (
        {
            isSelectedInitialized: true,
            selected: [...selection],
        }
    )),
    handleSelection: (teamMemberId) => set((state) => (
        {
            selected: state.selected.includes(teamMemberId)
                ? state.selected.filter(id => id !== teamMemberId)
                : [...state.selected, teamMemberId]
        }
    )),

})

export const useCalendarViewStore = create(
    persist(
        CalendarViewStore,
        {
            name: 'calendar-storage'
        }
    )
);
