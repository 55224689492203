import { Trans } from "react-i18next";

const EventBudgetSelectionModal = ({
    editable,
    budgetId,
    setBudgetId,
    budgets,
    budgetIdError,
}) => {

    
    const sortedBudgets = budgets.sort(function (budget1, budget2) {
        if (budget1.name < budget2.name) {
            return -1;
        }
        if (budget1.name > budget2.name) {
            return 1;
        }
        return 0;
    });


    const getBudget = (id) => {
        return budgets 
            ? budgets.find((budget) => budget.id === id) 
            : null;
    }


    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">

            <label
                htmlFor="budget"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
                <Trans i18nKey="team-calendar.event-modal.budget">Budget</Trans>
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2">
                {editable ?
                    <div className="sm:grid-row-2">
                        <select
                            id="budget"
                            name="budget"
                            value={budgetId}
                            onChange={(e) => setBudgetId(e.target.value)}
                            className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none sm:text-sm ${budgetIdError ? "border-red-500" : "border-gray-300"}`}
                        >
                            <option disabled value={-1}>
                                <Trans i18nKey="team-calendar.event-modal.select-budget">select a budget</Trans>
                            </option>
                            {sortedBudgets && sortedBudgets.map(budget => (
                                <option key={budget.id}
                                    value={budget.id}>{budget.name}</option>
                            ))}
                        </select>

                        {budgetIdError &&
                            <p className="mt-2 text-sm text-red-600">
                                <Trans i18nKey="team-calendar.event-modal.error.select-budget">
                                    Please select a budget.
                                </Trans>
                            </p>
                        }
                    </div>
                    :
                    <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                        {budgets && budgetId && parseInt(budgetId) !== -1 ? getBudget(budgetId).name : '-'}
                    </div>
                }
            </div>
        </div>
    )
};

export default EventBudgetSelectionModal;
