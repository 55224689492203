import { Trans, useTranslation } from "react-i18next";
import Autocomplete from "../Autocomplete";
import RedCrossRemoveButton from "../general/RedCrossRemoveButton";

const EventEmployeeSelectionModal = ({
    isMultiSelection,
    isClientEvent,
    editable,
    employeesOptions,
    employees,
    setEmployees,
    employeesError,
    isContactSelection
}) => {
    const { t } = useTranslation();


    const sortEmployees = (options) => {
        return options?.sort((a, b) => {
            if (a.person && b.person) {
                if (a.person.last_name < b.person.last_name)
                    return -1;
                if (a.person.last_name > b.person.last_name)
                    return 1;
                if (a.person.first_name < b.person.first_name)
                    return -1;
                if (a.person.first_name > b.person.first_name)
                    return 1;
            }
            return 0;
        }) ?? [];
    }


    const renderEmployee = (option) => {
        return option && option.person
            ? `${option.person.last_name} ${option.person.first_name}`
            : "";
    }


    const filterEmployees = (options, inputValue) => {
        return options.filter((option) => {
            if (inputValue === "") {
                return true;
            }

            let found = false;

            inputValue.split(" ").filter(Boolean).forEach((word) => {
                if (option.person && option.person.first_name.toLowerCase().startsWith(word.toLowerCase()) ||
                    option.person && option.person.last_name.toLowerCase().startsWith(word.toLowerCase())
                ) {
                    found = true;
                }
            });

            return found;
        });
    };


    const getEmployeeName = (employee) => {
        return employee
            ? `${employee.person.last_name} ${employee.person.first_name}`
            : <Trans i18nKey="team-calendar.not-assigned">Not assigned</Trans>;
    }


    if (isMultiSelection) {
        const filteredEmployeesOptions = employeesOptions?.filter(
            emp => !employees.some(employee => emp.id === employee.id)
        ) ?? [];

        return (
            <div
                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200">
                <label htmlFor="employees"
                    className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                    {isContactSelection
                        ? <Trans i18nKey="team-calendar.event-modal.contact">contact</Trans>
                        : <Trans i18nKey="team-calendar.event-modal.employees">employees</Trans>
                    }
                </label>

                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {editable ? (
                        <div>
                            <div className="mt-2">
                                {sortEmployees(employees).map(employee => (
                                    <div key={"employee_" + employee.id}
                                        className="flex items-center justify-between py-1">
                                        {renderEmployee(employee)}
                                        <RedCrossRemoveButton
                                            onClick={() => { setEmployees(employees.filter(emp => emp.id !== employee.id)); }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <Autocomplete
                                options={filteredEmployeesOptions}
                                error={employeesError}
                                onSelect={(employee) => {
                                    setEmployees(employee ? [...employees, employee] : [])}
                                }
                                renderOption={renderEmployee}
                                renderValue={renderEmployee}
                                filterFunction={filterEmployees}
                                sortFunction={sortEmployees}
                                placeholder={t('team-calendar.event-modal.add-employee')}
                                resetAfterSelection={true}
                            />
                        </div>
                    ) : (
                        <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                            {sortEmployees(employees).map(employee => (
                                <option key={employee.id} value={employee.id}>
                                    {renderEmployee(employee)}
                                </option>
                            ))}
                        </div>
                    )
                    }
                </div>
            </div>
        )
    }


    return (
        <div
            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
            <label htmlFor="employee"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                {isContactSelection
                    ? <Trans i18nKey="team-calendar.event-modal.contact">contact</Trans>
                    : <Trans i18nKey="team-calendar.event-modal.employee">Employee</Trans>
                }
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                {editable ?
                    <div className="sm:grid-row-2">
                        <select
                            id="employee"
                            name="employee"
                            value={employees.length > 0 ? (employees[0]?.id ?? -1) : -1}
                            onChange={(e) => {
                                const employee = employeesOptions
                                    ?.find(employee => parseInt(employee.id) === parseInt(e.target.value));
                                setEmployees(employee ? [employee] : []);
                            }}
                            className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${employeesError ? "border-red-500" : "border-gray-300"}`}
                        >
                            {isClientEvent
                                ?
                                <option value={-1}>
                                    <Trans i18nKey="team-calendar.event-modal.all-employees-of-team">all employees of team</Trans>
                                </option>
                                :
                                <option disabled value={-1}>
                                    {isContactSelection
                                        ? <Trans i18nKey="team-calendar.event-modal.select-contact">select a contact</Trans>
                                        : <Trans i18nKey="team-calendar.event-modal.select-employee">select an employee</Trans>
                                    }
                                </option>
                            }
                            {sortEmployees(employeesOptions).map(employee => (
                                <option key={employee.id} value={employee.id}>
                                    {renderEmployee(employee)}
                                </option>
                            ))}
                        </select>
                        {employeesError &&
                            <p className="mt-2 text-sm text-red-600">
                                {isContactSelection
                                    ? <Trans i18nKey="team-calendar.event-modal.error.select-contact">
                                        Please select a contact.
                                    </Trans>
                                    : <Trans i18nKey="team-calendar.event-modal.error.select-employee">
                                        Please select an employee.
                                    </Trans>
                                }
                            </p>
                        }
                    </div>
                    :
                    <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                        {employees.length > 0 
                            ? getEmployeeName(employees[0]) 
                            : getEmployeeName(null)
                        }
                    </div>
                }
            </div>
        </div>
    )


};

export default EventEmployeeSelectionModal;