import {
    Autocomplete,
    Box, Breadcrumbs,
    Button,
    CircularProgress, DialogActions, DialogContent, DialogTitle,
    Grid,
    Link, Paper,
    Stack,
    TextField, Typography
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import useApi from "../../hooks/useApi";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import useConfig from "../../hooks/useConfig";
import useRouting from "../../hooks/useRouting";
import "./Institution.css";
import FormAutocomplete from "../forms/FormAutocomplete";
import FormTextField from "../forms/FormTextField";
import Dialog from '@mui/material/Dialog';
import ConfirmationModal from "../ConfirmationModal";
import RedCrossRemoveButton from "../general/RedCrossRemoveButton";

const Institution = () => {

    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const {
        gridItemSxLabel,
        gridItemSxLabelMultiline,
        gridItemSxLabelMultilineEditable,
        gridItemSxValue,
        gridItemSxValueEditable
    } = useConfig();


    const payingAgentTypes = [
        { id: 1, value: '01', label: t('institutions.paying-agent.type.01', '01') },
        { id: 2, value: '02', label: t('institutions.paying-agent.type.02', '02') },
        { id: 3, value: '03', label: t('institutions.paying-agent.type.03', '03') },
        { id: 9, value: '09', label: t('institutions.paying-agent.type.09', '09') },
    ];


    const types = [
        {
            value: 'CARE_INSURANCE',
            label: t('institutions.type.CARE_INSURANCE', 'CARE_INSURANCE')
        },
        {
            value: 'PRIVATE_CARE_INSURANCE',
            label: t('institutions.type.PRIVATE_CARE_INSURANCE', 'PRIVATE_CARE_INSURANCE')
        },
        {
            value: 'HEALTH_INSURANCE',
            label: t('institutions.type.HEALTH_INSURANCE', 'HEALTH_INSURANCE')
        },
        {
            value: 'PRIVATE_HEALTH_INSURANCE',
            label: t('institutions.type.PRIVATE_HEALTH_INSURANCE', 'PRIVATE_HEALTH_INSURANCE')
        },
        {
            value: 'PAYMENT_AGENT',
            label: t('institutions.type.PAYMENT_AGENT', 'PAYMENT_AGENT')
        },
        {
            value: 'OTHERS',
            label: t('institutions.type.OTHERS', 'OTHERS')
        },
    ];


    const { queryInstitution, queryInstitutions, queryBudgets, saveOrUpdateInstitution } = useApi();
    const { goToInstitution, goToInstitutions } = useRouting();


    const { data: institution = null, isLoading, refetch } = queryInstitution(id);
    const { data: allBudgets = null } = queryBudgets();
    const { data: institutions = [] } = queryInstitutions();


    const [editable, setEditable] = useState(id === 'new');
    const [disabled, setDisabled] = useState(false);

    const [shortname, setShortname] = useState('');
    const [type, setType] = useState(null);
    const [ikNumber, setIkNumber] = useState('');
    const [description, setDescription] = useState('');
    const [validFrom, setValidFrom] = useState(null);
    const [validUntil, setValidUntil] = useState(null);
    const [replacement, setReplacement] = useState([]);
    const [errReplacement, setErrReplacement] = useState('');
    const [errShortname, setErrShortname] = useState('');
    const [errType, setErrType] = useState('');
    const [errIkNumber, setErrIkNumber] = useState('');
    const [errDescription, setErrDescription] = useState('');
    const [errValidFrom, setErrValidFrom] = useState('');
    const [errValidUntil, setErrValidUntil] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [openReplacementConfirmationModal, setOpenReplacementConfirmationModal] = useState(false);

    const [openPayingAgentModal, setOpenPayingAgentModal] = useState(false);
    const [payingAgents, setPayingAgents] = useState([]);
    const [payingAgent, setPayingAgent] = useState(null);
    const [payingAgentIndex, setPayingAgentIndex] = useState(null);
    const [payingAgentType, setPayingAgentType] = useState(null);
    const [payingAgentBudget, setPayingAgentBudget] = useState(null);
    const [errPayingAgent, setErrPayingAgent] = useState('');
    const [errPayingAgentType, setErrPayingAgentType] = useState('');
    const [errPayingAgentBudget, setErrPayingAgentBudget] = useState('');

    const [addresses, setAddresses] = useState([]);
    const [addressIndex, setAddressIndex] = useState(null);
    const [street, setStreet] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [budgets, setBudgets] = useState([]);
    const [freeBudgets, setFreeBudgets] = useState([]);
    const [errStreet, setErrStreet] = useState('');
    const [errZip, setErrZip] = useState('');
    const [errCity, setErrCity] = useState('');
    const [errBudget, setErrBudget] = useState('');


    const currentGridItemSxLabelMultiline = editable
        ? gridItemSxLabelMultilineEditable
        : gridItemSxLabelMultiline;
    const currentGridItemSxValue = editable
        ? gridItemSxValueEditable
        : gridItemSxValue;


    const init = () => {
        setShortname(institution.shortname);
        setType(types.find((type) => type.value === institution.type));
        setIkNumber(institution.ik_number ?? '');
        setDescription(institution.description ?? '');

        const validFromValue = institution.valid_from
            ? DateTime.fromFormat(institution.valid_from, 'yyyy-MM-dd')
            : null;
        setValidFrom(validFromValue);

        const validUntilValue = institution.valid_to
            ? DateTime.fromFormat(institution.valid_to, 'yyyy-MM-dd')
            : null;
        setValidUntil(validUntilValue);

        setPayingAgents(institution.institution_linking_rules);
        setAddresses(institution.addresses);
    }


    useEffect(() => {
        if (institution && institution.id) {
            init();
        }
    }, [institution]);

    const getFreeBudgets = () => {
        let result = [...allBudgets];
        for (let address of addresses) {
            for (let addressBudget of address.budgets) {
                result = result.filter((budget) => budget.id !== addressBudget.id);
            }
        }
        return result;
    }

    useEffect(() => {
        if (allBudgets && addresses) {
            setFreeBudgets(getFreeBudgets());
        }
    }, [allBudgets, addresses]);

    const handleStreetChange = (event) => {
        setStreet(event.target.value);
    }

    const handleZipChange = (event) => {
        setZip(event.target.value);
    }

    const handleCityChange = (event) => {
        setCity(event.target.value);
    }

    const validateAddress = () => {
        let valid = true;
        if (zip === "") {
            setErrZip(t('institutions.error.zip'));
            valid = false;

        } else {
            setErrZip(false);
        }
        if (city === "") {
            setErrCity(t('institutions.error.city'));
            valid = false;

        } else {
            setErrCity('');
        }
        if (street === "") {
            setErrStreet(t('institutions.error.street'));
            valid = false;

        } else {
            setErrStreet('');
        }
        if (budgets == null) {
            setErrBudget(t('institutions.error.budget'))
        } else {
            setErrBudget('')
        }
        return valid;
    }

    const handleAddAddressValues = () => {
        if (validateAddress()) {
            const newAddress = {
                address: {
                    street: street,
                    zip: zip,
                    city: city
                },
                budgets: budgets
            };
            if (addressIndex === null) {
                setAddresses([...addresses, newAddress]);
            } else {
                let addressesCopy = [...addresses];
                addressesCopy[addressIndex] = newAddress;
                setAddresses(addressesCopy);
            }

            setOpenModal(false);
            setStreet('');
            setCity('');
            setZip('');
            setBudgets([]);
        }
    }

    const handlePayingAgentModalClose = () => {
        setOpenPayingAgentModal(false);
    }

    const handleRemovePayingAgent = (index) => {
        let payingAgentsCopy = [...payingAgents];
        payingAgentsCopy.splice(index, 1);
        setPayingAgents(payingAgentsCopy);
    }

    const handleRemoveAddress = (index) => {
        let addressesCopy = [...addresses];
        addressesCopy.splice(index, 1);
        setAddresses(addressesCopy);
    }

    const handleTypeChange = (event) => {
        setType(event.target.value);
        setErrType('');
    }

    const handleIkNumberChange = (event) => {
        setIkNumber(event.target.value);
        setErrIkNumber('');
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
        setErrDescription('');
    }

    const handleShortnameChange = (event) => {
        setShortname(event.target.value);
        setErrShortname('');
    }
    const handleCancel = () => {
        setEditable(false);
        if (institution && institution.id) {
            init();
        } else {
            goToInstitutions();
            setShortname('');
            setType(null);
            setIkNumber('');
            setDescription('');
            setValidFrom(null);
            setValidUntil(null);
            setAddresses([]);
            setPayingAgents([]);
        }
    };

    const handleAddPayingAgent = () => {
        setPayingAgent(null);
        setPayingAgentIndex(null);
        setPayingAgentType(null);
        setPayingAgentBudget(null);
        setOpenPayingAgentModal(true);
    }

    const validatePayingAgent = () => {
        let valid = true;
        if (payingAgent == null) {
            setErrPayingAgent(t("institutions.error.paying-agent"));
            valid = false;

        } else {
            setErrPayingAgent('');
        }
        if (payingAgentType == null) {
            setErrPayingAgentType(t("institutions.error.paying-agent-type"));
            valid = false;

        } else {
            setErrPayingAgentType('');
        }
        if (payingAgentBudget == null) {
            setErrPayingAgentBudget(t("institutions.error.budget"));
            valid = false;

        } else {
            setErrPayingAgentBudget('');
        }
        return valid;
    }

    const handleAddPayingAgentValues = () => {
        if (validatePayingAgent()) {
            const newPayingAgent = {
                institution_id: institution.id,
                paying_agent: payingAgent,
                institution_paying_agent_id: payingAgent.id,
                type: payingAgentType !== '' ? payingAgentType.value : null,
                budget_type: payingAgentBudget?.code
            };

            if (payingAgentIndex === null) {
                setPayingAgents([...payingAgents, newPayingAgent]);
            } else {
                let payingAgentsCopy = [...payingAgents];
                payingAgentsCopy[payingAgentIndex] = newPayingAgent;
                setPayingAgents(payingAgentsCopy);
            }

            setOpenPayingAgentModal(false);
            setPayingAgent(null);
            setPayingAgentType(null);
            setPayingAgentBudget(null);
        }
    }

    const handleAddAddress = () => {
        setAddressIndex(null);
        setCity('');
        setStreet('');
        setZip('');
        setBudgets([]);
        setOpenModal(true);
    };

    const onClickAddress = (institutionAddress, index) => {
        setAddressIndex(index);
        setCity(institutionAddress.address.city);
        setStreet(institutionAddress.address.street);
        setZip(institutionAddress.address.zip);
        setBudgets(institutionAddress.budgets);
        setOpenModal(true);
    }

    const onClickPayingAgent = (linkingRule, index) => {
        setPayingAgent(linkingRule.paying_agent);
        setPayingAgentIndex(index);

        const payingAgentType = payingAgentTypes.find(payingAgent => payingAgent.value === linkingRule.type);
        setPayingAgentType(payingAgentType);
        const budget = allBudgets.find(b => b.code === linkingRule.budget_type);
        setPayingAgentBudget(budget);

        setErrPayingAgent('');
        setErrPayingAgentType('');
        setErrPayingAgentBudget('');

        setOpenPayingAgentModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleClosePayingAgentModal = () => {
        setOpenPayingAgentModal(false);
    }

    const obtainData = () => {
        let data = {};

        if (id) {
            data.id = id;
        }

        data.shortname = shortname;
        data.type = type?.value ?? null;
        data.ik_number = parseInt(ikNumber);
        data.description = description;
        data.valid_from = validFrom?.toFormat('yyyy-MM-dd') ?? null;
        data.valid_to = validUntil?.toFormat('yyyy-MM-dd') ?? null;
        data.addresses = addresses;
        data.payingAgents = payingAgents;
        data.replacement = replacement
            .map(institution => institution.id)
            .filter(institution_id => institution_id !== parseInt(id));

        return data;
    }


    const handleSave = (isConfirmed = false) => {
        if (!isConfirmed && replacement && replacement.length > 0) {
            setOpenReplacementConfirmationModal(true);
            return;
        }

        setDisabled(true);
        const data = obtainData();

        saveOrUpdateInstitution(data).then((response) => {
            setDisabled(false);
            setEditable(false);
            if (data.id === 'new' && response.data.id) {
                goToInstitution(response.data.id);
            } else {
                refetch();
            }
        }).catch((error) => {
            setDisabled(false);
            if (error.response?.status === 422 || error.response?.status === 500) {
                const errors = error.response.data?.errors;
                if (errors) {
                    setErrShortname(errors.shortname ?? '');
                    setErrType(errors.type ?? '');
                    setErrValidFrom(errors.valid_from ?? '');
                    setErrValidUntil(errors.valid_until ?? '');
                    setErrIkNumber(errors.ik_number ?? '');
                    setErrReplacement(errors.replacement ?? '');
                }
            }
        });

        setOpenReplacementConfirmationModal(false);
    };


    return (
        <Box sx={{ padding: 2 }}>
            {isLoading ?
                <Stack sx={{ flexGrow: 1, padding: 2 }} alignItems="center">
                    <CircularProgress />
                </Stack>
                :
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                        <NavLink underline="hover" color="inherit" to="/institutions">
                            <Trans i18nKey="institutions.title">Institutions</Trans>
                        </NavLink>
                        <Typography color="text.primary">{institution?.shortname}</Typography>
                    </Breadcrumbs>
                    <Paper elevation={0} sx={{ marginBottom: 2, padding: 2, maxWidth: 1024 }}>
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Box sx={{ paddingLeft: 2, display: 'flex', justifyContent: 'flex-start' }}>
                                    {editable && institution?.id &&
                                        <Typography variant="h5"><Trans i18nKey={"institutions.edit-title"}>Edit
                                            institution</Trans></Typography>
                                    }
                                    {editable && !institution?.id &&
                                        <Typography variant="h5"><Trans
                                            i18nKey={"institutions.create-title"}>Create
                                            institution</Trans></Typography>
                                    }
                                    {!editable &&
                                        <Typography variant="h5">{institution?.shortname}</Typography>
                                    }

                                </Box>
                            </Grid>
                            <Grid item xs={4} justifyContent="flex-end">
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {!editable &&
                                        <Button
                                            sx={{ textTransform: 'none' }}
                                            variant="outlined"
                                            size="small"
                                            disableElevation
                                            onClick={() => setEditable(!editable)}
                                        >
                                            <Trans i18nKey="institutions.edit">Edit</Trans>
                                            {/*<EditOutlinedIcon />*/}
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={0} sx={{ padding: 2, maxWidth: 1024 }}>
                        <Grid container alignItems="center">

                            {editable && <>
                                <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                                    <Trans i18nKey="institutions.shortname.label">Name</Trans>
                                </Grid>
                                <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                    <TextField
                                        disabled={disabled}
                                        id="shortname"
                                        fullWidth
                                        /*    multiline*/
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={shortname}
                                        placeholder={t('institutions.shortname.placeholder', 'Enter name')}
                                        InputProps={{ style: { fontSize: 14, backgroundColor: '#fff' } }}
                                        onChange={handleShortnameChange}
                                        error={!!errShortname}
                                        helperText={errShortname}
                                    />
                                </Grid>
                            </>}

                            <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                                <Trans i18nKey={"institutions.type.label"}>Type</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                {editable ?
                                    <Autocomplete
                                        disabled={disabled}
                                        size="small"
                                        value={type}
                                        onChange={(event, newValue) => {
                                            setType(newValue);
                                        }}
                                        options={types}
                                        getOptionLabel={(option) => option.label}
                                        defaultValue={null}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        filterSelectedOptions
                                        sx={{
                                            backgroundColor: '#fff',
                                            fontSize: 14,
                                            '& input': {
                                                fontSize: 14,
                                            },
                                            '.MuiAutocomplete-listbox': {
                                                fontSize: '14px !important',
                                            }
                                        }}
                                        ListboxProps={{
                                            sx: { fontSize: 14 },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={t('institutions.type.placeholder', 'Select type')}
                                                error={!!errType}
                                                helperText={errType}
                                            />
                                        )}
                                    />
                                    :
                                    <Trans
                                        i18nKey={"institutions.type." + institution?.type}>{institution?.type}</Trans>
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                                <Trans i18nKey="institutions.ik-number.label">IK number</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="ik-number"
                                        fullWidth
                                        /*multiline*/
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={ikNumber}
                                        placeholder={t('institutions.ik-number.placeholder', 'Enter IK number')}
                                        InputProps={{ style: { fontSize: 14 } }}
                                        sx={{ fontSize: 14, backgroundColor: '#fff' }}
                                        onChange={handleIkNumberChange}
                                        error={!!errIkNumber}
                                        helperText={errIkNumber}
                                    />
                                    :
                                    institution?.ik_number
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                                <Trans i18nKey="institutions.description.label">Description</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="description"
                                        fullWidth
                                        /*multiline*/
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={description}
                                        placeholder={t('institutions.description.placeholder', 'Enter description')}
                                        InputProps={{
                                            style: {
                                                fontSize: 14, border: 'none',
                                                outline: 'none',
                                            }
                                        }}
                                        sx={{ fontSize: 14, backgroundColor: '#fff' }}
                                        onChange={handleDescriptionChange}
                                        error={!!errDescription}
                                        helperText={errDescription}
                                    />
                                    :
                                    institution?.description
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                                <Trans i18nKey="institutions.valid-from.label">Valid from</Trans>
                            </Grid>

                            <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                {editable ?
                                    <DesktopDatePicker
                                        disabled={disabled}
                                        disableToolbar
                                        inputVariant="filled"
                                        format="dd.MM.yyyy"
                                        id="valid-from"
                                        value={validFrom}
                                        placeholder={t('institutions.valid-from.placeholder', 'Enter valid from')}
                                        sx={{
                                            backgroundColor: '#fff', '& input': {
                                                fontSize: 14,
                                            },
                                            '& input::placeholder': {
                                                fontSize: 14
                                            }
                                        }}
                                        onChange={(date) => {
                                            setValidFrom(date)
                                        }}
                                        slotProps={{ textField: { size: 'small', error: !!errValidFrom, helperText: errValidFrom } }}
                                        renderInput={(params) => <TextField variant="filled" {...params}
                                            inputProps={
                                                {
                                                    ...params.inputProps,
                                                    placeholder: "dd.MM.yyyy"
                                                }
                                            } placeholder="dd.MM.yyyy" />}
                                    />
                                    :
                                    institution?.valid_from ? t('institutions.valid-from.date', { date: moment(institution?.valid_from).toDate() }) : '-'
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                                <Trans i18nKey="institutions.valid-until.label">Valid until</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                {editable ?
                                    <DesktopDatePicker
                                        disabled={disabled}
                                        disableToolbar
                                        inputVariant="filled"
                                        format="dd.MM.yyyy"
                                        id="valid-until"
                                        value={validUntil}
                                        placeholder={t('institutions.valid-until.placeholder', 'Enter valid until')}
                                        sx={{
                                            backgroundColor: '#fff', '& input': {
                                                fontSize: 14,
                                            },
                                            '& input::placeholder': {
                                                fontSize: 14
                                            }
                                        }}
                                        onChange={(date) => {
                                            setValidUntil(date)
                                        }}
                                        slotProps={{ textField: { size: 'small', error: !!errValidUntil, helperText: errValidUntil } }}
                                        renderInput={(params) => <TextField variant="filled" {...params}
                                            error={errValidUntil} inputProps={
                                                {
                                                    ...params.inputProps,
                                                    placeholder: "dd.MM.yyyy"
                                                }
                                            } placeholder="dd.MM.yyyy" />}
                                    />
                                    :
                                    institution?.valid_to ? t('institutions.valid-until.date', { date: moment(institution?.valid_to).toDate() }) : '-'
                                }
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start">
                            <Grid item xs={12} sm={4} sx={currentGridItemSxLabelMultiline}>
                                <Trans i18nKey="institutions.paying-agents">Paying agents</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                {payingAgents.length > 0 ?
                                    <Box sx={{ marginBottom: '11px' }}>
                                        {payingAgents.map((linking_rule, index) => {
                                            return (
                                                <Box sx={{ fontSize: 14 }} key={'linking_rule-' + index}>
                                                    {editable
                                                        ?
                                                        <Link
                                                            component="button"
                                                            onClick={() => onClickPayingAgent(linking_rule, index)}>
                                                            {linking_rule.paying_agent.shortname} ({linking_rule.paying_agent.ik_number})
                                                            {linking_rule.type && <>- {t('institutions.paying-agent.type.' + linking_rule.type, linking_rule.type)}</>}
                                                        </Link>
                                                        :
                                                        <Link href={'/institutions/' + linking_rule.paying_agent.id}>
                                                            {linking_rule.paying_agent.shortname} ({linking_rule.paying_agent.ik_number})
                                                            {linking_rule.type && <>- {t('institutions.paying-agent.type.' + linking_rule.type, linking_rule.type)}</>}
                                                        </Link>
                                                    }
                                                    <RedCrossRemoveButton
                                                        enabled={editable}
                                                        onClick={() => handleRemovePayingAgent(index)}
                                                        style={{ marginLeft: "10px", verticalAlign: 'middle' }}
                                                    />
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                    :
                                    !editable && "-"
                                }
                                {editable && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        sx={{
                                            backgroundColor: '#fff',
                                            textTransform: 'none'
                                        }}
                                        onClick={handleAddPayingAgent}
                                    >
                                        <Trans i18nKey="institutions.new-paying-agent">Add paying agent</Trans>
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4} sx={currentGridItemSxLabelMultiline} alignItems="flex-start">
                                <Box sx={{ padding: 0 }}><Trans i18nKey="institutions.addresses">Addresses</Trans></Box>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={currentGridItemSxValue}>
                                {addresses.length > 0 ?
                                    <Box sx={{ marginBottom: '11px' }}>
                                        {addresses.map((instituteAddress, index) => {
                                            return (
                                                <Box sx={{ fontSize: 14 }}
                                                    key={'address-' + index}>
                                                    {editable ?
                                                        <Link component="button"
                                                            onClick={() => onClickAddress(instituteAddress, index)}>{instituteAddress.address.street} {instituteAddress.address.zip} - {instituteAddress.address.city}</Link>
                                                        :
                                                        <div>{instituteAddress.address.street} - {instituteAddress.address.zip} {instituteAddress.address.city}</div>
                                                    }
                                                    <RedCrossRemoveButton
                                                        enabled={editable}
                                                        onClick={() => handleRemoveAddress(index)}
                                                        style={{ marginLeft: "10px", verticalAlign: 'middle' }}
                                                    />

                                                </Box>
                                            );
                                        })
                                        }
                                    </Box>
                                    :
                                    !editable && "-"
                                }
                                {editable && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        sx={{
                                            backgroundColor: '#fff',
                                            textTransform: 'none'
                                        }}
                                        onClick={handleAddAddress}
                                    >
                                        <Trans i18nKey="institutions.institutions-modal.new-address">Add new
                                            address</Trans>
                                    </Button>
                                )}
                            </Grid>

                            {editable &&
                                <FormAutocomplete
                                    multiple
                                    value={replacement}
                                    editable={editable}
                                    onChange={(newValue) => {
                                        setReplacement(newValue);
                                        setErrReplacement('');
                                    }}
                                    i18nKeyLabel={"institutions.replacement.label"}
                                    i18nKeyPlaceholder={"institutions.replacement.placeholder"}
                                    options={institutions?.data?.filter(institution => institution.id !== parseInt(id)) ?? []}
                                    getOptionLabel={(option) => option.shortname + ' (' + option.ik_number + ')'}
                                    disabled={disabled}
                                    showValidation={true}
                                    error={!!errReplacement}
                                    errorValue={errReplacement}
                                />
                            }

                        </Grid>
                        <Dialog
                            open={openModal}
                            onClose={handleCloseModal}
                            maxWidth={'sm'}
                            fullWidth={true}
                        >
                            <DialogTitle>
                                <Trans i18nKey="institutions.institutions-modal.new-address">New Address</Trans>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container alignItems="center">
                                    <FormTextField
                                        required={true}
                                        value={street}
                                        editable={editable}
                                        onChange={handleStreetChange}
                                        i18nKeyLabel='institutions.institutions-modal.street'
                                        i18nKeyPlaceholder='institutions.institutions-modal.enter-street'
                                        valueText={street}
                                        disabled={disabled}
                                        showValidation={true}
                                        error={!!errStreet}
                                        errorValue={errStreet}
                                    />

                                    <FormTextField
                                        required={true}
                                        value={zip}
                                        editable={editable}
                                        onChange={handleZipChange}
                                        i18nKeyLabel='institutions.institutions-modal.zip'
                                        i18nKeyPlaceholder='institutions.institutions-modal.enter-zip'
                                        valueText={zip}
                                        disabled={disabled}
                                        showValidation={true}
                                        error={!!errZip}
                                        errorValue={errZip}
                                    />

                                    <FormTextField
                                        required={true}
                                        value={city}
                                        editable={editable}
                                        onChange={handleCityChange}
                                        i18nKeyLabel='institutions.institutions-modal.city'
                                        i18nKeyPlaceholder='institutions.institutions-modal.enter-city'
                                        valueText={city}
                                        disabled={disabled}
                                        showValidation={true}
                                        error={!!errCity}
                                        errorValue={errCity}
                                    />

                                    {allBudgets &&
                                        <FormAutocomplete
                                            i18nKeyLabel='institutions.institutions-modal.budgets'
                                            i18nKeyPlaceholder='institutions.institutions-modal.select-budgets'
                                            editable={editable}
                                            onChange={(newValue) => {
                                                setBudgets(newValue);
                                                setErrBudget('');
                                            }}
                                            options={freeBudgets}
                                            getOptionLabel={(option) => option.name}
                                            value={budgets}
                                            error={!!errBudget}
                                            errorValue={errBudget}
                                            showValidation={true}
                                            required={true}
                                            multiple={true}
                                        />
                                    }
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    sx={{ textTransform: 'none', width: "93px", height: "34px", marginRight: 0 }}
                                    onClick={handleCloseModal}
                                >
                                    <Trans i18nKey="institutions.institutions-modal.cancel">Cancel</Trans>
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    disableElevation
                                    sx={{ textTransform: 'none', width: "93px", height: "34px", marginRight: 0 }}
                                    onClick={handleAddAddressValues}
                                >
                                    <Trans i18nKey="institutions.institutions-modal.add">Add</Trans>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openPayingAgentModal}
                            onClose={handlePayingAgentModalClose}
                            maxWidth={'sm'}
                            fullWidth={true}
                        >
                            <DialogTitle><Trans i18nKey="institutions.paying-agent-modal.title">Paying
                                agent</Trans></DialogTitle>
                            <DialogContent dividers>
                                <FormAutocomplete
                                    i18nKeyLabel='institutions.paying-agent-modal.paying-agent.label'
                                    i18nKeyPlaceholder='institutions.paying-agent-modal.paying-agent.placeholder'
                                    editable={editable}
                                    onChange={(newValue) => {
                                        setPayingAgent(newValue);
                                        setErrPayingAgent('');
                                    }}
                                    options={institutions.data}
                                    getOptionLabel={(option) => option.shortname + ' (' + option.ik_number + ')'}
                                    value={payingAgent}
                                    valueText={payingAgent ?? null}
                                    error={!!errPayingAgent}
                                    errorValue={errPayingAgent}
                                    showValidation={true}
                                    required={true}
                                />

                                <FormAutocomplete
                                    i18nKeyLabel='institutions.paying-agent-modal.type.label'
                                    i18nKeyPlaceholder='institutions.paying-agent-modal.type.placeholder'
                                    editable={editable}
                                    onChange={(newValue) => {
                                        setPayingAgentType(newValue);
                                        setErrPayingAgentType('');
                                    }}
                                    options={payingAgentTypes}
                                    getOptionLabel={(option) => option.label}
                                    value={payingAgentType}
                                    valueText={payingAgentType ?? null}
                                    error={!!errPayingAgentType}
                                    errorValue={errPayingAgentType}
                                    showValidation={true}
                                    required={true}
                                />
                                {allBudgets &&
                                    <FormAutocomplete
                                        i18nKeyLabel='institutions.paying-agent-modal.budget.label'
                                        i18nKeyPlaceholder='institutions.paying-agent-modal.budget.placeholder'
                                        editable={editable}
                                        onChange={(newValue) => {
                                            setPayingAgentBudget(newValue);
                                            setErrPayingAgentBudget('');
                                        }}
                                        options={allBudgets}
                                        getOptionLabel={(option) => option.name}
                                        value={payingAgentBudget}
                                        error={!!errPayingAgentBudget}
                                        errorValue={errPayingAgentBudget}
                                        showValidation={true}
                                        required={true}
                                    />
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    sx={{ textTransform: 'none', marginRight: 0 }}
                                    onClick={handleClosePayingAgentModal}
                                >
                                    <Trans i18nKey="institutions.paying-agent-modal.cancel">Cancel</Trans>
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    sx={{ textTransform: 'none', marginRight: 0 }}
                                    onClick={handleAddPayingAgentValues}
                                >
                                    <Trans i18nKey="institutions.paying-agent-modal.add">Add</Trans>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {editable && (
                            <Grid container maxWidth="1024px">
                                <Grid item xs={12}>
                                    <Box sx={{ padding: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                marginRight: 2,
                                                textTransform: 'none', background: '#fff'
                                            }}
                                            onClick={handleCancel}
                                        >
                                            <Trans i18nKey="institutions.cancel">Cancel</Trans>
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            disableElevation
                                            sx={{ textTransform: 'none' }}
                                            onClick={() => handleSave()}
                                        >
                                            <Trans i18nKey="institutions.save">Save</Trans>
                                        </Button>

                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Paper>
                    <ConfirmationModal
                        isOpen={openReplacementConfirmationModal}
                        onClose={() => setOpenReplacementConfirmationModal(false)}
                        onConfirm={() => handleSave(true)}
                        message={t('institutions.replacement.confirmation-message')}
                    />
                </>
            }
        </Box>
    )
}

export default Institution;
