import { MenuItem } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import FormTextField from "../forms/FormTextField";
import FormSelect from "../forms/FormSelect";
import FormDateField from "../forms/FormDateField";
import FormSection from "../forms/FormSection";
import FormPhoneField from "../forms/FormPhoneField";

const ClientMasterData = ({
    editable,
    onSave,
    onCancel,
    setIsValid,
    showValidation,
    clientMasterData,
    setClientMasterData,
    errClientMasterData,
    showButtons = false
}) => {

    const { t } = useTranslation();

    const [disabled, setDisabled] = useState(false);
    const [errFirstName, setErrFirstName] = useState('');
    const [errLastName, setErrLastName] = useState('');
    const [errGender, setErrGender] = useState('');
    const [errStreet, setErrStreet] = useState('');
    const [errStreetNumber, setErrStreetNumber] = useState('');
    const [errZip, setErrZip] = useState('');
    const [errCity, setErrCity] = useState('');
    const [errBirthdate, setErrBirthdate] = useState('');
    const [errPhone] = useState(false);
    const [errEmail, setErrEmail] = useState('');

    useEffect(() => {
        setErrFirstName(errClientMasterData.firstName);
        setErrLastName(errClientMasterData.lastName);
        setErrGender(errClientMasterData.gender);
        setErrStreet(errClientMasterData.street);
        setErrStreetNumber(errClientMasterData.streetNumber);
        setErrZip(errClientMasterData.zip);
        setErrCity(errClientMasterData.city);
        setErrBirthdate(errClientMasterData.birthdate);
        setErrEmail(errClientMasterData.email);
    }, [errClientMasterData]);

    const validate = () => {
        setIsValid(true);
    }  
    const handleFieldChange = (event, fieldName) => {
        setClientMasterData({ ...clientMasterData, [fieldName]: event.target.value });
    }

    const handleTitleChange = (event) => {
        const validTitles = ['doctor', 'professor', 'professor-doctor'];
        const inputTitle = event.target.value;
        const title = validTitles.indexOf(inputTitle) !== -1 ? inputTitle : '';
        setClientMasterData({ ...clientMasterData, title: title });
    }

    const handleFirstNameChange = (event) => {
        setClientMasterData({ ...clientMasterData, firstName: event.target.value });
    }

    const handleLastNameChange = (event) => {
        setClientMasterData({ ...clientMasterData, lastName: event.target.value });
    }

    const handleGenderChange = (event) => {
        setClientMasterData({ ...clientMasterData, gender: event.target.value });
    }

    const handleStreetChange = (event) => {
        setClientMasterData({ ...clientMasterData, street: event.target.value });
    }

    const handleStreetNumberChange = (event) => {
        setClientMasterData({ ...clientMasterData, streetNumber: event.target.value });
    }

    const handleZipChange = (event) => {
        setClientMasterData({ ...clientMasterData, zip: event.target.value });
    }

    const handleCityChange = (event) => {
        setClientMasterData({ ...clientMasterData, city: event.target.value });
    }

    const handleBirthdateChange = (value) => {
        setClientMasterData({ ...clientMasterData, birthdate: value });
    }

    const handleEmailChange = (event) => {
        setClientMasterData({ ...clientMasterData, email: event.target.value });
    }

    const handlePhoneChange = (event) => {
        setClientMasterData({ ...clientMasterData, phone: event.target.value });
    }

    const handlePhoneCountryCodeChange = (event) => {
        setClientMasterData({ ...clientMasterData, phoneCountryCode: event.target.value });
    }

    const handlePhoneMobileChange = (event) => {
        setClientMasterData({ ...clientMasterData, phoneMobile: event.target.value });
    }

    const handlePhoneCountryCodeMobileChange = (event) => {
        setClientMasterData({ ...clientMasterData, phoneCountryCodeMobile: event.target.value });
    }

    useEffect(() => {
        validate();
    }, [showValidation, clientMasterData]);

    return (
        <FormSection
            editable={editable}
            showButtons={showButtons}
            title={t('clients.master-data', 'Master data')}
            onSave={onSave}
            onCancel={onCancel}
        >
            <FormSelect
                i18nKeyLabel="clients.title.label"
                i18nKeyPlaceholder="clients.title.placeholder"
                editable={editable}
                valueText={clientMasterData.title ? t('clients.title.' + clientMasterData.title, clientMasterData.title) : '-'}
                value={clientMasterData.title}
                onChange={handleTitleChange}
                disabled={disabled}
                showValidation={showValidation}
                required={false}
                displayEmpty={true}>
                <MenuItem sx={{ fontSize: 14 }} value={'doctor'}><Trans i18nKey="clients.title.doctor"></Trans></MenuItem>
                <MenuItem sx={{ fontSize: 14 }} value={'professor'}><Trans
                    i18nKey="clients.title.professor"></Trans></MenuItem>
                <MenuItem sx={{ fontSize: 14 }} value={'professor-doctor'}><Trans
                    i18nKey="clients.title.professor-doctor"></Trans></MenuItem>
            </FormSelect>

            <FormTextField
                required={true}
                value={clientMasterData.firstName}
                editable={editable}
                onChange={handleFirstNameChange}
                i18nKeyLabel="clients.first-name.label"
                i18nKeyPlaceholder="clients.first-name.placeholder"
                valueText={clientMasterData.firstName}
                disabled={disabled}
                showValidation={showValidation}
                error={!!errFirstName}
                errorValue={errFirstName}
            />

            <FormTextField
                required={true}
                value={clientMasterData.lastName}
                editable={editable}
                onChange={handleLastNameChange}
                i18nKeyLabel="clients.last-name.label"
                i18nKeyPlaceholder="clients.last-name.placeholder"
                valueText={clientMasterData.lastName}
                disabled={disabled}
                showValidation={showValidation}
                error={!!errLastName} 
                errorValue={errLastName}
            />

            <FormSelect
                i18nKeyLabel="clients.gender.label"
                i18nKeyPlaceholder="clients.gender.placeholder"
                editable={editable}
                valueText={clientMasterData.gender ? t('clients.gender.' + clientMasterData.gender, clientMasterData.gender) : '-'}
                value={clientMasterData.gender}
                onChange={handleGenderChange}
                disabled={disabled}
                showValidation={showValidation}
                error={!!errGender}
                errorValue={errGender}
                required={true}
                displayEmpty={true}>
                <MenuItem sx={{ fontSize: 14 }} value={'m'}><Trans i18nKey="clients.gender.m"></Trans></MenuItem>
                <MenuItem sx={{ fontSize: 14 }} value={'f'}><Trans i18nKey="clients.gender.f"></Trans></MenuItem>
                <MenuItem sx={{ fontSize: 14 }} value={'x'}><Trans i18nKey="clients.gender.x"></Trans></MenuItem>
            </FormSelect>

            <FormTextField
                required={true}
                value={clientMasterData.street}
                editable={editable}
                onChange={handleStreetChange}
                i18nKeyLabel="clients.street.label"
                i18nKeyPlaceholder="clients.street.placeholder"
                valueText={clientMasterData.street}
                disabled={disabled}
                showValidation={showValidation}
                error={!!errStreet} 
                errorValue={errStreet}
            />

            <FormTextField
                required={true}
                value={clientMasterData.streetNumber}
                editable={editable}
                onChange={handleStreetNumberChange}
                i18nKeyLabel="clients.street-number.label"
                i18nKeyPlaceholder="clients.street-number.placeholder"
                valueText={clientMasterData.streetNumber}
                disabled={disabled}
                showValidation={showValidation}
                error={!!errStreetNumber}
                errorValue={errStreetNumber}
            />

            <FormTextField
                required={true}
                value={clientMasterData.zip}
                editable={editable}
                onChange={handleZipChange}
                i18nKeyLabel="clients.zip.label"
                i18nKeyPlaceholder="clients.zip.placeholder"
                valueText={clientMasterData.zip}
                disabled={disabled}
                showValidation={showValidation}
                error={!!errZip}
                errorValue={errZip}
            />

            <FormTextField
                required={true}
                value={clientMasterData.city}
                editable={editable}
                onChange={handleCityChange}
                i18nKeyLabel="clients.city.label"
                i18nKeyPlaceholder="clients.city.placeholder"
                valueText={clientMasterData.city}
                disabled={disabled}
                showValidation={showValidation}
                error={!!errCity}
                errorValue={errCity}
            />

            <FormDateField
                i18nKeyLabel="clients.birthdate.label"
                i18nKeyPlaceholder="clients.birthdate.placeholder"
                i18nKeyDateFormat="clients.birthdate.date"
                editable={editable}
                valueText={clientMasterData.birthdate}
                value={clientMasterData.birthdate}
                onChange={handleBirthdateChange}
                disabled={disabled}
                showValidation={showValidation}
                error={!!errBirthdate}
                errorValue={errBirthdate}
                required={true}
            />

            {/* TODO: country */}

            <FormTextField
                value={clientMasterData.email}
                editable={editable}
                onChange={handleEmailChange}
                i18nKeyLabel="clients.email.label"
                i18nKeyPlaceholder="clients.email.placeholder"
                valueText={clientMasterData.email}
                disabled={disabled}
                showValidation={showValidation}
                error={!!errEmail}
                errorValue={errEmail}
            />

            <FormPhoneField
                valuePhone={clientMasterData.phone}
                valueCountryCode={clientMasterData.phoneCountryCode}
                editable={editable}
                onPhoneChange={handlePhoneChange}
                onCountryCodeChange={handlePhoneCountryCodeChange}
                i18nKeyLabel="clients.phone.label"
                i18nKeyPlaceholder="clients.phone.placeholder"
                valueText={clientMasterData.phone}
                disabled={disabled}
                showValidation={showValidation}
                error={errPhone}
                required={false}
            />

            <FormPhoneField
                valuePhone={clientMasterData.phoneMobile}
                valueCountryCode={clientMasterData.phoneCountryCodeMobile}
                editable={editable}
                onPhoneChange={handlePhoneMobileChange}
                onCountryCodeChange={handlePhoneCountryCodeMobileChange}
                i18nKeyLabel="clients.phone-mobile.label"
                i18nKeyPlaceholder="clients.phone.placeholder"
                valueText={clientMasterData.phoneMobile}
                disabled={disabled}
                showValidation={showValidation}
                error={errPhone}
                required={false}
            />

        </FormSection>
    )
}

export default ClientMasterData;
