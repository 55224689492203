import { useEffect, useState } from "react";

import useApi from "../../hooks/useApi";
import { useTranslation } from "react-i18next";

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import TeamCalendarChipSelection from "./TeamCalendarChipSelection";
import { TextField } from "@mui/material";

const TeamCalendarGeneralFilter = ({
    filter,
    setFilter,
}) => {
    const EVENT_TYPES = [
        { id: 'client_appointment', i18nKeyLabel: 'team-calendar.event-types.client_appointment' },
        { id: 'sick', i18nKeyLabel: 'team-calendar.event-types.sick' },
        { id: 'holiday', i18nKeyLabel: 'team-calendar.event-types.holiday' },
        { id: 'internal', i18nKeyLabel: 'team-calendar.event-types.internal' },
        { id: 'public', i18nKeyLabel: 'team-calendar.event-types.public' },
    ];

    const { t } = useTranslation();

    const { queryBudgets, queryServices, queryPatients, queryActiveInternalEventTypes } = useApi();
    const { data: patients } = queryPatients();
    const { data: budgets } = queryBudgets();
    const { data: services } = queryServices();
    const { data: internalEventTypes } = queryActiveInternalEventTypes();

    const patientOptions = !patients
        ? []
        : patients
            .map(patient => {
                return {
                    id: patient.id,
                    name: `${patient.last_name}, ${patient.first_name}`
                };
            })
            .sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });

    const internalEventTypeOptions = internalEventTypes?.map(internalEventType => {
        return {
            id: internalEventType.id,
            name: internalEventType.type
        };
    }) ?? [];

    const [isGeneralFilterOpen, setIsGeneralFilterOpen] = useState(false);
    const [filteredTitle, setFilteredTitle] = useState('');
    const [filteredEventTypes, setFilteredEventTypes] = useState([]);
    const [filteredPatients, setFilteredPatients] = useState([]);
    const [filteredBudgets, setFilteredBudgets] = useState([]);
    const [filteredServices, setFilteredServices] = useState([]);
    const [filteredInternalEventTypes, setFilteredInternalEventTypes] = useState([]);

    const handleOpenGeneralFilterClick = () => {
        setIsGeneralFilterOpen(!isGeneralFilterOpen);
    };

    useEffect(() => {
        setFilteredTitle(filter?.get('title') ?? '');

        const eventTypeIds = filter?.get('eventTypeIds') ?? [];
        const newFilteredEventTypes = EVENT_TYPES.filter(eventType => eventTypeIds.includes(eventType.id));
        setFilteredEventTypes(newFilteredEventTypes);

        const patientIds = filter?.get('patientIds') ?? [];
        const newFilteredPatients = patientOptions?.filter(patient => patientIds.includes(patient.id)) ?? [];
        setFilteredPatients(newFilteredPatients);

        const budgetIds = filter?.get('budgetIds') ?? [];
        const newFilteredBudgets = budgets?.filter(budget => budgetIds.includes(budget.id)) ?? [];
        setFilteredBudgets(newFilteredBudgets);

        const serviceIds = filter?.get('serviceIds') ?? [];
        const newFilteredServices = services?.filter(service => serviceIds.includes(service.id)) ?? [];
        setFilteredServices(newFilteredServices);

        const internalEventTypeIds = filter?.get('internalEventTypeIds') ?? [];
        const newFilteredInternalEventTypes = internalEventTypeOptions?.filter(
            internalEventType => internalEventTypeIds.includes(internalEventType.id)
        ) ?? [];
        setFilteredInternalEventTypes(newFilteredInternalEventTypes);
    }, [filter]);

    const updateField = (field, value) => {
        switch (field) {
            case 'title':
                setFilteredTitle(value);
                break;
            case 'eventType':
                setFilteredEventTypes(value);
                break;
            case 'patients':
                setFilteredPatients(value);
                break;
            case 'budgets':
                setFilteredBudgets(value);
                break;
            case 'services':
                setFilteredServices(value);
                break;
            case 'internalEventType':
                setFilteredInternalEventTypes(value);
                break;
        }
    };

    const getFilterKeyByField = (field) => {
        switch (field) {
            case 'title':
                return 'title';
            case 'eventType':
                return 'eventTypeIds';
            case 'patients':
                return 'patientIds';
            case 'budgets':
                return 'budgetIds';
            case 'services':
                return 'serviceIds';
            case 'internalEventType':
                return 'internalEventTypeIds';
        }
    }

    const updateFilter = (field, value) => {
        let activeFilters = new Map(filter);

        switch (field) {
            case 'title':
                if (!value || value === '') {
                    activeFilters.delete('title');
                    break;
                }

                activeFilters.set('title', value);
                break;
            case 'eventType':
            case 'patients':
            case 'budgets':
            case 'services':
            case 'internalEventType':
                const filterMapKey = getFilterKeyByField(field);

                if (!value || value.length === 0) {
                    activeFilters.delete(filterMapKey);
                    break;
                }

                activeFilters.set(filterMapKey, value.map(obj => obj.id));
                break;
        }

        setFilter(activeFilters);
    };

    const handleFieldChange = (field, value) => {
        updateField(field, value);
        updateFilter(field, value);
    };

    return (
        <>
            <ListItem sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <ListItemText sx={{ textAlign: 'center', flex: 1 }} primary="Filter" />
                <ListItemButton
                    onClick={handleOpenGeneralFilterClick}
                    sx={{ position: 'absolute', right: 0, marginRight: '8px' }}
                >
                    {isGeneralFilterOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </ListItem>

            <Collapse in={isGeneralFilterOpen} timeout="auto" unmountOnExit>
                <List
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}
                    component="div"
                    disablePadding
                >
                    <TextField
                        sx={{ m: 1, width: '225px' }}
                        id="title-filter-input"
                        field='title'
                        label={t('team-calendar.filter.by-title', 'by title')}
                        variant="outlined"
                        value={filteredTitle}
                        onChange={(event) => handleFieldChange('title', event.target.value)}
                    />
                    <TeamCalendarChipSelection
                        field='eventType'
                        label={t('team-calendar.filter.by-event-type', 'by event type')}
                        options={EVENT_TYPES}
                        selectedOptions={filteredEventTypes}
                        handleSelection={(value) => handleFieldChange('eventType', value)}
                    />
                    <TeamCalendarChipSelection
                        field='patients'
                        label={t('team-calendar.filter.by-patients', 'by patients')}
                        options={patientOptions}
                        selectedOptions={filteredPatients}
                        handleSelection={(value) => handleFieldChange('patients', value)}
                    />
                    <TeamCalendarChipSelection
                        field='budgets'
                        label={t('team-calendar.filter.by-budget', 'by budget')}
                        options={budgets}
                        selectedOptions={filteredBudgets}
                        handleSelection={(value) => handleFieldChange('budgets', value)}
                    />
                    <TeamCalendarChipSelection
                        field='services'
                        label={t('team-calendar.filter.by-service', 'by service')}
                        options={services}
                        selectedOptions={filteredServices}
                        handleSelection={(value) => handleFieldChange('services', value)}
                    />
                    <TeamCalendarChipSelection
                        field='internalEventType'
                        label={t('team-calendar.filter.by-internal-event-type', 'by internal event type')}
                        options={internalEventTypeOptions}
                        selectedOptions={filteredInternalEventTypes}
                        handleSelection={(value) => handleFieldChange('internalEventType', value)}
                    />
                </List>
            </Collapse>
        </>
    );

};

export default TeamCalendarGeneralFilter;
