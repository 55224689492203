const PersonObjectHelper = () => {


    const compareInts = (value1, value2) => {
        return parseInt(value1) === parseInt(value2);
    }


    const sortByName = (options) => {
        if (!options) {
            return null;
        }
        return options.sort((a, b) => {
            if (a.person && b.person) {
                if (a.person.last_name < b.person.last_name)
                    return -1;
                if (a.person.last_name > b.person.last_name)
                    return 1;
                if (a.person.first_name < b.person.first_name)
                    return -1;
                if (a.person.first_name > b.person.first_name)
                    return 1;
            }
            return 0;
        });
    };


    const filterByName = (options, inputValue) => {
        return options.filter((option) => {
            if (inputValue === "") {
                return true;
            }

            let found = false;

            inputValue.split(" ").filter(Boolean).forEach((word) => {
                if (option.person && option.person.first_name.toLowerCase().startsWith(word.toLowerCase()) ||
                    option.person && option.person.last_name.toLowerCase().startsWith(word.toLowerCase())
                ) {
                    found = true;
                }
            });

            return found;
        });
    };


    const extractNameOrEmpty = (option) => {
        return option && option.person
            ? `${option.person.last_name} ${option.person.first_name}`
            : "";
    };


    const filterOutByIds = (options, extractedOptions) => {
        if (!extractedOptions) {
            return options;
        }

        return options
            .filter(option => !extractedOptions
                .some(extractedOption =>
                    compareInts(option.id, extractedOption.id)
                )
            )
    }


    const filterOutById = (options, extractedId) => {
        return options
            .filter(option => !compareInts(option.id, extractedId))
    }


    return {
        compareInts,
        sortByName,
        filterByName,
        extractNameOrEmpty,
        filterOutByIds,
        filterOutById,
    };


};

export default PersonObjectHelper;