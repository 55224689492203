import { Trans, useTranslation } from "react-i18next";

const EventLocationSelectionModal = ({
    editable,
    location,
    setLocation,
    locationError,
}) => {
    const { t } = useTranslation();

    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
            <label
                htmlFor="location"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
                <Trans i18nKey="team-calendar.event-modal.location">Location</Trans>
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2">
                {editable
                    ?
                    <div className="sm:grid-row-2">
                        <input
                            type="text"
                            id="location"
                            name="location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${locationError ? "border-red-500" : "border-gray-300"}`}
                            placeholder={t('team-calendar.event-modal.enter-location')}
                        />
                        {locationError &&
                            <p className="mt-2 text-sm text-red-600">
                                <Trans i18nKey="team-calendar.event-modal.error.select-location">
                                    Please select a location.
                                </Trans>
                            </p>
                        }
                    </div>
                    :
                    <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">{location}</div>
                }
            </div>
        </div>
    );


};

export default EventLocationSelectionModal;