import { Trans, useTranslation } from "react-i18next"
import Autocomplete from "../Autocomplete";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import PersonObjectHelper from "./PersonObjectHelper";
import useApi from "../../hooks/useApi";
import Spinner from "../Spinner";
import RedCrossRemoveButton from "../general/RedCrossRemoveButton";

const EventClientWithBudgetTimeSelectionModal = ({
    editable,
    selectedClients, // { id, start, end, budget: {id, name}, person }
    setSelectedClients,
    selectedClientsEmptyError,
    setSelectedClientsEmptyError,
    selectedClientsError,
    setSelectedClientsError,
    clients,
    globalStart,
    globalEnd,
}) => {
    const { t } = useTranslation();

    const {
        queryActiveBudgetsForClients,
    } = useApi();

    const {
        compareInts,
        sortByName,
        filterByName,
        extractNameOrEmpty,
        filterOutByIds,
        filterOutById,
    } = PersonObjectHelper();


    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevGlobalStart = usePrevious(globalStart);
    const prevGlobalEnd = usePrevious(globalEnd);

    const queryBudgetsMapping = selectedClients
        ?.filter(selectedClient => !selectedClient.budgets)
        ?.map(selectedClient => ({ id: selectedClient.id, start: selectedClient.start }))
        ?? [];

    queryActiveBudgetsForClients(
        queryBudgetsMapping,
        true,
        editable,
        (activeBudgetsForClients) => {
            const newSelectedClients = selectedClients.map(selectedClient => !selectedClient.budgets
                ? {
                    ...selectedClient,
                    budgets: activeBudgetsForClients[selectedClient.id] ?? null
                }
                : selectedClient
            );
            setSelectedClients(newSelectedClients);
        }
    );

    const renderClient = (option) => {
        return option
            ? `${extractNameOrEmpty(option)} (${option.client_number})`
            : "";
    };

    const isValidDate = (date, start, end) => {
        if (!date) {
            return true;
        }
        return (!start || date.isSameOrAfter(start))
            && (!end || date.isSameOrBefore(end))
    };

    const onSelectClient = (client) => {
        let newSelectedClients = [...selectedClients, client];

        for (const selectedClient of newSelectedClients) {
            if (!selectedClient.start) {
                selectedClient.start = globalStart;
                selectedClient.budgets = null;
            }

            if (!selectedClient.end) {
                selectedClient.end = globalEnd;
            }
        }

        setSelectedClients(newSelectedClients);
        setSelectedClientsEmptyError(false);
    }

    const onChangeStart = (client, newStart) => {
        const date = globalStart ? globalStart.substring(0, 10) : moment().format('YYYY-MM-DD');
        client.start = date + ' ' + newStart + ':00';
        client.budgets = null;

        const parsedDate = moment(client.start);
        const parsedGlobalStart = globalStart ? moment(globalStart) : null;
        const parsedGlobalEnd = globalEnd ? moment(globalEnd) : null;

        if (!isValidDate(parsedDate, parsedGlobalStart, parsedGlobalEnd)) {
            return;
        }

        let newSelectedClients = filterOutById(selectedClients, client.id);
        setSelectedClients([...newSelectedClients, client]);

        const clientError = selectedClientsError?.find(error => compareInts(error.id, client.id)) ?? undefined;

        if (clientError?.start) {
            let newSelectedClientsError = filterOutById(selectedClientsError, clientError.id);
            clientError.start = false;
            setSelectedClientsError([...newSelectedClientsError, clientError]);
        }
    }

    const onChangeEnd = (client, newEnd) => {
        const date = globalEnd ? globalEnd.substring(0, 10) : moment().format('YYYY-MM-DD');
        client.end = date + ' ' + newEnd + ':00';

        const parsedDate = moment(client.end);
        const parsedGlobalStart = globalStart ? moment(globalStart) : null;
        const parsedGlobalEnd = globalEnd ? moment(globalEnd) : null;

        if (!isValidDate(parsedDate, parsedGlobalStart, parsedGlobalEnd)) {
            return;
        }

        let newSelectedClients = filterOutById(selectedClients, client.id);
        setSelectedClients([...newSelectedClients, client]);

        const clientError = selectedClientsError?.find(error => compareInts(error.id, client.id)) ?? undefined;

        if (clientError?.end) {
            let newSelectedClientsError = filterOutById(selectedClientsError, clientError.id);
            clientError.end = false;
            setSelectedClientsError([...newSelectedClientsError, clientError]);
        }
    }

    const onChangeBudget = (client, newBudgetId) => {
        let newSelectedClients = filterOutById(selectedClients, client.id);
        client.budget = { id: newBudgetId };
        setSelectedClients([...newSelectedClients, client]);

        const clientError = selectedClientsError?.find(error => compareInts(error.id, client.id)) ?? undefined;

        if (clientError?.budget) {
            let newSelectedClientsError = filterOutById(selectedClientsError, clientError.id);
            clientError.budget = false;
            setSelectedClientsError([...newSelectedClientsError, clientError]);
        }
    }

    useEffect(() => {
        if (selectedClients?.length <= 0) {
            return;
        }

        let newSelectedClients = [];

        const globalStartChange = prevGlobalStart !== globalStart;
        const globalEndChange = prevGlobalEnd !== globalEnd;

        const parsedPrevGlobalStart = moment(prevGlobalStart);
        const parsedPrevGlobalEnd = moment(prevGlobalEnd);

        const parsedGlobalStart = globalStart ? moment(globalStart) : null;
        const parsedGlobalEnd = globalEnd ? moment(globalEnd) : null;

        for (const selectedClient of selectedClients) {
            const parsedStart = selectedClient.start ? moment(selectedClient.start) : null;
            const parsedEnd = selectedClient.end ? moment(selectedClient.end) : null;

            if ((globalStartChange && parsedStart.isSame(parsedPrevGlobalStart))
                || !isValidDate(parsedStart, parsedGlobalStart, parsedGlobalEnd)
            ) {
                selectedClient.start = globalStart;
                selectedClient.budgets = null;
            }

            if ((globalEndChange && parsedEnd.isSame(parsedPrevGlobalEnd))
                || !isValidDate(parsedEnd, parsedGlobalStart, parsedGlobalEnd)
            ) {
                selectedClient.end = globalEnd;
            }

            newSelectedClients.push(selectedClient);
        }
    }, [globalStart, globalEnd]);

    return (
        <div className="sm:items-start sm:border-gray-200">

            <label
                htmlFor="clients"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
                <Trans i18nKey="team-calendar.event-modal.patients">patients</Trans>
            </label>

            {!editable && selectedClients?.length >= 1 &&
                <div className="mt-1 sm:mt-0">
                    <div className="mt-2">
                        {sortByName(selectedClients).map(client => {
                            return (
                                <div
                                    key={"client_" + client.id}
                                    className="grid grid-cols-12 gap-x-1 mt-2 text-sm"
                                >
                                    <div className="grid col-span-6 flex flex-column">
                                        <div className="flex">
                                            {extractNameOrEmpty(client)}
                                        </div>
                                        <div className="flex truncate">
                                            ({client.client_number})
                                        </div>
                                    </div>

                                    <div className="grid col-span-4 flex justify-center">
                                        {
                                            (client.start ? t('team-calendar.event-modal.end.time', { date: moment(client.start).toDate() }) : 'X')
                                            + ' - ' +
                                            (client.end ? t('team-calendar.event-modal.end.time', { date: moment(client.end).toDate() }) : 'X')
                                        }
                                    </div>

                                    <div className="grid col-span-2 flex justify-center">
                                        {client.budget?.code ?? '-'}
                                    </div>
                                </div>
                            );
                        })
                        }
                    </div>
                </div>
            }

            {editable &&
                <div className="mt-1 sm:mt-0">
                    <div className="mt-2">
                        {selectedClients?.length >= 1 &&
                            sortByName(selectedClients).map(client => {
                                const clientError = selectedClientsError?.find(error => compareInts(error.id, client.id));
                                return (
                                    <div
                                        key={"client_" + client.id}
                                        className="flex flex-col bg-gray-200 my-2"
                                    >
                                        <div className="flex items-center justify-between ml-2 mr-2">
                                            {renderClient(client)}
                                            <RedCrossRemoveButton
                                                enabled={true}
                                                onClick={() => { setSelectedClients(filterOutById(selectedClients, client.id)); }}
                                            />
                                        </div>

                                        <div className="mr-2 mb-2">
                                            <div>
                                                <div className="grid grid-cols-3 gap-4 items-start border-gray-200 ml-2">
                                                    <label
                                                        htmlFor={`start-time-${client.id}`}
                                                        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                                    >
                                                        <Trans i18nKey="team-calendar.event-modal.start.label">Start</Trans>
                                                    </label>
                                                    <input
                                                        type="time"
                                                        id={`start-time-${client.id}`}
                                                        value={client.start?.substring(11, 16) ?? ''}
                                                        onChange={(event) => onChangeStart(client, event.target.value)}
                                                        className={`col-span-2 mt-1 block w-full py-2 px-3 border ${clientError?.start ? "border-red-500" : "border-gray-200"} bg-white shadow-sm focus:outline-none sm:text-sm`}
                                                    />
                                                </div>
                                                <div className="grid grid-cols-3 gap-4 items-start border-gray-200 ml-2">
                                                    <label
                                                        htmlFor={`end-time-${client.id}`}
                                                        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                                    >
                                                        <Trans i18nKey="team-calendar.event-modal.end.label">End</Trans>
                                                    </label>
                                                    <input
                                                        type="time"
                                                        id={`end-time-${client.id}`}
                                                        value={client.end?.substring(11, 16) ?? ''}
                                                        onChange={(event) => onChangeEnd(client, event.target.value)}
                                                        className={`col-span-2 mt-1 block w-full py-2 px-3 border ${clientError?.end ? "border-red-500" : "border-gray-200"} bg-white shadow-sm focus:outline-none sm:text-sm`}
                                                    />
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-3 gap-4 items-start border-gray-200 ml-2">
                                                <label
                                                    htmlFor={`budget-${client.id}`}
                                                    className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                                >
                                                    <Trans i18nKey="team-calendar.event-modal.budget">Budget</Trans>
                                                </label>
                                                {!client.budgets
                                                    ?
                                                    <Spinner />
                                                    :
                                                    <select
                                                        id={`budget-${client.id}`}
                                                        value={client.budget?.id ?? '-1'}
                                                        onChange={(event) => onChangeBudget(client, event.target.value)}
                                                        className={`pr-7 col-span-2 mt-1 block w-full py-2 px-3 truncate border ${clientError?.budget ? "border-red-500" : "border-gray-200"} bg-white shadow-sm focus:outline-none sm:text-sm`}
                                                    >
                                                        <option disabled value={'-1'}>
                                                            <Trans i18nKey="team-calendar.event-modal.select-budget">select a budget</Trans>
                                                        </option>
                                                        {client.budgets?.map(budget => (
                                                            <option
                                                                key={budget.id}
                                                                value={budget.id}
                                                            >
                                                                {budget.name} ({budget.code})
                                                            </option>
                                                        )) ?? '-'}
                                                    </select>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <Autocomplete
                        options={filterOutByIds(clients, selectedClients)}
                        error={selectedClientsEmptyError}
                        onSelect={(client) => onSelectClient(client)}
                        renderOption={renderClient}
                        renderValue={renderClient}
                        filterFunction={filterByName}
                        sortFunction={sortByName}
                        placeholder={t('team-calendar.event-modal.add-patient')}
                        resetAfterSelection={true}
                    />
                </div>
            }
        </div>
    )
};

export default EventClientWithBudgetTimeSelectionModal;
