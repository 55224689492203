import { Trans, useTranslation } from "react-i18next";
import Autocomplete from "../Autocomplete";

const EventClientSelectionModal = ({
    editable,
    client,
    setClient,
    clients,
    clientError,
}) => {
    const { t } = useTranslation();


    const sortClients = (options) => {
        return options.sort((a, b) => {
            if (a && b) {
                if (a.last_name < b.last_name)
                    return -1;
                if (a.last_name > b.last_name)
                    return 1;
                if (a.first_name < b.first_name)
                    return -1;
                if (a.first_name > b.first_name)
                    return 1;
            }
            return 0;
        });
    };


    const renderClient = (option) => {
        return option 
            ? `${option.last_name} ${option.first_name}` 
            : "";
    };


    const filterClients = (options, inputValue) => {
        return options.filter((option) => {
            if (inputValue === "") {
                return true;
            }

            let found = false;

            inputValue.split(" ").filter(Boolean).forEach((word) => {

                if (!option.first_name || !option.last_name) {
                    return;
                }

                if (option.first_name.toLowerCase().startsWith(word.toLowerCase()) || 
                    option.last_name.toLowerCase().startsWith(word.toLowerCase()) ||
                    option.client_number.toLowerCase().startsWith(word.toLowerCase())
                ) {
                    found = true;
                }
            });

            return found;
        });
    };


    const getPrimaryEmployeeName = () => {
        if (!client || !client.employees || client.employees.length === 0) {
            return '-';
        }

        const primaryEmployee = client.employees[0];
        const person = primaryEmployee.person;
        return person.last_name + ' ' + person.first_name;
    }


    const getPrimaryEmployeeTeamName = () => {
        if (!client || !client.employees || client.employees.length === 0) {
            return '';
        }

        const primaryEmployee = client.employees[0];
        if (primaryEmployee?.teamsAsLeader && primaryEmployee.teamsAsLeader.length > 0) {
            const team = primaryEmployee.teamsAsLeader[0];
            return '(' + team?.name + ')' ?? '(-)';
        }

        if (primaryEmployee?.teamsAsMember && primaryEmployee.teamsAsMember.length > 0) {
            let team = primaryEmployee.teamsAsMember.find(t => t.is_primary);
            if (!team) {
                team = primaryEmployee.teamsAsMember.length[0];
            }

            return '(' + team.name + ')' ?? '(-)';
        }

        return '(-)';
    }


    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
            <label
                htmlFor="patient"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
                <Trans i18nKey="team-calendar.event-modal.patient">Patient</Trans>
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2">
                {editable
                    ?
                    <div className="sm:grid-row-2">
                        <Autocomplete 
                            options={clients}
                            error={clientError}
                            value={client}
                            onSelect={(client) => setClient(client)}
                            renderOption={renderClient}
                            renderValue={renderClient}
                            filterFunction={filterClients}
                            sortFunction={sortClients}
                            placeholder={t('team-calendar.event-modal.select-patient')} 
                        />
                        {clientError &&
                            <p className="mt-2 text-sm text-red-600">
                                <Trans i18nKey="team-calendar.event-modal.error.select-client">
                                    Please select a client.
                                </Trans>
                            </p>
                        }
                    </div>
                    :
                    <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                        {client ? renderClient(client) : '-'}
                    </div>
                }
            </div>

            <label
                htmlFor="primary-employee"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
                <Trans i18nKey="team-calendar.event-modal.primary-employee">Primary Employee</Trans>
                <br />
                <Trans i18nKey="team-calendar.event-modal.primary-employee-team">(Team)</Trans>
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                    {getPrimaryEmployeeName()}
                    <br />
                    {getPrimaryEmployeeTeamName()}
                </div>
            </div>
        </div>
    );

}

export default EventClientSelectionModal;