import useApi from "../../hooks/useApi";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React from "react";
import {Paper} from "@mui/material";
import FormTable from ".././forms/FormTable";


const ClientBudgets = (props) => {
    const [columnVisibility, setColumnVisibility] = React.useState({
        name: true,
        description: true,
        valid_from: false,
        valid_until: false,
        current_amount: true,
    });
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {queryClientBudgets} = useApi();

    const [columns, setColumns] = React.useState([
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                const fullName = params.row.budget.name ? params.row.budget.name : '';
                return <b>{fullName}</b>;
            },
        },
        {
            field: 'description',
            headerName: t('clients.budgets.description','Description'),
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                const description = params.row.budget?.description ? params.row.budget.description : '';
                return description;
            },
        },
        {
            field: 'valid_from',
            headerName: t('clients.budgets.valid_from.label', 'Valid From'),
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                const validFrom = params.row.valid_from?  params.row.valid_from  :'';
                return validFrom;
            },
        },
        {
            field: 'valid_until',
            headerName: t('clients.budgets.valid_until.label', 'Valid Until'),
            flex: 1,
            renderCell: (params) => {
                const validUntil = params.row.valid_until?  params.row.valid_until :'';
                return validUntil;
            },
            headerClassName: 'data-grid-header'
        },
        {
            field: 'current_amount',
            headerName: t('clients.budgets.current_amount','Current Amount'),
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                const hours = params.row.value ?? '';
                const price = params.row.rate ?? 0;
                const totalValue = hours * price;
                const formattedValue = new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }).format(totalValue);

                return formattedValue.replace(/€/, '') + '€';
            },
        },
        {
            field: 'active',
            headerName: t('clients.budgets.active.label', 'Active'),
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                const active = params.row.active;
                return t('clients.budgets.active.' + active, active);
            },
        },
    ]);
    const [sortModel, setSortModel] = React.useState(searchParams.get('sortField') && searchParams.get('sortOrder') ? [{
        field: searchParams.get('sortField'),
        sort: searchParams.get('sortOrder')
    }] : []);
    const [searchText, setSearchText] = React.useState(searchParams.get('search') ?? '');
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : props.paginationModel.pageSize,
        page: searchParams.get('page') ? parseInt(searchParams.get('page')) : props.paginationModel.page,
    });

    const {data: clientBudgets = null, isLoading} = queryClientBudgets(
        props.client.id,
        paginationModel.pageSize,
        paginationModel.page + 1,
        sortModel.length > 0 ? sortModel[0].field : null,
        sortModel.length > 0 ? sortModel[0].sort : null,
        searchText);


    const handleRowClick = (params) => {
        const budgetId = params.row?.id;
        navigate(`${budgetId}`);
    }

    const handleNewClientContactClick = (event) => {
        const budgetId = 'new';
        navigate(`${budgetId}`);
    }

    return (
        <Paper elevation={0} sx={{marginBottom: 2, padding: 2, maxWidth: 1024}}>
            <FormTable
                createButtonLabel={t("clients.budgets.new-budget", "New Budget")}
                columns={columns}
                data={clientBudgets}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                onSearchTextChange={setSearchText}
                onRowClick={handleRowClick}
                onNewClick={handleNewClientContactClick}
                sortModel={sortModel}
                setSortModel={setSortModel}
                isLoading={isLoading}
                onScreenSizeChange={() => {}}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                createPermissions={['clients', 'client', 'client.budgets', 'client.budgets.update']}
            />
        </Paper>
    );
}

export default ClientBudgets;
