import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import rrulePlugin from '@fullcalendar/rrule'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import deLocale from '@fullcalendar/core/locales/de';
import useApi from "../hooks/useApi";
import useConfig from "../hooks/useConfig";
import TeamMemberDropdown from "./TeamMemberDropdown";
import { Trans, useTranslation } from "react-i18next";
import EventModal from "./EventModal";
import moment from "moment";
import { Route, Routes } from "react-router-dom";
import useRouting from "../hooks/useRouting"
import TeamCalendarNavigation from "./TeamCalendarNavigation";
import "./TeamCalendar.css";
import EventsExportModal from "./EventsExportModal";
import ImportModal from "./ImportModal";
import useUser from "../hooks/useUser";
import useTeamCalendarEventUpdater from "./events/TeamCalendarEventUpdater";
import useTeamCalendarEventContent from "./calendar/TeamCalendarEventContent";
import { useCalendarViewStore } from "../store/CalendarViewStore";
import useHorizontalSwipe from "./gestures/HorizontalSwipe";
import EventTypeConfirmationModal from "./events/EventTypeConfirmationModal";

export default function TeamCalendar() {
    const { t } = useTranslation();
    const { goToTeamCalendar, goToTeamCalendarNewEvent, goToTeamCalendarEvent } = useRouting();
    const { getEmployeeId } = useUser();
    const { queryEvents, queryPrimaryPatientsIds, convertEvents, importEvents } = useApi();
    const { updateEventsByTeamSelection, updateEventsByRecurrence } = useTeamCalendarEventUpdater();
    const { renderEventContent } = useTeamCalendarEventContent(employeeId);
    const calendarRef = useRef(null);
    const { eventColors } = useConfig();

    const initialDate = useCalendarViewStore(state => state.viewDate);
    const updateViewDate = useCalendarViewStore(state => state.updateViewDate);

    const initialGridType = useCalendarViewStore(state => state.gridType);
    const hiddenDays = useCalendarViewStore(state => state.hiddenDays);
    const updateGrid = useCalendarViewStore(state => state.updateGrid);

    const teamSelection = useCalendarViewStore(state => state.selected);

    const employeeId = getEmployeeId();
    const userIdMeta = document.querySelector('meta[name="user_id"]');
    const userId = userIdMeta ? parseInt(userIdMeta.content) : null;
    const teamMeta = document.querySelector('meta[name="team_id"]');
    const teamId = teamMeta ? teamMeta.content : null;

    const permissionsMeta = document.querySelector('meta[name="permissions"]');
    const permissions = permissionsMeta ? permissionsMeta.content.split(',') : [];
    const canExportEvents = permissions.includes('events.export');
    const canImportEvents = permissions.includes('events.import');
    
    const [range, setRange] = React.useState({ start: null, end: null });
    const [filteredEvents, setFilteredEvents] = React.useState([]);
    const [isEventsExportModalOpen, setIsEventsExportModalOpen] = React.useState(false);
    const [isEventsImportModalOpen, setIsEventsImportModalOpen] = React.useState(false);
    const [selectedEvent] = React.useState(null);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    
    const [isLargeScreen, setIsLargeScreen] = React.useState(window.innerWidth >= 1024);
    const [isSmallScreen, setIsSmallScreen] = React.useState(window.innerWidth < 460);

    const { data: events = null, refetch: eventsRefetch } = queryEvents(employeeId, range);
    const { data: primaryPatientsIds = [] } = queryPrimaryPatientsIds(employeeId);

    useEffect(() => {
        if (events) {
            const result = updateEventsByTeamSelection(events, teamSelection, employeeId, primaryPatientsIds);
            updateEventsByRecurrence(result);
            setFilteredEvents(result);
            setIsLoading(false);
        }
    }, [teamSelection, events]);

    const handleEventsUpdated = () => {
        eventsRefetch();
    };

    const handlePrevClick = () => {
        calendarRef.current.getApi().prev();
        updateViewDate(calendarRef.current.getApi().getDate());
    };

    const handleNextClick = () => {
        calendarRef.current.getApi().next();
        updateViewDate(calendarRef.current.getApi().getDate());
    };

    const handleTodayClick = () => {
        calendarRef.current.getApi().today();
        updateViewDate(calendarRef.current.getApi().getDate());
    };

    const handleEventModalClose = (event = null, action = 'created', close = true) => {
        if (event) {
            if (action === 'created' || action === 'updated') {
                event.allDay = event.all_day === 1;

                if (event.event_type_id === 'HOLIDAY') {
                    event.title = t('team-calendar.event-types.holiday', 'Holiday');
                } else if (event.event_type_id === 'SICK') {
                    event.title = t('team-calendar.event-types.sick', 'Sick');
                }

                let newFilteredEvents = [...filteredEvents];

                switch (event.event_type_id) {
                    case 'INTERNAL':
                        event.eventColor = eventColors.internal;
                        break;
                    default:
                        event.eventColor = event.employee_colors.length > 0 && event.employee_colors[0]
                            ? event.employee_colors[0]
                            : eventColors.notAssigned;
                        break;
                }

                event.color = 'transparent';

                if (action === 'created') {
                    newFilteredEvents.push(event);
                    setFilteredEvents(newFilteredEvents);
                } else if (action === 'updated') {
                    for (let i = 0; i < newFilteredEvents.length; i++) {
                        if (newFilteredEvents[i].id === event.id) {
                            newFilteredEvents[i] = event;
                            setFilteredEvents(newFilteredEvents);
                            break;
                        }
                    }
                }
            } else if (action === 'deleted') {
                let newFilteredEvents = [...filteredEvents];
                for (let i = 0; i < newFilteredEvents.length; i++) {
                    if (newFilteredEvents[i].id === event.id) {
                        newFilteredEvents.splice(i, 1);
                        setFilteredEvents(newFilteredEvents);
                        break;
                    }
                }
            }
        }

        if (!event && action === 'deleted') {
            handleEventsUpdated();
        }

        if (close) {
            setSelectedDate(null);
            goToTeamCalendar();
        }
    }

    const handleDateChange = (dateInfo) => {
        setRange({
            start: moment(dateInfo.start).format('YYYY-MM-DD'),
            end: moment(dateInfo.end).format('YYYY-MM-DD')
        });
    }
    const handleDateClick = (arg) => {
        setSelectedDate(arg);
        if (arg.allDay && arg.view.type !== 'timeGridMonth') {
            //goToTeamCalendarNewEvent('holiday');
            goToTeamCalendarNewEvent();
        } else {
            //goToTeamCalendarNewEvent('client_appointment');
            goToTeamCalendarNewEvent();
        }
    }

    const handleEventClick = (arg) => {
        if (arg.event.id) {
            goToTeamCalendarEvent(arg.event.id, arg.event.startStr);
        }
    }

    const gridButtonHandler = (viewType) => {
        const calendarApi = calendarRef.current.getApi();

        switch (viewType) {
            case 'five-days':
                updateGrid(viewType, 'timeGridWeek', [0, 6]);
                calendarApi.changeView('timeGridWeek');
                return;
            case 'single-day':
                updateGrid(viewType, 'timeGridDay', []);
                calendarApi.changeView('timeGridDay');
                return;
            case 'week':
                updateGrid(viewType, 'timeGridWeek', []);
                calendarApi.changeView('timeGridWeek');
                return;
            case 'month':
                updateGrid(viewType, 'dayGridMonth', []);
                calendarApi.changeView('dayGridMonth');
                return;
            case 'list':
                updateGrid(viewType, 'listWeek', []);
                calendarApi.changeView('listWeek');
                return;
        }
    }

    const downloadEventsHandler = () => {
        setIsEventsExportModalOpen(true);
    }

    const importEventsHandler = () => {
        setIsEventsImportModalOpen(true);
    }

    const handleEventsConvert = (file, onFinish) => {
        convertEvents(file, onFinish);
    }

    const handleEventsImport = (file, onFinish, onSuccess) => {
        importEvents(file, onFinish, onSuccess, eventsRefetch);
    }

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 1024);
            setIsSmallScreen(window.innerWidth < 460);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const horizontalSwipeHandler = useHorizontalSwipe({
        onLeftSwipe: handleNextClick,
        onRightSwipe: handlePrevClick
    });


    return <div className="relative" {...horizontalSwipeHandler}>
        {isLoading &&
            <div className="h-1 inset-x-0 overflow-hidden absolute top-0">
                <div className="absolute inset-0 bg-blue-500 animate-pulse"></div>
                {/*<div className="absolute left-0 top-0 bg-blue-500 h-full animate-slide"></div>*/}
            </div>
        }
        <header id='team-calendar-container-header'
            className="sticky top-0 h-20 lg:h-14 items-center justify-between border-b border-gray-200 py-2 px-2 md:px-6 bg-white flex-1 flex-col z-10">
            <div className="flex items-center justify-between">
                <div className="flex">
                    <TeamMemberDropdown />
                    {canExportEvents && !isSmallScreen &&
                        <button onClick={downloadEventsHandler} type="button" className="inline-flex justify-center items-center px-4 py-2 mr-2 text-sm font-medium text-gray-400 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none text-gray-700">
                            <span className="hidden md:block text-gray-700"><Trans i18nKey="team-calendar.export">Export</Trans></span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="sm:block md:hidden w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                            </svg>
                        </button>
                    }
                    {canImportEvents && !isSmallScreen &&
                        <button onClick={importEventsHandler} type="button" className="inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-gray-400 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none text-gray-700">
                            <span className="hidden md:block text-gray-700"><Trans i18nKey="team-calendar.eents-import">Import</Trans></span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="sm:block md:hidden w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                            </svg>
                        </button>
                    }
                </div>
                {isLargeScreen &&
                    <div className="flex justify-center flex-grow">
                        <p className="text-gray-700 text-base font-medium">{calendarRef?.current?.calendar?.currentData?.viewTitle}</p>
                    </div>
                }
                <div className="flex justify-end">
                    <TeamCalendarNavigation
                        gridButtonHandler={gridButtonHandler}
                        handlePrevClick={handlePrevClick}
                        handleTodayClick={handleTodayClick}
                        handleNextClick={handleNextClick}
                    />
                    <div className="hidden md:block mx-6 my-2 h-5 w-px bg-gray-300 flex-shrink-0"></div>
                    <div className="md:hidden lg:mx-1 lg:w-px"></div>

                    <button
                        onClick={() => goToTeamCalendarNewEvent()}
                        type="button"
                        className="inline-flex justify-center items-center px-4 py-2 mr-2 text-sm font-medium text-white bg-blue-500 rounded-md border border-transparent hover:bg-blue-600 focus:outline-none"
                    >
                        <span className="hidden md:block whitespace-nowra">
                            <Trans i18nKey="team-calendar.new-event">New event</Trans>
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="md:hidden w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </button>
                </div>
            </div>
            {!isLargeScreen &&
                <div className="flex justify-center flex-grow my-1">
                    <p className="text-gray-700 text-base font-medium">{calendarRef?.current?.calendar?.currentData?.viewTitle}</p>
                </div>
            }
        </header>
        <div className="relative bg-white">
            <FullCalendar
                ref={calendarRef}
                initialDate={initialDate}
                eventContent={renderEventContent}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin]}
                initialView={initialGridType}
                weekNumbers={true}
                weekText={"KW"}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                allDayText={''}
                datesSet={handleDateChange}
                slotEventOverlap={false}
                headerToolbar={false}
                height={'100vh'}
                contentHeight={'auto'}
                locale={deLocale}
                events={filteredEvents}
                slotDuration={'00:15:00'}
                slotLabelInterval={'01:00:00'}
                hiddenDays={hiddenDays}
                views={{
                    timeGridDay: {
                        type: 'timeGrid',
                        duration: { days: 1 },
                    },
                    timeGridWeek: {
                        type: 'timeGrid',
                        duration: { weeks: 1 },
                    },
                    listWeek: {
                        type: 'list',
                        duration: { days: 7 },
                    }
                }}
                slotLabelFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    meridiem: 'short'
                }}
            // eventContent={eventRender}
            />
        </div>
        <EventsExportModal isOpen={isEventsExportModalOpen} onClose={() => setIsEventsExportModalOpen(false)} />
        <ImportModal
            isOpen={isEventsImportModalOpen}
            onClose={() => setIsEventsImportModalOpen(false)}
            title={t("employees.import-events", "Import Events")}
            handleConvert={handleEventsConvert}
            handleImport={handleEventsImport}
        />

        <Routes>
            <Route
                path="/new-event/*"
                element={
                    <EventTypeConfirmationModal
                        currentUserId={userId}
                        currentEmployeeId={employeeId}
                        teamId={teamId}
                        isOpen={true}
                        event={selectedEvent}
                        onClose={handleEventModalClose}
                        date={selectedDate}
                        handleEventsUpdated={handleEventsUpdated}
                    />
                }
            />
            <Route
                path="/events/:id"
                element={
                    <EventModal
                        currentUserId={userId}
                        currentEmployeeId={employeeId}
                        teamId={teamId}
                        isOpen={true}
                        event={selectedEvent}
                        onClose={handleEventModalClose}
                        date={selectedDate}
                        handleEventsUpdated={handleEventsUpdated}
                    />
                }
            />
        </Routes>
    </div>
}
