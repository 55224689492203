const EventPermissionHandler = (
    event = null,
    currentEmployeeId = -1,
) => {


    const permissionsMeta = document.querySelector('meta[name="permissions"]');
    const permissions = permissionsMeta ? permissionsMeta.content.split(',') : [];
    const canEvents = permissions.includes('events');
    const canTeamEvents = permissions.includes('team.events') || permissions.includes('teams');

    const canEditOwnEvents = permissions.includes('events.edit.own');

    const canEditTeamEvents = canTeamEvents
        || permissions.includes('team.events.update')
        || permissions.includes('events.edit.team-member');
    const mayEditEvent = canEditTeamEvents
        || (event?.employee_ids && event.employee_ids.length === 0)
        || (event?.employee_ids
            && canEditOwnEvents
            && event.employee_ids.some(id => parseInt(id) === parseInt(currentEmployeeId))
        );
    const mayEditGroupEvent = canEvents
        || (event?.employee_ids && event.employee_ids.length === 0)
        || (event?.employee_ids && event.employee_ids.length === 1
            && canEditOwnEvents
            && event.employee_ids.some(id => parseInt(id) === parseInt(currentEmployeeId))
        );

    const mayEditEventClientBased = (clients) => {
        return mayEditEvent
            || (event?.client_id
                && canEditOwnEvents
                && clients.some(client => parseInt(client.id) == parseInt(event.client_id))
            );
    }

    const canDeleteTeamEvents = canTeamEvents
        || permissions.includes('team.events.remove');
    const mayDeleteEvent = canDeleteTeamEvents
        || (event?.employee_ids && event.employee_ids.length === 0)
        || (event?.employee_ids
            && canEditOwnEvents
            && event.employee_ids.some(id => parseInt(id) === parseInt(currentEmployeeId))
        );
    const mayDeleteGroupEvent = canEvents
        || (event?.employee_ids && event.employee_ids.length === 0)
        || (event?.employee_ids && event.employee_ids.length === 1
            && canEditOwnEvents
            && event.employee_ids.some(id => parseInt(id) === parseInt(currentEmployeeId))
        );

    const mayDeleteEventClientBased = (clients) => {
        return mayDeleteEvent
            || (event?.client_id
                && canEditOwnEvents
                && clients.some(client => client.id == event.client_id)
            );
    }

    return {
        canEvents,
        canTeamEvents,
        mayEditEvent,
        mayEditGroupEvent,
        mayEditEventClientBased,
        mayDeleteEvent,
        mayDeleteGroupEvent,
        mayDeleteEventClientBased,
    }
};

export default EventPermissionHandler;