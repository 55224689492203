import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    CircularProgress, FormControl,
    Grid, InputAdornment,
    MenuItem, OutlinedInput,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import useApi from "../../hooks/useApi";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import useRouting from "../../hooks/useRouting";
import FormSection from "../forms/FormSection";

import FormSelect from "../forms/FormSelect";
import FormAutocomplete from "../forms/FormAutocomplete";


const InvoiceReceiver = (props) => {

    const {t} = useTranslation();
    const {id} = useParams();
    const {invoiceReceiverId} = useParams();

    const {
        queryClient,
        queryClientContacts,
        saveOrUpdateInvoiceReceiver,
        queryInvoiceReceiver
    } = useApi();
    const {goToInvoiceTypes, goToInvoiceType} = useRouting();

    const {data: client = null} = queryClient(id);

    const {data: invoiceReceiver = null, isLoading, refetch} = queryInvoiceReceiver(id, invoiceReceiverId);

    const {data: clientContacts = null, isLoading: isLoadingClientContacts} = queryClientContacts(id);
    // const [clientContacts, setClientContacts] = useState(null);

    const [editable, setEditable] = useState(invoiceReceiverId === 'new');

    const [disabled, setDisabled] = useState(false);
    const [invoiceType, setInvoiceType] = useState("all");
    const [type, setType] = useState("");
    const [clientContact, setClientContact] = useState(null);
    const [showValidation, setShowValidation] = useState(false);

    const [errClientContact, setErrClientContact] = useState(false);
    const [errType, setErrType] = useState(false);

    const init = () => {
        setInvoiceType(invoiceReceiver?.data?.invoice_type);
        setType(invoiceReceiver?.data?.type)
        setClientContact(invoiceReceiver?.data?.client_contact)
    }

    useEffect(() => {
        if (invoiceReceiver && invoiceReceiver?.data?.id) {
            init();
        }
    }, [invoiceReceiver]);

    const handleInvoiceTypeChange = (event) => {
        setInvoiceType(event.target.value);
    }

    const handleTypeChange = (event) => {
        setClientContact(null);
        setType(event.target.value);
        setErrType(false);
    }
    const handleCancel = () => {
        setEditable(false);
        if (invoiceReceiver && invoiceReceiver?.data?.id) {
            init();
        } else {
            goToInvoiceTypes(id);
        }
    };

    const formatCurrency = (value) => {
        const formattedValue = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value);
        // Move the Euro sign to the end
        return formattedValue.replace(/€/, '') + '€';
    };

    const validate = () => {
        let valid = true;
        if (type === "") {
            setErrType(true);
            valid = false;
        } else {
            setErrType(false);
        }

        if (type === 'contact' && clientContact === null) {
            setErrClientContact(true);
            valid = false;
        } else {
            setErrClientContact(false);
        }

        return valid;
    }
    const handleSave = () => {
        setDisabled(true);

        if (validate()) {
            let data = {};
            if (invoiceReceiverId && invoiceReceiverId !== "new") {
                data.id = invoiceReceiverId;
            }
            data.client_id = id;
            data.invoice_type = invoiceType;
            data.type = type;
            if(type === 'contact'){
                data.client_contact_id = clientContact?.id;
            }
            else {
                data.client_contact_id = null;
            }


            saveOrUpdateInvoiceReceiver(id, data).then((response) => {
                setDisabled(false);
                setEditable(false);
                if (response.data.id) {
                    refetch();
                    goToInvoiceType(id, response.data.id);
                } else {
                    refetch();
                }
            }).catch((error) => {
                setDisabled(false);
                if (error.response?.status === 422) {
                    let errors = error.response.data.errors;

                }

            });
        } else {
            setShowValidation(true);
            setDisabled(false);
        }
    };

    return (
        <Box sx={{padding: 2}}>
            {isLoading || isLoadingClientContacts ?
                <Stack sx={{flexGrow: 1, padding: 2}} alignItems="center">
                    <CircularProgress/>
                </Stack>
                :
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: 2}}>
                        <NavLink underline="hover" color="inherit" to="/clients">
                            <Trans i18nKey="clients.header">Clients</Trans>
                        </NavLink>
                        <Typography
                            color="text.primary">{client?.person ? client.person.last_name + " " + client.person.first_name : ""}</Typography>
                        <NavLink underline="hover" color="inherit"
                                 to={`/clients/${id}/invoicing`}>
                            <Trans i18nKey="clients.invoice-receiver.header">Invoice recipient</Trans>
                        </NavLink>
                        <Typography
                            color="text.primary">{invoiceReceiver?.data ? t('clients.invoice-receiver.invoice-receiver') + ' (' + t('clients.invoice-receiver.invoice-type.' + invoiceReceiver?.data.invoice_type) + ')' : t('clients.invoice-receiver.new-invoice-receiver', 'New Invoice Receiver')}</Typography>
                    </Breadcrumbs>
                    <Paper elevation={0} sx={{marginBottom: 2, padding: 2, maxWidth: 1024}}>
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Box sx={{paddingLeft: 2, display: 'flex', justifyContent: 'flex-start'}}>
                                    {editable && invoiceReceiver?.data?.id &&
                                        <Typography variant="h5"><Trans
                                            i18nKey={"clients.invoice-receiver.edit-invoice-receiver"}>Edit
                                            Invoice Receiver</Trans></Typography>
                                    }
                                    {editable && !invoiceReceiver?.data &&
                                        <Typography variant="h5"><Trans
                                            i18nKey={"clients.invoice-receiver.new-invoice-receiver"}>Create new Invoice
                                            Receiver
                                        </Trans></Typography>
                                    }
                                    {!editable &&
                                        <Typography
                                            variant="h5">{invoiceReceiver?.data ? t('clients.invoice-receiver.invoice-receiver') + ' (' + t('clients.invoice-receiver.invoice-type.' + invoiceReceiver?.data.invoice_type) + ')' : ''}</Typography>
                                    }

                                </Box>
                            </Grid>
                            <Grid item xs={4} justifyContent="flex-end">
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {!editable &&
                                        <Button
                                            sx={{textTransform: 'none'}}
                                            variant="outlined"
                                            size="small"
                                            disableElevation
                                            onClick={() => {
                                                setEditable(!editable)
                                            }}
                                        >
                                            <Trans i18nKey="employee.edit">Edit</Trans>
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>

                    <FormSection
                        editable={editable}
                        title={t('clients.invoice-receiver.invoice-receiver', 'Invoice Receiver')}
                    >

                        <FormSelect
                            i18nKeyLabel="clients.invoice-receiver.invoice-type.label"
                            editable={editable}
                            valueText={t('clients.invoice-receiver.invoice-type.' + invoiceType)}
                            value={invoiceType}
                            onChange={handleInvoiceTypeChange}
                            disabled={disabled}
                            showValidation={showValidation}
                            required={false}
                            displayEmpty={true}>
                            <MenuItem sx={{fontSize: 14}} value={'original'}><Trans
                                i18nKey="clients.invoice-receiver.invoice-type.original"></Trans></MenuItem>
                            <MenuItem sx={{fontSize: 14}} value={'copy'}><Trans
                                i18nKey="clients.invoice-receiver.invoice-type.copy"></Trans></MenuItem>
                            <MenuItem sx={{fontSize: 14}} value={'all'}><Trans
                                i18nKey="clients.invoice-receiver.invoice-type.all"></Trans></MenuItem>

                        </FormSelect>

                        <FormSelect
                            i18nKeyLabel="clients.invoice-receiver.type.label"
                            editable={editable}
                            valueText={t('clients.invoice-receiver.type.' + type)}
                            value={type}
                            onChange={handleTypeChange}
                            disabled={disabled}
                            showValidation={showValidation}
                            error={errType}
                            displayEmpty={true}>
                            <MenuItem sx={{fontSize: 14}} value={'client'}><Trans
                                i18nKey="clients.invoice-receiver.type.client"></Trans></MenuItem>
                            <MenuItem sx={{fontSize: 14}} value={'insurance'}><Trans
                                i18nKey="clients.invoice-receiver.type.insurance"></Trans></MenuItem>
                            <MenuItem sx={{fontSize: 14}} value={'contact'}><Trans
                                i18nKey="clients.invoice-receiver.type.contact"></Trans></MenuItem>

                        </FormSelect>
                        {type === 'contact' &&
                            <FormAutocomplete
                                value={clientContact}
                                editable={editable}
                                onChange={(newValue) => {
                                    setClientContact(newValue);
                                }}
                                i18nKeyLabel="clients.invoice-receiver.contact.label"
                                i18nKeyPlaceholder="clients.invoice-receiver.contact.placeholder"
                                options={clientContacts?.data}
                                getOptionLabel={(option) => option.person.last_name + ', ' + option.person.first_name}
                                valueText={clientContact?.person ? clientContact.person.last_name + ', ' + clientContact.person.first_name : '-'}
                                disabled={disabled}
                                showValidation={showValidation}
                                error={showValidation && errClientContact}
                            />
                        }

                        {editable && (
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        paddingRight: 2,
                                        paddingTop: 2,
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                marginRight: 2,
                                                textTransform: 'none', background: '#fff'
                                            }}
                                            onClick={handleCancel}
                                        >
                                            <Trans i18nKey="institutions.cancel">Cancel</Trans>
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            disableElevation
                                            sx={{textTransform: 'none'}}
                                            onClick={handleSave}
                                        >
                                            <Trans i18nKey="institutions.save">Save</Trans>
                                        </Button>

                                    </Box>
                                </Grid>
                            </Grid>
                        )}

                    </FormSection>

                </>
            }
        </Box>
    );
}

export default InvoiceReceiver;
