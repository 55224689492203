import { Trans } from "react-i18next";

const EventSaveCancelActions = ({
    editable,
    handleCancel,
    handleSave,
}) => {


    return (
        <div className="mt-4 flex justify-between">
            {editable ?
                <>
                    <button
                        type="button"
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handleCancel}
                    >
                        <Trans i18nKey="team-calendar.event-modal.cancel">Cancel</Trans>
                    </button>
                    <button
                        type="submit"
                        className="inline-flex items-center border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-3 focus:ring-blue-400"
                        onClick={handleSave}
                    >
                        <Trans i18nKey="team-calendar.event-modal.save">Save</Trans>
                    </button>
                </>
                :
                <></>
            }
        </div>
    )
};

export default EventSaveCancelActions;