import './bootstrap';

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

// import {Datepicker} from "flowbite-datepicker";
window.Datepicker = require('flowbite-datepicker');

import './frontend_config';

require('./components/dashboard/DashboardContainer');
require('./components/TeamCalendarContainer');
require('./components/institutions/InstitutionsContainer');
require('./components/internal-event-types/InternalEventTypesContainer');
require('./components/employees/EmployeesContainer');
require('./components/clients/ClientsContainer');
require('./components/general/DataPrivacyContainer');
require('./components/general/ImprintContainer');


document.addEventListener('DOMContentLoaded', function () {
  // Detect the React component's div
  const calendarDiv = document.getElementById('teamView');
  const mainContainer = document.getElementById('main-container');

  if (calendarDiv) {
      // Apply a different class based on React component's id
      mainContainer.classList.remove('overflow-auto');
      mainContainer.classList.add('overflow-hidden');
  }
});