import {
    Box,
    Button,
    FormControl,
    Grid,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TextField
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import useApi from "../../hooks/useApi";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import moment from "moment";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {DateTime} from "luxon";
import useRouting from "../../hooks/useRouting";
import { useNavigate } from "react-router-dom";
import ClientMasterData from "./ClientMasterData";
import ClientAdministrativeData from "./ClientAdministrativeData";

const ClientGeneral = ({client, editable, setEditable}) => {

    const {t} = useTranslation();
    const {id} = useParams();

    const {saveOrUpdateClient} = useApi();
    const { goToClients } = useRouting();
    const navigate = useNavigate();

    const [disabled, setDisabled] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [isClientMasterDataValid, setIsClientMasterDataValid] = useState(false);
    const [isClientAdministrativeDataValid, setIsClientAdministrativeDataValid] = useState(false);
    const initClientMasterData = () => {
        return {
            id: client?.id ?? '',
            title: client?.person?.title ?? '',
            firstName: client?.person?.first_name ?? '',
            lastName: client?.person?.last_name ?? '',
            gender: client?.person?.gender ?? '',
            street: client?.person?.address?.street ?? '',
            streetNumber: client?.person?.address?.street_number ?? '',
            zip: client?.person?.address?.zip ?? '',
            city: client?.person?.address?.city ?? '',
            birthdate: client?.person?.birthdate ? DateTime.fromFormat(client?.person?.birthdate, 'yyyy-MM-dd') : null,
            email: client?.person?.email ?? '',
            phone: client?.phone_home?.phone_number ?? '',
            phoneCountryCode: client?.phone_home?.country_code ?? 'DE',
            phoneMobile: client?.phone_mobile?.phone_number ?? '',
            phoneCountryCodeMobile: client?.phone_mobile?.country_code ?? 'DE',
        };
    }
    const initClientAdministrativeData = () => {
        const healthInsurances = client?.insurances?.length > 0 ? client?.insurances.filter((institution) => institution.type === 'HEALTH_INSURANCE' || institution.type === 'PRIVATE_HEALTH_INSURANCE') : [];
        const careInsurances = client?.insurances?.length > 0 ? client?.insurances.filter((institution) => institution.type === 'CARE_INSURANCE' || institution.type === 'PRIVATE_CARE_INSURANCE') : [];
        const careDegrees = client?.care_degrees ?? [];
        const healthInsurancesData = healthInsurances.map((insurance) => {
            return {
                id: insurance.pivot.id,
                insuranceId: insurance.id,
                persisted: true,
                shortname: insurance.shortname,
                insuranceNumber: insurance.pivot.insurance_number,
                validFrom: insurance.pivot.valid_from ? DateTime.fromFormat(insurance.pivot.valid_from, 'yyyy-MM-dd') : null,
                validTo: insurance.pivot.valid_to ? DateTime.fromFormat(insurance.pivot.valid_to, 'yyyy-MM-dd') : null,
            }
        });
        const careInsurancesData = careInsurances.map((insurance) => {
            return {
                id: insurance.pivot.id,
                insuranceId: insurance.id,
                persisted: true,
                shortname: insurance.shortname,
                insuranceNumber: insurance.pivot.insurance_number,
                validFrom: insurance.pivot.valid_from ? DateTime.fromFormat(insurance.pivot.valid_from, 'yyyy-MM-dd') : null,
                validTo: insurance.pivot.valid_to ? DateTime.fromFormat(insurance.pivot.valid_to, 'yyyy-MM-dd') : null,
            }
        });
        const careDegreesData = careDegrees.map((careDegree) => {
            return {
                id: careDegree.id,
                degree: careDegree.degree,
                persisted: true,
                validFrom: careDegree.valid_from ? DateTime.fromFormat(careDegree.valid_from, 'yyyy-MM-dd') : null
            }
        });

        let disabilityCodesData = [];
        if (client?.disability_code) {
            disabilityCodesData = Object.keys(client?.disability_code).map((disabilityCode) => {
                return {
                    id: disabilityCode,
                    key: disabilityCode
                }
            });
        }
        let livingSituationData = [];
        if (client?.living_situation) {
            livingSituationData = Object.keys(client?.living_situation).map((livingSituation) => {
                return {
                    id: livingSituation,
                    key: livingSituation
                }
            });
        }
        return {
            id: client?.id ?? '',
            clientNumber: client?.client_number ?? '',
            employee: client?.employees && client?.employees.length > 0 ? client?.employees[0] : null, 
            secondaryEmployee: client?.employees && client?.employees.length > 1 ? client?.employees[1] : null,
            contractStart: client?.contract_start ? DateTime.fromFormat(client?.contract_start, 'yyyy-MM-dd') : null,
            contractEnd: client?.contract_end ? DateTime.fromFormat(client?.contract_end, 'yyyy-MM-dd') : null,
            healthInsurances: healthInsurancesData,
            careInsurances: careInsurancesData,
            careDegrees: careDegreesData,
            disabilityCodes: disabilityCodesData,
            aidGrantPercent: client?.aid_grant_percent,
            physician: client?.physician ? client?.physician : null,
            livingSituation: livingSituationData,
            note: client?.note ?? ''
        };
    }

    const initErrClientMasterData = () => {
        return {
            firstName: '',
            lastName: '',
            gender: '',
            street: '',
            streetNumber: '',
            zip: '',
            city: '',
            birthdate: ''
        };
    }

    const initErrClientAdministrativeData = () => {
        return {
            clientNumber: '',
            contractStart: '',
            note: '',
            employee: ''
        };
    }
    
    const [errClientMasterData, setErrClientMasterData] = useState(initErrClientMasterData());
    const [errClientAdministrativeData, setErrClientAdministrativeData] = useState(initErrClientAdministrativeData());
    const [clientMasterData, setClientMasterData] = useState(initClientMasterData());
    const [clientAdministrativeData, setClientAdministrativeData] = useState(initClientAdministrativeData());

    useEffect(() => {
        setClientMasterData(initClientMasterData());
        setClientAdministrativeData(initClientAdministrativeData());
    }, [client]);

    const handleCancel = () => {
        setShowValidation(false);
        goToClients();
    }

    const validate = () => {
        return isClientAdministrativeDataValid && isClientMasterDataValid;
    }

    const handleSave = () => {
        setDisabled(true);

        if (validate()) {
            setShowValidation(true);
            let data = {};
            if (client?.id) {
                data.id = id;
            }
            data.client_number = clientAdministrativeData.clientNumber
            data.title = ['doctor', 'professor', 'professor-doctor'].indexOf(clientMasterData.title) !== -1 ? clientMasterData.title : '';
            data.first_name = clientMasterData.firstName;
            data.last_name = clientMasterData.lastName;
            data.gender = clientMasterData.gender;
            data.street = clientMasterData.street;
            data.street_number = clientMasterData.streetNumber;
            data.zip = clientMasterData.zip;
            data.city = clientMasterData.city;
            data.birthdate = clientMasterData.birthdate ? clientMasterData.birthdate.toFormat('yyyy-MM-dd') : null;
            data.email = clientMasterData.email;
            data.phone = clientMasterData.phone;
            data.phone_country = clientMasterData.phoneCountryCode;
            data.phone_mobile = clientMasterData.phoneMobile;
            data.phone_country_mobile = clientMasterData.phoneCountryCodeMobile;
            data.contract_start = clientAdministrativeData.contractStart ? clientAdministrativeData.contractStart.toFormat('yyyy-MM-dd') : null;
            data.contract_end = clientAdministrativeData.contractEnd ? clientAdministrativeData.contractEnd.toFormat('yyyy-MM-dd') : null;
            data.aid_grant_percent = clientAdministrativeData.aidGrantPercent;
            data.physician_id = clientAdministrativeData.physician?.id ?? null;
            data.living_situation = clientAdministrativeData.livingSituation.map((livingSituation) => livingSituation.id);
            data.note = clientAdministrativeData.note;
            data.disability_code = clientAdministrativeData.disabilityCodes.map((disabilityCode) => disabilityCode.id);
            data.care_degrees = clientAdministrativeData.careDegrees.map((careDegree) => {
                return {
                    id: careDegree.id,
                    degree: careDegree.degree,
                    valid_from: careDegree.validFrom ? careDegree.validFrom.toFormat('yyyy-MM-dd') : null,
                }
            });
            data.health_insurances = clientAdministrativeData.healthInsurances.map((insurance) => {
                return {
                    id: insurance.id,
                    institution_id: insurance.insuranceId,
                    insurance_number: insurance.insuranceNumber,
                    valid_from: insurance.validFrom ? insurance.validFrom.toFormat('yyyy-MM-dd') : null,
                    valid_to: insurance.validTo ? insurance.validTo.toFormat('yyyy-MM-dd') : null,
                }
            });
            data.care_insurances = clientAdministrativeData.careInsurances.map((insurance) => {
                return {
                    id: insurance.id,
                    institution_id: insurance.insuranceId,
                    insurance_number: insurance.insuranceNumber,
                    valid_from: insurance.validFrom ? insurance.validFrom.toFormat('yyyy-MM-dd') : null,
                    valid_to: insurance.validTo ? insurance.validTo.toFormat('yyyy-MM-dd') : null,
                }
            });
            if (clientAdministrativeData.employee) {
                data.employees = [clientAdministrativeData.employee.id];
            } else {
                data.employees = [];
            } 
            if (clientAdministrativeData.secondaryEmployee) {
                data.employees.push(clientAdministrativeData.secondaryEmployee.id)
            }

            saveOrUpdateClient(data).then((response) => {
                setDisabled(false);
                setEditable(false);

                if (!clientAdministrativeData?.id && response.id) {
                    navigate(`/clients/${response.id}/general`);
                }
            }).catch((error) => {
                setDisabled(false);
                if (error.response?.status === 422 || error.response?.status === 500) {
                    const errors = error.response.data?.errors;
                    if (errors) {
                        setErrClientMasterData({  
                            firstName: errors.first_name ?? '',
                            lastName: errors.last_name ?? '',
                            gender: errors.gender ?? '',
                            street: errors.street ?? '',
                            streetNumber: errors.street_number ?? '',
                            city: errors.city ?? '',
                            zip: errors.zip ?? '',
                            birthdate: errors.birthdate ?? '',
                            email: errors.email ?? '',
                        })
                        setErrClientAdministrativeData({  
                            clientNumber: errors.client_number ?? '',
                            contractStart: errors.contract_start ?? '',
                            note: errors.note ?? '',
                            employee: errors.employee ?? '',
                        })
                    }
                }
            });
        } else {
            setDisabled(false);
            setShowValidation(true);
        }
    };

    return (
        <>
            <ClientMasterData 
                clientMasterData={clientMasterData}
                setClientMasterData={setClientMasterData}
                errClientMasterData={errClientMasterData}
                editable={editable} setEditable={setEditable}
                onSave={handleSave}
                onCancel={handleCancel}
                setIsValid={setIsClientMasterDataValid}
                showValidation={showValidation} 
            />
            <ClientAdministrativeData 
                clientAdministrativeData={clientAdministrativeData}
                setClientAdministrativeData={setClientAdministrativeData}
                errClientAdministrativeData={errClientAdministrativeData}
                editable={editable}
                onSave={handleSave}
                onCancel={handleCancel}
                setIsValid={setIsClientAdministrativeDataValid}
                showValidation={showValidation}
                showButtons={true} 
            />
            {editable && false &&  (
                <Paper elevation={0} sx={{padding: 2, maxWidth: 1024}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{paddingRight: 2, display: 'flex', justifyContent: 'flex-end'}}>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        marginRight: 2,
                                        textTransform: 'none', background: '#fff'
                                    }}
                                    onClick={handleCancel}
                                >
                                    <Trans i18nKey="clients.cancel">Cancel</Trans>
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disableElevation
                                    sx={{textTransform: 'none'}}
                                    onClick={handleSave}
                                >
                                    <Trans i18nKey="clients.save">Save</Trans>
                                </Button>

                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            )}
        </>
    )
}

export default ClientGeneral;
