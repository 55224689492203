import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled } from '@mui/material/styles';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

import moment from "moment";

import { useCalendarViewStore } from "../../store/CalendarViewStore";

const TeamCalendarFilterCalendar = ({
    handleGoToDateClick,
}) => {
    const theme = useTheme();

    const CustomPickersDay = styled(
        PickersDay,
        {
            shouldForwardProp: (prop) => prop !== 'isSelected'
                && prop !== 'isHovered'
                && prop !== 'roundCornersOnSelection'
                && prop !== 'roundCornersOnHover',
        }
    )
        (({
            isSelected,
            isHovered,
            roundCornersOnSelection,
            roundCornersOnHover,
        }) => ({
            borderRadius: 0,
            ...(isSelected && {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.primary.main,
                },
            }),
            ...(isHovered && {
                backgroundColor: theme.palette.primary.light,
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.primary.light,
                },
            }),
            ...(isSelected && roundCornersOnSelection.left && {
                borderTopLeftRadius: '50%',
                borderBottomLeftRadius: '50%',
            }),
            ...(isSelected && roundCornersOnSelection.right && {
                borderTopRightRadius: '50%',
                borderBottomRightRadius: '50%',
            }),
            ...(isHovered && roundCornersOnHover.left && {
                borderTopLeftRadius: '50%',
                borderBottomLeftRadius: '50%',
            }),
            ...(isHovered && roundCornersOnHover.right && {
                borderTopRightRadius: '50%',
                borderBottomRightRadius: '50%',
            }),
        }));

    const isInSameView = (dayA, dayB, viewType) => {
        if (!dayB || !viewType) {
            return false;
        }

        // Sunday = 0, Saturday = 6
        const isWeekday = (dayA.day() % 6) !== 0;

        switch (viewType) {
            case 'list':
                if (dayB.day() === 0) {
                    if (dayA.isSame(dayB, 'week') && dayA.day() === 0) {
                        return true;
                    }

                    return (dayA.isoWeek() === dayB.isoWeek() + 1)
                        && (dayA.day() % 7 > 0)
                }

                if (dayA.isSame(dayB, 'week')) {
                    return (dayA.day() === 0) || (dayA.day() % 7 >= dayB.day() % 7);
                }

                if (dayA.isoWeek() === dayB.isoWeek() + 1) {
                    return (dayA.day() !== 0) && (dayA.day() % 7 < dayB.day() % 7);
                }

                return false;
            case 'week':
                return dayA.isSame(dayB, 'week');
            case 'five-days':
                return isWeekday && dayA.isSame(dayB, 'week');
            case 'month':
                return dayA.isSame(dayB, 'month');
        }
    };

    const roundCorners = (dayA, dayB, viewType, day) => {
        if (!dayB || !viewType) {
            return { left: false, right: false };
        }

        switch (viewType) {
            case 'list':
                return {
                    left: dayA.day() === day.day(),
                    right: dayA.day() === ((day.day() + 6) % 7)
                }
            case 'week':
                return {
                    left: dayA.day() === 1,
                    right: dayA.day() === 0
                };
            case 'five-days':
                return {
                    left: dayA.day() === 1,
                    right: dayA.day() === 5
                };
            case 'month':
                return {
                    left: dayA.day() === 1 || (dayA.month() === dayB.month() && dayA.date() === moment(dayB).startOf('month').date()),
                    right: dayA.day() === 0 || (dayA.month() === dayB.month() && dayA.date() === moment(dayB).endOf('month').date())
                };
        }
    };

    const ViewPicker = ({
        day,
        selectedDay,
        hoveredDay,
        viewType,
        ...other
    }) => {
        return (
            <CustomPickersDay
                {...other}
                day={day}
                disableMargin
                selected={false}
                isSelected={isInSameView(day, selectedDay, viewType)}
                isHovered={isInSameView(day, hoveredDay, viewType)}
                roundCornersOnSelection={roundCorners(day, selectedDay, viewType, selectedDay)}
                roundCornersOnHover={roundCorners(day, hoveredDay, viewType, hoveredDay)}
            />
        );
    }

    const currentViewDate = useCalendarViewStore(state => state.viewDate);
    const currentViewType = useCalendarViewStore(state => state.viewType);

    const isCustomizedView = currentViewType !== 'single-day';

    const [selectedDay, setSelectedDay] = useState(moment(currentViewDate));
    const [hoveredDay, setHoveredDay] = useState(null);

    useEffect(() => {
        setSelectedDay(moment(currentViewDate));
    }, [currentViewDate]);

    const dateCalendarStyling = {
        width: '225px',
        height: '320px',
        fontSize: '12px',
        '& .MuiYearCalendar-root': {
            width: '225px',
            height: '320px',
        },
        '& .MuiPickersYear-yearButton, .MuiPickersCalendarHeader-label': {
            fontSize: '12px',
        },
    };

    return (
        <>
            {isCustomizedView &&
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateCalendar
                        sx={dateCalendarStyling}
                        value={selectedDay}
                        onChange={(newValue) => handleGoToDateClick(newValue.format('YYYY-MM-DD'))}
                        showDaysOutsideCurrentMonth
                        displayWeekNumber
                        slots={{ day: ViewPicker }}
                        slotProps={{
                            day: (ownerState) => ({
                                selectedDay: selectedDay,
                                hoveredDay,
                                viewType: currentViewType,
                                onPointerEnter: () => setHoveredDay(ownerState.day),
                                onPointerLeave: () => setHoveredDay(null),
                            }),
                        }}
                    />
                </LocalizationProvider>
            }
            {!isCustomizedView &&
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateCalendar
                        sx={{
                            ...dateCalendarStyling,
                            '& .MuiPickersDay-root': {
                                '&:hover, &:focus': {
                                    backgroundColor: theme.palette.primary.light,
                                },
                            },
                        }}
                        value={selectedDay}
                        onChange={(newValue) => handleGoToDateClick(newValue.format('YYYY-MM-DD'))}
                        showDaysOutsideCurrentMonth
                        displayWeekNumber
                    />
                </LocalizationProvider>
            }
        </>
    );
};

export default TeamCalendarFilterCalendar;