import { Trans } from "react-i18next";
import TeamCalendarNavigation from "./TeamCalendarNavigation";
import TuneIcon from '@mui/icons-material/Tune';
import { useCalendarViewStore } from "../store/CalendarViewStore";
import { useEffect, useState } from "react";
import moment from "moment";

const TeamCalendarHeader = ({
    filter,
    isLargeScreen,
    gridButtonHandler,
    handlePrevClick,
    handleTodayClick,
    handleNextClick,
}) => {
    const isFilterDrawerOpen = useCalendarViewStore(state => state.isFilterDrawerOpen);
    const toggleFilterDrawer = useCalendarViewStore(state => state.toggleFilterDrawer);

    const toggleEventTypeConfirmationModal = useCalendarViewStore(state => state.toggleEventTypeConfirmationModal);

    const activeFilterCount = filter ? filter.size : 0;

    const viewDate = useCalendarViewStore(state => state.viewDate);
    const gridType = useCalendarViewStore(state => state.gridType);
    const hiddenDays = useCalendarViewStore(state => state.hiddenDays);

    const getCalendarTimeInterval = () => {
        const momentDate = moment(viewDate);
        let start = null;
        let end = null;

        switch (gridType) {
            case 'timeGridDay':
                return momentDate.format('DD. MMMM YYYY');
            case 'timeGridWeek':
                const dayRange = hiddenDays?.length == 0 ? 6 : 4;
                start = momentDate.startOf('week');
                end = start.clone().add(dayRange, 'days');
                break;
            case 'listWeek':
                start = momentDate;
                end = momentDate.clone().add(6, 'days');
                break;
            case 'dayGridMonth':
                start = momentDate.startOf('month');
                end = start.clone().endOf('month');
                break;
            default:
                return "";
        }

        if (start.month() === end.month() && start.year() === end.year()) {
            return `${start.format('DD')} – ${end.format('DD. MMMM YYYY')}`; // Same month and year
        }
        
        if (start.year() === end.year()) {
            return `${start.format('DD. MMM')} – ${end.format('DD. MMMM YYYY')}`; // Same year but different months
        }
            
        return `${start.format('DD. MMM YYYY')} – ${end.format('DD. MMM YYYY')}`; // Different years
    }

    const [title, setTitle] = useState(getCalendarTimeInterval());
    useEffect(() => { setTitle(getCalendarTimeInterval); }, [viewDate, gridType, hiddenDays]);

    return (
        <>
            <header id='team-calendar-container-header'
                className="sticky top-0 h-20 lg:h-14 items-center justify-between border-b border-gray-200 py-2 px-2 md:px-6 bg-white flex-1 flex-col z-10">
                <div className="flex items-center justify-between">
                    {!isFilterDrawerOpen &&
                        <div className="px-4 py-1.5 text-sm font-medium text-gray-400 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none flex">
                            <button onClick={() => toggleFilterDrawer(true)} type="button">
                                <TuneIcon />
                            </button>
                            <div className={`ml-2 px-2 border rounded-full text-white ${activeFilterCount === 0 ? 'bg-gray-400' : 'bg-blue-500'}`}>
                                {activeFilterCount}
                            </div>
                        </div>
                    }
                    {isLargeScreen &&
                        <div className="flex justify-center flex-grow">
                            <p className="text-gray-700 text-base font-medium">{title}</p>
                        </div>
                    }
                    <div className="flex justify-end">
                        <TeamCalendarNavigation
                            gridButtonHandler={gridButtonHandler}
                            handlePrevClick={handlePrevClick}
                            handleTodayClick={handleTodayClick}
                            handleNextClick={handleNextClick}
                        />
                        <div className="hidden md:block mx-6 my-2 h-5 w-px bg-gray-300 flex-shrink-0"></div>
                        <div className="md:hidden lg:mx-1 lg:w-px"></div>

                        <button
                            onClick={() => toggleEventTypeConfirmationModal(true)}
                            type="button"
                            className="inline-flex justify-center items-center px-4 py-2 mr-2 text-sm font-medium text-white bg-blue-500 rounded-md border border-transparent hover:bg-blue-600 focus:outline-none"
                        >
                            <span className="hidden md:block whitespace-nowra">
                                <Trans i18nKey="team-calendar.new-event">New event</Trans>
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="md:hidden w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                        </button>
                    </div>
                </div>
                {!isLargeScreen &&
                    <div className="flex justify-center flex-grow my-1">
                        <p className="text-gray-700 text-base font-medium">{title}</p>
                    </div>
                }
            </header>
        </>
    )
};

export default TeamCalendarHeader;
