import { Trans } from "react-i18next";

const EventInternalEventTypeSelectionModal = ({
    editable,
    internalEventTypeId,
    setInternalEventTypeId,
    internalEventTypes,
    internalEventTypeIdError,
}) => {


    const sortedInternalEventTypes = internalEventTypes.sort(function (type1, type2) {
        if (type1.type < type2.type) {
            return -1;
        }
        if (type1.type > type2.type) {
            return 1;
        }
        return 0;
    });


    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">

            <label 
                htmlFor="event-type" 
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
                <Trans i18nKey="team-calendar.event-modal.internal-event-type">Internal Event Type</Trans>
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2">
                {editable 
                    ?
                    <select
                        id="internal-event-type"
                        name="internal-event-type"
                        value={internalEventTypeId}
                        onChange={(e) => setInternalEventTypeId(e.target.value)}
                        className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none sm:text-sm ${internalEventTypeIdError ? "border-red-500" : "border-gray-300"}`}
                    >
                        <option disabled value={-1}>
                            <Trans i18nKey="team-calendar.event-modal.select-internal-event-type">Select an internal event type</Trans>
                        </option>
                        {sortedInternalEventTypes.map(eventType => (
                            <option key={eventType.id} value={eventType.id}>
                                {eventType.type}
                            </option>
                        ))}
                    </select>
                    :
                    <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                        {internalEventTypeId
                            ? internalEventTypes.find((eventType) => eventType.id === parseInt(internalEventTypeId))?.type || "-"
                            : "-"}
                    </div>
                }

                {internalEventTypeIdError && (
                    <p className="mt-2 text-sm text-red-600">
                        <Trans i18nKey="team-calendar.event-modal.error.internal-event-type">
                            Please select a valid internal event type.
                        </Trans>
                    </p>
                )}
            </div>
        </div>
    );

};

export default EventInternalEventTypeSelectionModal;
