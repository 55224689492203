import { Trans, useTranslation } from "react-i18next";

const EventTitleSelectionModal = ({
    editable,
    title,
    setTitle,
    titleError,
}) => {
    const { t } = useTranslation();

    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">

            <label
                htmlFor="title"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
                <Trans i18nKey="team-calendar.event-modal.title">Title</Trans>
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2">
                {editable
                    ?
                    <div className="sm:grid-row-2">
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white shadow-sm focus:outline-none  sm:text-sm ${titleError ? "border-red-500" : "border-gray-300"}`}
                            placeholder={t('team-calendar.event-modal.enter-title')}
                        />

                        {titleError &&
                            <p className="mt-2 text-sm text-red-600">
                                <Trans i18nKey="team-calendar.event-modal.error.select-title">
                                    Please select a title.
                                </Trans>
                            </p>
                        }
                    </div>
                    :
                    <div
                        className="text-sm text-gray-700 sm:mt-px sm:pt-2">{title}</div>
                }
            </div>
        </div>
    )
};

export default EventTitleSelectionModal;