import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Trans } from "react-i18next";
import EventModal from "../EventModal";
import useRouting from "../../hooks/useRouting"
import { Route, Routes } from "react-router-dom";

const EventTypeConfirmationModal = ({
    currentUserId,
    currentEmployeeId,
    teamId,
    isOpen,
    event,
    onClose,
    date,
    handleEventsUpdated,
}) => {


    const EVENT_TYPES = ['client_appointment', 'internal', 'holiday', 'sick'];


    const { goToTeamCalendarNewEvent } = useRouting();


    const [isOpenInternal, setIsOpenInternal] = useState(isOpen);
    const [eventType, setEventType] = useState('');


    useEffect(() => {
        setIsOpenInternal(isOpen);
    }, [isOpen]);


    const handleEventType = (eventType) => {
        setEventType(eventType);
        setIsOpenInternal(false);
    };


    const handleAfterLeave = () => {
        if (eventType) {
            goToTeamCalendarNewEvent(eventType);
        }
    }


    return (
        <div>
            <Transition.Root show={isOpenInternal} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-20 inset-0 overflow-y-auto"
                    onClose={() => onClose(null)}
                >
                    <div className="flex items-center justify-center min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={handleAfterLeave}
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <span
                            className="inline-block align-bottom bg-white rounded-lg px-4 py-3 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                        >
                            <div className="mt-2 mb-4">
                                <Trans i18nKey={"team-calendar.event-type-selection"}>
                                    Which type of event do you want to create?
                                </Trans>
                            </div>
                            <div className="mt-4 flex flex-col justify-evenly">

                                {EVENT_TYPES.map((eventType) => (
                                    <button
                                        key={"event-type." + eventType}
                                        type="button"
                                        className="my-1 flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={() => handleEventType(eventType)}
                                    >
                                        <Trans i18nKey={"team-calendar.event-types." + eventType}>
                                            {eventType}
                                        </Trans>
                                    </button>
                                ))}

                                <button
                                    type="button"
                                    className="my-1 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                    onClick={() => onClose(null)}
                                >
                                    <Trans i18nKey="team-calendar.confirmation-modal.cancel">Cancel</Trans>
                                </button>

                            </div>
                        </span>
                    </div>
                </Dialog>
            </Transition.Root>

            <Routes>
                <Route
                    path="/:type"
                    element={
                        <EventModal
                            currentUserId={currentUserId}
                            currentEmployeeId={currentEmployeeId}
                            teamId={teamId}
                            isOpen={true}
                            event={event}
                            onClose={onClose}
                            date={date}
                            handleEventsUpdated={handleEventsUpdated}
                        />
                    }
                />
            </Routes>
        </div>
    );


};

export default EventTypeConfirmationModal;
