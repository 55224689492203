import { Autocomplete, TextField } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

const EventPropertiesSelectionModal = ({
    editable,
    eventPropertiesOptions,
    selectedEventProperties,
    setSelectedEventProperties,
    selectedEventPropertiesError,
}) => {
    const { t } = useTranslation();


    const sortEventProperties = (options) => {
        return options?.sort((a, b) => {
            if (a.name && b.name) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
            }
            return 0;
        }) ?? [];
    }


    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">

            <label
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
                <Trans i18nKey="team-calendar.event-modal.properties">Properties</Trans>
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2">
                {editable
                    ?
                    <Autocomplete
                        noOptionsText={t('forms.no-options', 'No options')}
                        multiple
                        size="small"
                        value={selectedEventProperties}
                        onChange={(_, newValue) => {
                            const sortedNewValue = sortEventProperties(newValue);
                            setSelectedEventProperties(sortedNewValue);
                        }}
                        options={sortEventProperties(eventPropertiesOptions)}
                        getOptionLabel={(option) => option.name ?? ""}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={'form-autocomplete-option-' + option.id}>
                                    {option.name}
                                </li>
                            );
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        filterSelectedOptions
                        sx={{
                            fontSize: '14px !important',
                            '& input': {
                                fontSize: 14,
                                paddingLeft: '6px',
                            },
                            '& .MuiAutocomplete-listbox': {
                                fontSize: '14px !important',
                            }
                        }}
                        ListboxProps={{
                            sx: { fontSize: 14 },
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={t('event-properties.placeholder', 'Select event properties')}
                                error={!!selectedEventPropertiesError}
                                helperText={selectedEventPropertiesError}
                            />
                        )}
                    />
                    :
                    <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                        {
                            selectedEventProperties && selectedEventProperties.length > 0
                                ? selectedEventProperties
                                    .map(property => property.name)
                                    .join(', ')
                                : '-'
                        }
                    </div>
                }

                {selectedEventPropertiesError && (
                    <p className="mt-2 text-sm text-red-600">
                        <Trans i18nKey="team-calendar.event-modal.error.event-properties">
                            Please select at least one valid event property.
                        </Trans>
                    </p>
                )}
            </div>
        </div>
    )
};

export default EventPropertiesSelectionModal;