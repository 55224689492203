// TooltipContent.js
import React, { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";


const getDateTime = (date) => {
  const hours = String(date.getHours()).padStart(2, '0');   // "05"
  const minutes = String(date.getMinutes()).padStart(2, '0'); // "15";
  return `${hours}:${minutes}`
}
const getDate = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // 11 (Months are 0-indexed)
  const day = date.getDate(); // 11
  return `${day}.${month}.${year}`;
}

const getFormattedDate = (event) => {
  const start = event.start;
  const end =  event.end;
  if (start && end) {
    
    return getDate(start) + ", " + getDateTime(start) + " - " + getDateTime(end); 
  }
  return null;

}

 const getClient = (eventInfo) => {
  const client = eventInfo.client;
  if (!client) {
    return null;
  }
  const person = client.person;
  if (!person) {
    return null;
  }
  const firstName = person.first_name;
  const lastName = person.last_name;
  
  return lastName + ' ' + firstName;
}

 const getEmployees = (eventInfo) => {
  const employees = eventInfo.employees;
  if (!employees) {
    return [];
  }
  const employeeNames = employees.map(employee => {
    return (employee.person?.last_name ?? '') + ' ' + (employee.person?.first_name ?? '');
  });

  return employeeNames;
}

const getBudget = (eventInfo) => {
  const budget = eventInfo.budget;
  if (!budget) {
    return null;
  } 
  return budget.name; 
}

const getService = (eventInfo) => {
  const service = eventInfo.service;
  if (!service) {
    return null;
  } 
  return service.name; 
}

const getLocation = (eventInfo) => {
  return eventInfo.location == '' ? null : eventInfo.location;
}

const TooltipContent = ({event, eventInfo}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {getFormattedDate(event) &&
        <div><em>{t('team-calendar.event.tooltip.date', 'Date')}:</em> {getFormattedDate(event)}</div>
      }
      {getClient(eventInfo) &&
        <div><em>{t('team-calendar.event.tooltip.client', 'Client')}:</em> {getClient(eventInfo)}</div>
      }
      {getEmployees(eventInfo).length > 0 &&
        <div><em>{t('team-calendar.event.tooltip.employee', 'Employee')}:</em> 
        {' '}
        {getEmployees(eventInfo).map((employee, index) => (
          <React.Fragment key={index}>
            {index === 0 ? (
              <span>{employee}</span> // First employee stays on the same line as the label
            ) : (
              <div>{employee}</div> // Additional employees start on new lines
            )}
          </React.Fragment>
        ))}
      </div>
      }
      {getService(eventInfo) && 
        <div><em>{t('team-calendar.event.tooltip.service', 'Service')}:</em> {getService(eventInfo)}</div>
      }
      {getBudget(eventInfo) &&
        <div><em>{t('team-calendar.event.tooltip.budget', 'Budget')}:</em> {getBudget(eventInfo)}</div>
      }
      {getLocation(eventInfo) &&
        <div><em>{t('team-calendar.event.tooltip.location', 'Location')}:</em> {eventInfo.location}</div> 
      }
    </Fragment>
  );

};

export default TooltipContent;
