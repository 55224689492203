export default `
Visiosax UG (haftungsbeschränkt)  
Alt-Rottwerndorf 45  
01796 Pirna  
Deutschland  
\` \`  
Tel.: 015204402351  
E-Mail: kanzlei@visiosax.de  
\` \`  
Registergericht: Pirna  
Registernummer: HRB 41311  
\` \`  
Geschäftsführer/innen: Steffen Salzinger, Maik Lehnert  
\` \`  
Plattform der EU-Kommission zur Online-Streitbeilegung: https://ec.europa.eu/odr  
\` \`  
Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
`;