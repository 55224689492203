import moment from "moment";

const EventGroupValidator = ({
    title, setTitleError,
    location, setLocationError,
    start, setStartError,
    end, setEndError,
    endsAt, setEndsAtError,
    employees, setEmployeesError,
    clients, setClientsEmptyError, setClientsError,
}) => {

    const validateField = (isError, setError) => {
        setError(isError);
        const isValid = !isError;
        return isValid;
    }


    const validateTimestamps = () => {
        let valid = true;

        if (moment(start).isSameOrAfter(moment(end))) {
            setStartError(true);
            setEndError(true);
            valid = false;
        }

        if (moment(endsAt).isBefore(moment(start), 'day') || moment(endsAt).isBefore(moment(end), 'day')) {
            setEndsAtError(true);
            valid = false;
        }

        return valid;
    }


    const validateEmployees = () => {
        if (employees.length <= 1) {
            return true;
        }

        const parsedGlobalStart = start ? moment(start) : null;
        const parsedGlobalEnd = end ? moment(end) : null;

        const invalidEmployees = [];
        employees.forEach(employee => {
            const parsedStart = employee.start ? moment(employee.start) : null;
            const parsedEnd = employee.end ? moment(employee.end) : null;

            const invalidEmployee = {
                id: employee.id,
                start: !employee.start
                    || (parsedGlobalStart && parsedStart && parsedStart.isBefore(parsedGlobalStart))
                    || (parsedGlobalEnd && parsedStart && parsedStart.isAfter(parsedGlobalEnd)),
                end: !employee.end
                    || (parsedGlobalStart && parsedEnd && parsedEnd.isBefore(parsedGlobalStart))
                    || (parsedGlobalEnd && parsedEnd && parsedEnd.isAfter(parsedGlobalEnd)),
            };

            if (parsedStart && parsedEnd && parsedStart.isSameOrAfter(parsedEnd)) {
                invalidEmployee.start = true;
                invalidEmployee.end = true;
            }

            if (invalidEmployee.start || invalidEmployee.end) {
                invalidEmployees.push(invalidEmployee);
            }
        })

        setEmployeesError(invalidEmployees);
        return invalidEmployees.length <= 0;
    }


    const validateClients = () => {
        let valid = validateField(clients.length === 0, setClientsEmptyError);

        if (clients.length <= 0) {
            return valid;
        }

        const parsedGlobalStart = start ? moment(start) : null;
        const parsedGlobalEnd = end ? moment(end) : null;

        const invalidClients = [];
        clients.forEach(client => {
            const parsedStart = client.start ? moment(client.start) : null;
            const parsedEnd = client.end ? moment(client.end) : null;

            const invalidClient = {
                id: client.id,
                start: !client.start
                    || (parsedGlobalStart && parsedStart && parsedStart.isBefore(parsedGlobalStart))
                    || (parsedGlobalEnd && parsedStart && parsedStart.isAfter(parsedGlobalEnd)),
                end: !client.end
                    || (parsedGlobalStart && parsedEnd && parsedEnd.isBefore(parsedGlobalStart))
                    || (parsedGlobalEnd && parsedEnd && parsedEnd.isAfter(parsedGlobalEnd)),
                budget: !client.budget?.id,
            };

            if (parsedStart && parsedEnd && parsedStart.isSameOrAfter(parsedEnd)) {
                invalidClient.start = true;
                invalidClient.end = true;
            }

            if (invalidClient.start || invalidClient.end || invalidClient.budget) {
                invalidClients.push(invalidClient);
            }
        })

        setClientsError(invalidClients);
        return invalidClients.length <= 0 && valid;
    }


    const validate = () => {
        let valid = true;

        valid = validateField(title === "", setTitleError) && valid;
        valid = validateField(location === "", setLocationError) && valid;
        valid = validateField(start === '', setStartError) && valid;
        valid = validateField(end === '', setEndError) && valid;
        valid = validateTimestamps() && valid;
        valid = validateEmployees();
        valid = validateClients();

        return valid;
    }


    return {
        validate,
    }


};

export default EventGroupValidator;