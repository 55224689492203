import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import useApi from "../../hooks/useApi";
import useUser from "../../hooks/useUser";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Spinner from "../Spinner";
import { DocumentDuplicateIcon, PencilIcon } from "@heroicons/react/24/outline";
import ConfirmationModal from "../ConfirmationModal";
import { TrashIcon } from "@heroicons/react/20/solid";
import EventDurationSelectionModal from './EventDurationSelectionModal';
import RecurrenceConfirmationModal from './RecurrenceConfirmationModal';
import EventNotesSelectionModal from './EventNotesSelectionModal';
import EventEmployeeSelectionModal from './EventEmployeeSelectionModal';

const EventHolidaySickModal = forwardRef(({
    onClose,                // Call on close
    date,                   // Clicked calendar date, possibly null
    handleEventsUpdated,    // Call on close
    event,                  // Related event, null if no id
    eventTypeId,            // Differ between "SICK" and "HOLIDAY"
    setEventTypeId,         // Set as "CLIENT_APPOINTMENT"
    setDialogTitle,         // Set on button click
    setErrorMessage,        // Handle validation and HTTP 422 error response
    editable, setEditable,  // Check edit mode and switch if edit button clicked
    initTimeInterval,        // To set the parameter of clicked date
}, ref) => {
    const { t } = useTranslation();
    const { getParsedUserId, getEmployeeId, getTeamId } = useUser();

    const {
        queryEmployeeNames,
        queryEmployees,
        saveOrUpdateEvent,
        deleteEvent,
        deleteRecurringEvent,
    } = useApi();

    const currentUserId = getParsedUserId();
    const currentEmployeeId = getEmployeeId();
    const teamId = getTeamId();

    const permissionsMeta = document.querySelector('meta[name="permissions"]');
    const permissions = permissionsMeta ? permissionsMeta.content.split(',') : [];
    const canTeamEvents = permissions.includes('team.events') || permissions.includes('teams');

    const [eventId, setEventId] = useState(null);
    const [employeeId, setEmployeeId] = useState(canTeamEvents ? '-1' : currentEmployeeId);
    const [employeeIdError, setEmployeeIdError] = useState(false);
    const [employeeIds, setEmployeeIds] = useState([]);
    const [listOfEmployeesError, setListOfEmployeesError] = useState(false);
    const [start, setStart] = useState("");
    const [startError, setStartError] = useState(false);
    const [end, setEnd] = useState("");
    const [endError, setEndError] = useState(false);
    const [notes, setNotes] = useState("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showRecurrenceConfirmationModal, setShowRecurrenceConfirmationModal] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState("");
    const [confirmationModalData, setConfirmationModalData] = useState(null);

    const [recurrenceType, setRecurrenceType] = useState('none');
    const [recurrenceFrequency, setRecurrenceFrequency] = useState(1);
    const [weekdaysRecurrence, setWeekdaysRecurrence] = useState([true, true, true, true, true, true, true]);
    const [weekdaysRecurrenceError, setWeekdaysRecurrenceError] = useState(false);
    const [startsAt, setStartsAt] = useState("");
    const [endsNever, setEndsNever] = useState(true);
    const [endsAt, setEndsAt] = useState("");
    const [endsAtError, setEndsAtError] = useState(false);

    const { data: employees = [], isLoading: isLoadingEmployees = true } = queryEmployees();
    const { data: employeeNames = [], isLoading: isLoadingEmployeeNames = true } = queryEmployeeNames();

    const isLoading = isLoadingEmployees || isLoadingEmployeeNames;

    useEffect(() => {
        if (!editable) {
            return;
        }

        const parsedDateInput = date ? moment(date.dateStr) : moment();

        setStart(parsedDateInput.format('YYYY-MM-DD'));
        setEnd(parsedDateInput.format('YYYY-MM-DD'));
    }, [date]);

    const initEvent = (event) => {
        if (event) {
            setEmployeeId(event.employee_ids.length > 0 ? event.employee_ids[0] : '-1');
            setEmployeeIds(event.employee_ids);
            setEventTypeId(event.event_type_id);
            initTimeInterval(setStart, setEnd);
            setNotes(event.note);

            if (event.recurrence) {
                const recurrence = event.recurrence;
                setRecurrenceType(recurrence.type ?? 'none');
                setRecurrenceFrequency(recurrence.frequency ?? 1);
                setWeekdaysRecurrence(recurrence.weekdays ?? [true, true, true, true, true, true, true]);
                setStartsAt(moment(event.start).format('YYYY-MM-DDTHH:mm'));
                setEndsNever(!recurrence.endsAt);
                setEndsAt(recurrence.endsAt ? moment(recurrence.endsAt).format('YYYY-MM-DD') : "");
            }
        }
    }

    useEffect(() => {
        initEvent(event);
    }, [event]);

    const validateField = (isError, setError) => {
        setError(isError);
        const isValid = !isError;
        return isValid;
    }

    const validateTimestamps = () => {
        let valid = true;

        if (moment(start).isAfter(moment(end))) {
            setStartError(true);
            setEndError(true);
            valid = false;
        }

        if (moment(endsAt).isBefore(moment(start), 'day') || moment(endsAt).isBefore(moment(end), 'day')) {
            setEndsAtError(true);
            valid = false;
        }

        return valid;
    }


    const validate = () => {
        let valid = true;
        let errorMessages = [];

        valid = validateField(employeeId === '-1', setEmployeeIdError) && valid;
        valid = validateField(start === '', setStartError) && valid;
        valid = validateField(end === '', setEndError) && valid;
        valid = validateTimestamps() && valid;

        setErrorMessage(errorMessages)
        return valid;
    }


    const collectRequestData = () => {
        let data = {};

        data.id = eventId ?? null;
        data.event_type_id = eventTypeId;

        data.note = notes;
        data.team_id = teamId;

        data.start = start;
        data.end = end;

        const employee = employees.data.find(emp => emp.id === parseInt(employeeId));
        if (employee) {
            data.title = employee.person?.last_name + ' ' + employee.person?.first_name;
            if (eventTypeId === 'SICK') {
                data.title = data.title + ' ' + 'Krank';
            } else if (eventTypeId === 'HOLIDAY') {
                data.title = data.title + ' ' + 'Urlaub';
            }
        }
        data.all_day = 1;
        data.employee_ids = employeeId && parseInt(employeeId) !== -1 ? [employeeId] : [];
        data.status = 'NULL';
        data.user_id = currentUserId;
        return data;
    }


    const handleError422 = (errors) => {
        if (errors.user_id) {
            if (errors.user_id.includes("validation.events.user_id_unique")) {
                setStartError(true);
                setEndError(true);
                setErrorMessage(t('validation.events.user_id_unique'));
            } else {
                setEmployeeIdError(true);
            }
        }
        if (errors.start) {
            setStartError(true);
        }
        if (errors.end) {
            setEndError(true);
        }
    }


    const handleSaveOrUpdateEvent = (data) => {
        saveOrUpdateEvent(data).then((response) => {
            handleEventsUpdated();
            onClose(response.data, eventId === null ? 'created' : 'updated');
        }).catch((error) => {
            if (error.response?.status === 422) {
                let errors = error.response.data.errors;
                handleError422(errors);
            } else {
                console.log(error);
            }
        });
    }

    useImperativeHandle(ref, () => ({
        handleCancel() {
            if (!event) {
                onClose(null);
                return;
            }

            setEditable(false);
            setDialogTitle(t('team-calendar.event-modal.title-edit', 'Event details'));
            initEvent(event);
        },
        handleSave() {
            const isValid = validate();

            if (!isValid) {
                return;
            }

            const data = collectRequestData();
            if (recurrenceType != 'none') {
                setConfirmationModalMessage(t(
                    'team-calendar.confirmation-modal.choose-recurrence-change',
                    'On which elements of recurrence do you want to apply the changes?'
                ))
                setConfirmationModalData({ action: 'update', data: data });
                setShowRecurrenceConfirmationModal(true);
                return;
            }
            handleSaveOrUpdateEvent(data);
        }
    }));

    const handleEditEvent = () => {
        setDialogTitle(t('team-calendar.event-modal.title-edit-event', 'Edit Event'));
        setEventId(event ? parseInt(event.id) : null);
        setEditable(true);
    }

    const handleDuplicateEvent = () => {
        setDialogTitle(t('team-calendar.event-modal.title-duplicate-event', 'Duplicate Event'));
        setStart('');
        setEnd('');
        setEventId(null);
        setRecurrenceType('none');
        setEditable(true);
    }


    const handleEventCancelled = () => {
        if (event?.recurrence) {
            setConfirmationModalMessage(t('team-calendar.confirmation-modal.choose-recurrence-deletion', 'Which elements of recurrence do you want to delete?'))
            setConfirmationModalData({ action: 'delete', id: event.id, start: start, end: end });
            setShowRecurrenceConfirmationModal(true);
            return;
        }

        setConfirmationModalMessage(t('team-calendar.confirmation-modal.message-cancelled', 'Are you sure you want to delete this appointment?'))
        setConfirmationModalData({ action: 'delete', id: event.id });
        setShowConfirmationModal(true);
    }


    const onCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
        setShowRecurrenceConfirmationModal(false);

        const isDeletion = confirmationModalData?.action === 'delete';
        setConfirmationModalData(null);
        setConfirmationModalMessage(null);

        handleEventsUpdated(isDeletion);
    }


    const onConfirm = (data, option = null) => {
        try {
            switch (data.action) {
                case 'update':
                    let updateData = data.data;
                    updateData.recurrenceUpdateOption = option;
                    handleSaveOrUpdateEvent(updateData);
                    return;
                case 'delete':
                    if (option) {
                        deleteRecurringEvent(data.id, option, data.start, data.end,).then((_) => {
                            onClose(null, 'deleted', true);
                        }).catch((_) => { });
                        return;
                    } else {
                        deleteEvent(data.id).then((response) => {
                            onClose({ id: data.id }, 'deleted', true);
                            setShowConfirmationModal(false);
                        }).catch((_) => { });
                    }
            }
        } finally {
            setShowConfirmationModal(false);
            setShowRecurrenceConfirmationModal(false);

            setConfirmationModalData(null);
            setConfirmationModalMessage(null);
        }
    }


    return (
        <>
            {isLoading
                ?
                <Spinner />
                :
                <div className="mt-2">
                    <div className="space-y-4">
                        {!editable &&
                            <div className="flex items-center justify-end space-x-2">
                                {/*Termin editieren*/}
                                <button onClick={handleEditEvent}
                                    className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                    title={t('team-calendar.event-modal.edit', 'Edit event')}>
                                    <PencilIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                </button>
                                {/*Termin duplizieren*/}
                                <button onClick={handleDuplicateEvent}
                                    className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                    title={t('team-calendar.event-modal.duplicate', 'Duplicate event')}>
                                    <DocumentDuplicateIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                </button>
                                {/*Termin löschen*/}
                                <button onClick={handleEventCancelled}
                                    className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                    title={t('team-calendar.event-modal.switch-status-cancelled', 'Set status to cancelled')}>
                                    <TrashIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                </button>
                            </div>
                        }

                        {(canTeamEvents || (!editable && event && !event.employee_ids.includes(currentEmployeeId)))
                            &&
                            <EventEmployeeSelectionModal
                                isMultiSelection={false}
                                isClientEvent={false}
                                editable={editable}
                                event={event}
                                employeeIds={employeeIds}
                                setEmployeeIds={setEmployeeIds}
                                employeeId={employeeId}
                                setEmployeeId={setEmployeeId}
                                employees={employees}
                                employeeNames={employeeNames}
                                listOfEmployeesError={listOfEmployeesError}
                                employeeIdError={employeeIdError}
                                isContactSelection={false}
                            />
                        }

                        <EventDurationSelectionModal
                            isRecurrence={event !== null && event.recurrence !== null}
                            editable={editable} eventTypeId={eventTypeId}
                            start={start} setStart={setStart} startError={startError}
                            end={end} setEnd={setEnd} endError={endError}
                            recurrenceType={recurrenceType} setRecurrenceType={setRecurrenceType}
                            recurrenceFrequency={recurrenceFrequency} setRecurrenceFrequency={setRecurrenceFrequency}
                            weekdaysRecurrence={weekdaysRecurrence} setWeekdaysRecurrence={setWeekdaysRecurrence} weekdaysRecurrenceError={weekdaysRecurrenceError}
                            endsNever={endsNever} setEndsNever={setEndsNever}
                            endsAt={endsAt} setEndsAt={setEndsAt} endsAtError={endsAtError}
                            startsAt={startsAt}
                        />

                        <EventNotesSelectionModal
                            editable={editable}
                            notes={notes}
                            setNotes={setNotes}
                        />
                    </div>

                    <ConfirmationModal
                        isOpen={showConfirmationModal}
                        onClose={onCloseConfirmationModal}
                        onConfirm={onConfirm}
                        data={confirmationModalData}
                        message={confirmationModalMessage}
                    />
                    <RecurrenceConfirmationModal
                        isOpen={showRecurrenceConfirmationModal}
                        onClose={onCloseConfirmationModal}
                        onConfirm={onConfirm}
                        data={confirmationModalData}
                        message={confirmationModalMessage}
                    />
                </div>
            }
        </>
    );
});
export default EventHolidaySickModal;
