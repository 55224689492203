import { Box, CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material";
import useApi from "../../hooks/useApi";
import moment from "moment";
import { Trans } from "react-i18next";
import useUser from "../../hooks/useUser";
import 'moment/locale/de'; // Import the German locale
import { useEffect, useState } from 'react';
import PersonObjectHelper from "../events/PersonObjectHelper";

// Set the locale to German
moment.locale('de'); // You can set this globally in your application, if needed

const HoursWorked = () => {

    const { extractNameOrEmpty, sortByName } = PersonObjectHelper();

    const permissionsMeta = document.querySelector('meta[name="permissions"]');
    const permissions = permissionsMeta ? permissionsMeta.content.split(',') : [];
    const canTeamEvents = permissions.includes('team.events') || permissions.includes('teams');

    const { getEmployeeId } = useUser();
    const currentEmployeeId = getEmployeeId();
    const { queryDashboardHoursWorked, queryEmployees } = useApi();


    const [start, setStart] = useState("");
    const [startError, setStartError] = useState(false);
    const [employeeIdError, setEmployeeIdError] = useState(false);
    const [end, setEnd] = useState("");
    const [endError, setEndError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [employeeId, setEmployeeId] = useState(canTeamEvents ? '-1' : currentEmployeeId);

    const [showResponse, setShowResponse] = useState(false);
    const [response, setResponse] = useState("");

    const { data: hours = null, isLoading: isLoadingHoursWorked, refetch: refetchHoursWorked } = queryDashboardHoursWorked(start, end, employeeId);
    const { data: employees = [], isLoading: isLoadingEmployees } = queryEmployees();

    const onChangeStart = (e) => {
        const inputStart = moment(e.target.value);
        setStart(inputStart.format('YYYY-MM-DD'));

        if (end === "" || inputStart.isAfter(end)) {
            setEnd(inputStart.format('YYYY-MM-DD'));
        }
    }

    const onChangeEnd = (e) => {
        const inputEnd = moment(e.target.value);
        setEnd(inputEnd.format('YYYY-MM-DD'));

        if (start === "" || inputEnd.isBefore(start)) {
            setStart(inputEnd.format('YYYY-MM-DD'));
        }
    }

    const validate = () => {
        let valid = true;
        setErrorMessage("");
        if (start === '') {
            setStartError(true);
            valid = false;
        } else {
            setStartError(false);
        }
        if (end === '') {
            setEndError(true);
            valid = false;
        } else {
            setEndError(false);
        }
        if (parseInt(employeeId) === -1) {
            setEmployeeIdError(true);
            valid = false;
        } else {
            setEmployeeIdError(false);
        }
        return valid;
    }

    const handleExecute = () => {
        if (validate()) {
            refetchHoursWorked();
        }
    };

    useEffect(() => {
        if (hours) {
            setShowResponse(true);
            setResponse(hours.hours_worked);
        }
    }, [hours])

    return <Paper elevation={0} sx={{ height: '400px' }}>
        <Box sx={{ padding: 2 }}>
            <Typography variant="h6"><Trans i18nKey="dashboard.hours-worked.title">Client Hours Worked</Trans></Typography>
        </Box>
        <Divider />
        {isLoadingHoursWorked && isLoadingEmployees ?
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '300px',
            }}>
                <CircularProgress />
            </Box>
            :
            <Box sx={{ padding: 2, overflow: 'auto', height: '340px' }}>
                {
                    <div className="grid md:grid-cols-2 gap-4 w-full">
                        {/* First column */}
                        <div className="flex flex-col gap-4 h-full">
                            <div
                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                <label htmlFor="start-time"
                                    className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                    <Trans
                                        i18nKey="dashboard.hours-worked.start">Start</Trans>
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        type="date"
                                        id="start-time"
                                        name="start-time"
                                        value={start}
                                        onChange={onChangeStart}
                                        className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${startError ? "border-red-500" : "border-gray-300"}`}
                                    />
                                </div>
                            </div>
                            <div
                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                <label htmlFor="end-time"
                                    className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                    <Trans
                                        i18nKey="dashboard.hours-worked.end">End</Trans>
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        type="date"
                                        id="end-time"
                                        name="end-time"
                                        value={end}
                                        onChange={onChangeEnd}
                                        className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${endError ? "border-red-500" : "border-gray-300"}`}
                                    />
                                </div>
                            </div>
                            {canTeamEvents &&
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                    <label htmlFor="end-time"
                                        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                        <Trans
                                            i18nKey="dashboard.hours-worked.employee">Employee</Trans>
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <select
                                            id="employee"
                                            name="employee"
                                            value={employeeId}
                                            onChange={(e) => setEmployeeId(e.target.value)}
                                            className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${employeeIdError ? "border-red-500" : "border-gray-300"}`}
                                        >
                                            <option value={-1}>
                                                <Trans i18nKey="dashboard.hours-worked.no-selected-employee">---</Trans>
                                            </option>
                                            {employees && sortByName(employees.data)?.map(employee => (
                                                <option key={employee.id} value={employee.id}>
                                                    {extractNameOrEmpty(employee)}
                                                </option>
                                            ))}
                                        </select>
                                        {employeeIdError &&
                                            <p className="mt-2 text-sm text-red-600">
                                                <Trans i18nKey="dashboard.hours-worked.select-employee">
                                                    Please select an employee.
                                                </Trans>
                                            </p>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="mt-1 sm:mt-0">
                                <button
                                    type="submit"
                                    className="float-right items-center border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-3 focus:ring-blue-400"
                                    onClick={handleExecute}
                                >
                                    <Trans i18nKey="dashboard.hours-worked.execute">Check Hours</Trans>
                                </button>
                            </div>
                        </div>
                        {/* Second column */}
                        <div className="flex flex-col gap-4 h-full">
                            <div className="flex text-sm font-medium leading-5 text-gray-700">
                                <Trans i18nKey="dashboard.hours-worked.response-message">Client Hours Worked</Trans>:
                            </div>
                            {showResponse &&
                            <div className="flex text-sm font-medium leading-5 text-gray-700">
                                {response} <Trans i18nKey="dashboard.hours-worked.hours">hours</Trans>
                            </div>
                            }
                        </div>
                    </div>
                }
            </Box>
        }
    </Paper >


}

export default HoursWorked;
