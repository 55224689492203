import { Fragment, useState, useRef, useEffect } from 'react';
import useApi from "../hooks/useApi";
import { useTranslation } from "react-i18next";
import EventClientAppointmentModal from './events/EventClientAppointmentModal';
import EventInternalModal from './events/EventInternalModal';
import EventHolidaySickModal from './events/EventHolidaySickModal';
import { Dialog, Transition } from '@headlessui/react';
import Spinner from "./Spinner";
import EventPublicModal from './events/EventPublicModal';
import moment from "moment";
import EventGroupModal from './events/group/EventGroupModal';
import useUser from '../hooks/useUser';
import { useCalendarViewStore } from '../store/CalendarViewStore';
import LastUpdatedModal from './events/LastUpdatedModal';

const EventModal = ({
    handleEventsChanged,
}) => {
    const { t } = useTranslation();
    const { getEmployeeId } = useUser();
    const {
        queryEvent,
        saveOrUpdateEvent,
        updateEventStatus,
        deleteEvent,
        deleteRecurringEvent,
    } = useApi();

    const currentEmployeeId = getEmployeeId();

    const isOpen = useCalendarViewStore(state => state.isEventModalOpen);
    const date = useCalendarViewStore(state => state.selectedDate);
    const id = useCalendarViewStore(state => state.selectedId);
    const type = useCalendarViewStore(state => state.selectedType);

    const setSelectedDate = useCalendarViewStore(state => state.setSelectedDate);
    const setSelectedId = useCalendarViewStore(state => state.setSelectedId);
    const setSelectedType = useCalendarViewStore(state => state.setSelectedType);
    const toggleEventModal = useCalendarViewStore(state => state.toggleEventModal);

    const { data: event = null, remove: eventRemove } = queryEvent(id, true, true);

    const refDiv = useRef(null); // To prevent initial focus trap
    const [editable, setEditable] = useState(true);
    const [duplicate, setDuplicate] = useState(false);

    useEffect(() => {
        setEditable(!id);
    }, [event])

    const onClose = (
        events = [],
        action = null,
    ) => {
        handleEventsChanged(events, action);

        setSelectedDate(null)
        setSelectedId(null);
        setSelectedType(null);
        toggleEventModal(false);
    }

    const getStartForDate = (considerTime = true) => {
        const parsedDateInput = date ? moment(date) : moment();
        const hasTimestamp = date && !date.allDay;
        const startDate = hasTimestamp
            ? parsedDateInput
            : parsedDateInput.hour(8).minute(0);
        const format = considerTime ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD';
        return startDate.format(format);
    }

    const getEndForDate = (considerTime = true, durationInMilliseconds = null) => {
        const parsedDateInput = date ? moment(date) : moment();
        const duration = (!date || date?.allDay) ? null : (durationInMilliseconds ?? 3600000);
        const endDate = duration
            ? parsedDateInput.add(duration, 'ms')
            : parsedDateInput.hour(8).minute(30);
        const format = considerTime ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD';
        return endDate.format(format);
    }

    const handleAction = (
        data,
        reset = () => { console.log('No reset defined.'); },
        option = null,
        handleError422 = () => { console.log('No error handling.'); }
    ) => {
        try {
            switch (data.action) {
                case 'cancel':
                    return handleCancel(reset);
                case 'save':
                    return handleSave(data.data, handleError422);
                case 'update':
                    return handleUpdate(data, option, handleError422);
                case 'status':
                    return handleStatusChange(data);
                case 'assign':
                    return handleAssign(data);
                case 'unassign':
                    return handleUnassign(data);
                case 'delete':
                    return handleDelete(data, option);
            }
        } finally {
            if (data?.action !== 'cancel') {
                reset();
                eventRemove();
            }
        }
    }

    const handleCancel = (reset) => {
        if (!event) {
            onClose();
            return;
        }

        setDuplicate(false);
        setEditable(false);
        reset();
    }

    const handleSave = (data, handleError422) => {
        saveOrUpdateEvent(data)
            .then((response) => {
                onClose(response.data, 'created');
            })
            .catch((error) => {
                if (error.response?.status === 422) {
                    let errors = error.response.data.errors;
                    handleError422(errors);
                } else {
                    console.log(error);
                }
            });

        setDuplicate(false);
    }

    const handleUpdate = (data, option, handleError422) => {
        let updateData = data.data;
        updateData.recurrenceUpdateOption = option;

        saveOrUpdateEvent(updateData)
            .then((response) => {
                onClose(response.data, 'updated');
            })
            .catch((error) => {
                if (error.response?.status === 422) {
                    let errors = error.response.data.errors;
                    handleError422(errors);
                } else {
                    console.log(error);
                }
            });

        setDuplicate(false);
    }

    const handleStatusChange = (data) => {
        updateEventStatus(data.id, data.status, data.employees, data.start, data.end)
            .then((response) => {
                onClose(response.data, 'updated');
            })
            .catch((_) => { });
    }

    const handleAssign = (data) => {
        const employeesAssign = [...data.employees, { id: currentEmployeeId, start: null, end: null }];
        updateEventStatus(data.id, data.status, employeesAssign, data.start, data.end)
            .then((response) => {
                onClose(response.data, 'updated');
            })
            .catch((_) => { });
    }

    const handleUnassign = (data) => {
        const employeesUnassign = data.employees
            .filter(employee => parseInt(employee.id) !== parseInt(currentEmployeeId));
        updateEventStatus(data.id, data.status, employeesUnassign, data.start, data.end)
            .then((response) => {
                onClose(response.data, 'updated');
            })
            .catch((_) => { });
    }

    const handleDelete = (data, option) => {
        if (option) {
            deleteRecurringEvent(data.id, option, data.start, data.end,)
                .then((response) => {
                    switch (option) {
                        case 'single':
                        case 'future':
                            onClose(response.data, 'updated');
                        case 'all':
                            onClose({ id: data.id }, 'deleted');
                    }
                })
                .catch((_) => { });
            return;
        }

        deleteEvent(data.id)
            .then((_) => {
                onClose([{ id: data.id }], 'deleted');
            })
            .catch((_) => { });
    }


    const eventTypeId = type?.toUpperCase() ?? "NONE";
    const dialogTitle = duplicate
        ? t('team-calendar.event-modal.title-duplicate-event', 'Duplicate Event')
        : editable
            ? t('team-calendar.event-modal.title-edit-event', 'Edit Event')
            : id
                ? t('team-calendar.event-modal.title-edit', 'Event details')
                : t('team-calendar.event-modal.title-new-event-type', {
                    eventType: t('team-calendar.event-types.' + type, type)
                });


    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    initialFocus={refDiv}
                    as="div"
                    className="fixed inset-0 z-20 overflow-y-auto"
                    onClose={() => {
                        eventRemove();
                        onClose();
                    }}
                >
                    <div ref={refDiv} className="min-h-screen px-4 text-center">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                        {(id && !event)
                            ?
                            <Spinner />
                            :
                            <div>
                                <div
                                    className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title as="h3"
                                        className="flex items-start justify-between pb-4 border-b text-gray-900 text-xl lg:text-2xl font-semibold">
                                        {dialogTitle}
                                        <button
                                            onClick={() => {
                                                eventRemove();
                                                onClose();
                                            }}
                                            type="button"
                                            className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                            aria-label="Close">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="h-6 w-6">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </Dialog.Title>


                                    {eventTypeId === "CLIENT_APPOINTMENT" &&
                                        <EventClientAppointmentModal
                                            event={event}
                                            editable={editable}
                                            setEditable={setEditable}
                                            getStartForDate={getStartForDate}
                                            getEndForDate={getEndForDate}
                                            duplicate={duplicate}
                                            setDuplicate={setDuplicate}
                                            handleAction={handleAction}
                                        />
                                    }

                                    {eventTypeId === "GROUP" &&
                                        <EventGroupModal
                                            event={event}
                                            editable={editable}
                                            setEditable={setEditable}
                                            getStartForDate={getStartForDate}
                                            getEndForDate={getEndForDate}
                                            duplicate={duplicate}
                                            setDuplicate={setDuplicate}
                                            handleAction={handleAction}
                                        />
                                    }

                                    {eventTypeId === "INTERNAL" &&
                                        <EventInternalModal
                                            event={event}
                                            editable={editable}
                                            setEditable={setEditable}
                                            getStartForDate={getStartForDate}
                                            getEndForDate={getEndForDate}
                                            duplicate={duplicate}
                                            setDuplicate={setDuplicate}
                                            handleAction={handleAction}
                                        />
                                    }
                                    {(eventTypeId === "HOLIDAY" || eventTypeId === "SICK") &&
                                        <EventHolidaySickModal
                                            event={event}
                                            eventTypeId={eventTypeId}
                                            editable={editable}
                                            setEditable={setEditable}
                                            getStartForDate={getStartForDate}
                                            getEndForDate={getEndForDate}
                                            duplicate={duplicate}
                                            setDuplicate={setDuplicate}
                                            handleAction={handleAction}
                                        />
                                    }
                                    {eventTypeId === "PUBLIC" &&
                                        <EventPublicModal
                                            event={event}
                                            editable={editable}
                                            setEditable={setEditable}
                                            getStartForDate={getStartForDate}
                                            getEndForDate={getEndForDate}
                                            duplicate={duplicate}
                                            setDuplicate={setDuplicate}
                                            handleAction={handleAction}
                                        />
                                    }
                                    {!editable &&
                                        <LastUpdatedModal 
                                            event={event} 
                                        />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
export default EventModal;
