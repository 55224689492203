// TooltipContent.js
import React, { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import Spinner from '../Spinner';
import useApi from '../../hooks/useApi';


const getDateTime = (date) => {
    const hours = String(date.getHours()).padStart(2, '0');   // "05"
    const minutes = String(date.getMinutes()).padStart(2, '0'); // "15";
    return `${hours}:${minutes}`
}

const getDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // 11 (Months are 0-indexed)
    const day = date.getDate(); // 11
    return `${day}.${month}.${year}`;
}

const getFormattedDate = (event) => {
    const start = event.start ? new Date(event.start) : null;
    const end = event.end ? new Date(event.end) : null;
    if (start && end) {
        return getDate(start) + ", " + getDateTime(start) + " - " + getDateTime(end);
    }
    return null;

}

const getPeople = (people) => {
    if (!people) {
        return [];
    }

    const names = people.map(person => {
        return (person.person?.last_name ?? '') + ' ' + (person.person?.first_name ?? '');
    });

    return names;
}

const getBudget = (event) => {
    if (event.clients?.length !== 1) {
        return null;
    }

    const budget = event.clients[0].budget;

    if (!budget) {
        return null;
    }

    return budget.name;
}

const getService = (event) => {
    const service = event.service;

    if (!service) {
        return null;
    }

    return service.name;
}

const getLocation = (event) => {
    return event.location == '' ? null : event.location;
}

const TooltipContent = ({ eventId }) => {
    const { t } = useTranslation();
    const { queryEvent } = useApi();

    const hoverTimeout = useRef(null);
    
    const { data: event = null, refetch: refetchEvent } = queryEvent(eventId, false);

    useEffect(() => {
        hoverTimeout.current = setTimeout(() => {
            refetchEvent();
        }, 500);
        return () => {
            clearTimeout(hoverTimeout.current);
        };
    }, []);

    return (
        <Fragment>
            {!event
                ?
                <Spinner useScreenHeight={false} />
                :
                <div>
                    {getFormattedDate(event) &&
                        <div><em>{t('team-calendar.event.tooltip.date', 'Date')}:</em> {getFormattedDate(event)}</div>
                    }
                    {event.clients?.length > 0 &&
                        <div><em>{t('team-calendar.event.tooltip.client', 'Client')}:</em>
                            {' '}
                            {getPeople(event.clients).map((client, index) => (
                                <React.Fragment key={index}>
                                    {index === 0 ? (
                                        <span>{client}</span> // First client stays on the same line as the label
                                    ) : (
                                        <div>{client}</div> // Additional clients start on new lines
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    }
                    {event.employees?.length > 0 &&
                        <div><em>{t('team-calendar.event.tooltip.employee', 'Employee')}:</em>
                            {' '}
                            {getPeople(event.employees).map((employee, index) => (
                                <React.Fragment key={index}>
                                    {index === 0 ? (
                                        <span>{employee}</span> // First employee stays on the same line as the label
                                    ) : (
                                        <div>{employee}</div> // Additional employees start on new lines
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    }
                    {getService(event) &&
                        <div><em>{t('team-calendar.event.tooltip.service', 'Service')}:</em> {getService(event)}</div>
                    }
                    {getBudget(event) &&
                        <div><em>{t('team-calendar.event.tooltip.budget', 'Budget')}:</em> {getBudget(event)}</div>
                    }
                    {getLocation(event) &&
                        <div><em>{t('team-calendar.event.tooltip.location', 'Location')}:</em> {event.location}</div>
                    }
                </div>
            }
        </Fragment>
    );

};

export default TooltipContent;
