import { Trans, useTranslation } from "react-i18next";
import { useEffect } from 'react';
import { FormControl, InputAdornment, MenuItem, OutlinedInput, Select } from "@mui/material";


const EventPhoneNumberModal = ({
    editable,
    phone,
    setPhone,
    phoneCountryCode,
    setPhoneCountryCode,
    employee,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (editable) {
            const phoneNumber = employee?.phone?.phone_number ?? '';
            setPhone(phoneNumber);
        }
    }, [employee]);

    const normalizePhoneNumber = (phoneNumber, countryCode) => {
        const phoneNumberWithLeadingZero = phoneNumber ?? '';
        const countryCodeNumber = countryCode === 'DE' ? '49' : '';

        if (!phoneNumberWithLeadingZero || !countryCodeNumber) {
            return '';
        }

        const formattedPhoneNumber = phoneNumberWithLeadingZero.startsWith('0')
            ? phoneNumberWithLeadingZero.slice(1)
            : phoneNumberWithLeadingZero;

        return `${countryCodeNumber}${formattedPhoneNumber}`;
    }

    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200">

            <label
                htmlFor="phone"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
                <Trans i18nKey="team-calendar.event-modal.phone-home">Phone</Trans>
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2">
                {editable
                    ?
                    <OutlinedInput
                        id="phone"
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={phone}
                        placeholder={t('team-calendar.event-modal.phone-placeholder')}
                        sx={{ fontSize: 14, backgroundColor: '#fff', paddingLeft: 0 }}
                        onChange={(event) => setPhone(event.target.value)}
                        startAdornment={
                            <InputAdornment
                                position="start">
                                <FormControl
                                    size='small'>
                                    <Select sx={{
                                        fontSize: 14, "& fieldset": {
                                            border: "none",
                                        }
                                    }} value={phoneCountryCode}
                                        onChange={(event) => setPhoneCountryCode(event.target.value)}
                                    >
                                        <MenuItem value={'DE'}>DE</MenuItem>
                                        <MenuItem value={'AT'}>AT</MenuItem>
                                        <MenuItem value={'CH'}>CH</MenuItem>
                                    </Select>
                                </FormControl>
                            </InputAdornment>
                        }
                    />
                    :
                    <a
                        href={`tel:+${normalizePhoneNumber(phone)}`}
                        style={{ color: 'blue', textDecoration: 'none' }}
                    >
                        <div className="text-sm sm:mt-px sm:pt-2">
                            {phone ?? '-'}
                        </div>
                    </a>
                }
            </div>

        </div>
    );
};

export default EventPhoneNumberModal;