import { Fragment, useEffect, useState, useRef } from 'react';
import useApi from "../hooks/useApi";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import EventClientAppointmentModal from './events/EventClientAppointmentModal';
import EventInternalModal from './events/EventInternalModal';
import EventHolidaySickModal from './events/EventHolidaySickModal';
import { Dialog, Transition } from '@headlessui/react';
import Spinner from "./Spinner";
import EventPublicModal from './events/EventPublicModal';
import moment from "moment";
import { useLocation } from "react-router-dom";

const EventModal = ({
    isOpen,
    onClose,
    date,
    handleEventsUpdated,
}) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { type } = useParams();
    const { queryEvent } = useApi();
    const { state } = useLocation();

    const { data: event = null, isLoading: isLoadingEvent, refetch: eventRefetch } = queryEvent(id);
    const [eventTypeId, setEventTypeId] = useState(type ? type.toUpperCase() : "NONE");
    const [dialogTitle, setDialogTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState([]);
    const [editable, setEditable] = useState(!id);

    const childRef = useRef();
    const clickedStart = state?.clickedStart ?? null;

    const initEvent = (event) => {
        if (event) {
            setEventTypeId(event.event_type_id);
        }
    }

    useEffect(() => {
        initEvent(event);
    }, [event]);

    useEffect(() => {
        if (event) {
            setDialogTitle(t('team-calendar.event-modal.title-edit', 'Edit Event'));
            return;
        }

        if (type) {
            setDialogTitle(t('team-calendar.event-modal.title-new-event-type', {
                eventType: t('team-calendar.event-types.' + type, type)
            }));
            return;
        }

        setDialogTitle(t('team-calendar.event-modal.title-new-event', 'Create Event'));
    }, [event, type]);

    const handleCancel = () => {
        childRef.current.handleCancel();
    }

    const handleSave = () => {
        childRef.current.handleSave();
    }

    const setTimeInterval = (setStart, setEnd) => {
        const format = 'YYYY-MM-DDTHH:mm';

        if (!clickedStart) {
            setStart(moment(event.start).format(format));
            setEnd(moment(event.end).format(format));
            return;
        }

        let start = moment(event.start);
        let end = moment(event.end);
        const durationInMilliseconds = end.diff(start, 'ms');

        start = moment(clickedStart);
        end = moment(clickedStart).add(durationInMilliseconds, 'ms');

        setStart(start.format(format))
        setEnd(end.format(format))
    }

    const handleEventUpdated = (isDeletion) => {
        handleEventsUpdated();

        if (!isDeletion) {
            eventRefetch();
        }
    }

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-20 overflow-y-auto"
                    onClose={() => onClose(null)}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                        {isLoadingEvent
                            ?
                            <Spinner />
                            :
                            <div>
                                <div
                                    className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title as="h3"
                                        className="flex items-start justify-between pb-4 border-b text-gray-900 text-xl lg:text-2xl font-semibold">
                                        {dialogTitle}
                                        <button
                                            onClick={() => onClose(null)}
                                            type="button"
                                            className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                            aria-label="Close">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="h-6 w-6">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </Dialog.Title>


                                    {eventTypeId === "CLIENT_APPOINTMENT" &&
                                        <EventClientAppointmentModal
                                            ref={childRef}
                                            onClose={onClose}
                                            date={date}
                                            handleEventsUpdated={handleEventUpdated}
                                            event={event}
                                            setEventTypeId={setEventTypeId}
                                            setDialogTitle={setDialogTitle}
                                            setErrorMessage={setErrorMessage}
                                            editable={editable}
                                            setEditable={setEditable}
                                            initTimeInterval={setTimeInterval}
                                        />
                                    }
                                    {eventTypeId === "INTERNAL" &&
                                        <EventInternalModal
                                            ref={childRef}
                                            onClose={onClose}
                                            date={date}
                                            handleEventsUpdated={handleEventUpdated}
                                            event={event}
                                            setEventTypeId={setEventTypeId}
                                            setDialogTitle={setDialogTitle}
                                            setErrorMessage={setErrorMessage}
                                            editable={editable}
                                            setEditable={setEditable}
                                            initTimeInterval={setTimeInterval}
                                        />
                                    }
                                    {(eventTypeId === "HOLIDAY" || eventTypeId === "SICK") &&
                                        <EventHolidaySickModal
                                            ref={childRef}
                                            onClose={onClose}
                                            date={date}
                                            handleEventsUpdated={handleEventUpdated}
                                            event={event}
                                            eventTypeId={eventTypeId}
                                            setEventTypeId={setEventTypeId}
                                            setDialogTitle={setDialogTitle}
                                            setErrorMessage={setErrorMessage}
                                            editable={editable}
                                            setEditable={setEditable}
                                            initTimeInterval={setTimeInterval}
                                        />
                                    }
                                    {eventTypeId === "PUBLIC" &&
                                        <EventPublicModal
                                            ref={childRef}
                                            onClose={onClose}
                                            date={date}
                                            handleEventsUpdated={handleEventUpdated}
                                            event={event}
                                            setEventTypeId={setEventTypeId}
                                            setDialogTitle={setDialogTitle}
                                            setErrorMessage={setErrorMessage}
                                            editable={editable}
                                            setEditable={setEditable}
                                            initTimeInterval={setTimeInterval}
                                        />
                                    }

                                    {errorMessage.length !== 0 && (
                                        <Transition
                                            show={true}
                                            enter="transition-opacity duration-800"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="transition-opacity duration-800"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div
                                                className="flex justify-between text-sm mt-4 mb-4 bg-red-100 border border-red-200 text-gray-700 px-4 py-3 relative">
                                                <ul>
                                                    {
                                                        errorMessage.map(err => <li key={err}>{err}</li>)
                                                    }
                                                </ul>
                                            </div>
                                        </Transition>
                                    )}
                                    {errorMessage.length === 0 && (
                                        <Transition
                                            show={true}
                                            enter="transition-opacity duration-800"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="transition-opacity duration-800"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div className="h-0"></div>
                                        </Transition>
                                    )}
                                    <div className="mt-4 flex justify-between">
                                        {editable ?
                                            <>
                                                <button
                                                    type="button"
                                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    onClick={handleCancel}
                                                >
                                                    <Trans i18nKey="team-calendar.event-modal.cancel">Cancel</Trans>
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="inline-flex items-center border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-3 focus:ring-blue-400"
                                                    onClick={handleSave}
                                                >
                                                    <Trans i18nKey="team-calendar.event-modal.save">Save</Trans>
                                                </button>
                                            </>
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
export default EventModal;
