import { Trans, useTranslation } from "react-i18next";

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const TeamCalendarActiveFilterBox = ({
    filter,
    setFilter,
}) => {

    const { t } = useTranslation();
    const i18nPrefix = 'team-calendar.filter.';

    const handleOnDelete = (key) => {
        const newFilter = new Map(filter);
        newFilter.delete(key);
        setFilter(newFilter);
    }

    return (
        <ListItem
            className='bg-white top-12 z-10'
            sx={{
                position: 'sticky',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
            <ListItemText sx={{ display: 'flex', textAlign: 'center' }}>
                <Trans i18nKey="team-calendar.filter.active-filters">Active Filters</Trans>
                : {filter?.size ?? 0}
            </ListItemText>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 0.5, alignItems: 'center' }}>
                {filter && filter.size > 0 &&
                    Array.from(filter, ([key, _]) => (
                        <Chip
                            key={key}
                            label={t(i18nPrefix + key, key)}
                            onDelete={() => handleOnDelete(key)}
                            onMouseDown={(event) => event.stopPropagation()}
                        />
                    ))}
            </Box>
        </ListItem>
    )
};

export default TeamCalendarActiveFilterBox;