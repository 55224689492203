import { useState } from 'react';
import useApi from "../../hooks/useApi";
import useUser from "../../hooks/useUser";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ConfirmationModal from "../ConfirmationModal";
import EventDurationSelectionModal from './EventDurationSelectionModal';
import RecurrenceConfirmationModal from './RecurrenceConfirmationModal';
import EventNotesSelectionModal from './EventNotesSelectionModal';
import EventEmployeeSelectionModal from './EventEmployeeSelectionModal';
import EventPermissionHandler from './EventPermissionHandler';
import EventEditActionModal from './actions/EventEditActionButton';
import EventDuplicateActionModal from './actions/EventDuplicateActionButton';
import EventDeleteActionButton from './actions/EventDeleteActionButton';
import EventSaveCancelActions from './EventSaveCancelActions';

const EventHolidaySickModal = ({
    event,                      // Related event, null if no id
    eventTypeId,                // Differ between "SICK" and "HOLIDAY"
    editable, setEditable,      // Check edit mode and switch if edit button clicked
    getStartForDate,            // For calendar date click
    getEndForDate,              // For calendar date click
    duplicate, setDuplicate,    // If click on duplicate
    handleAction,               // Handle any action
}) => {
    const { t } = useTranslation();
    const { getEmployeeId, getTeamId } = useUser();

    const {
        queryEmployeesSimple,
    } = useApi();

    const currentEmployeeId = getEmployeeId();
    const teamId = getTeamId();

    const {
        canTeamEvents,
    } = EventPermissionHandler(event, null);

    const initialEmployees = event?.employees ?? [];
    const initialStart = event?.start && !event?.recurrence
        ? moment(event?.start).format('YYYY-MM-DD')
        : getStartForDate(false);
    const initialEnd = event?.end && !event?.recurrence
        ? moment(event?.end).format('YYYY-MM-DD')
        : (event?.start && event?.end
            ? getEndForDate(false, moment(event.end).diff(moment(event.start), 'ms'))
            : getEndForDate(false)
        );
    const initialNotes = event?.note ?? "";

    const initialRecurrenceType = event?.recurrence?.type ?? 'none';
    const initialRecurrenceFrequency = event?.recurrence?.frequency ?? 1;
    const initialWeekdaysRecurrence = event?.recurrence?.weekdays ?? [true, true, true, true, true, true, true];
    const initialEndsNever = !event?.recurrence?.endsAt;
    const initialEndsAt = event?.recurrence?.endsAt
        ? moment(event?.recurrence.endsAt).format('YYYY-MM-DD')
        : "";

    const [employees, setEmployees] = useState(initialEmployees);
    const [start, setStart] = useState(initialStart);
    const [end, setEnd] = useState(initialEnd);
    const [notes, setNotes] = useState(initialNotes);

    const [recurrenceType, setRecurrenceType] = useState(initialRecurrenceType);
    const [recurrenceFrequency, setRecurrenceFrequency] = useState(initialRecurrenceFrequency);
    const [weekdaysRecurrence, setWeekdaysRecurrence] = useState(initialWeekdaysRecurrence);
    const [startsAt, setStartsAt] = useState(initialStart);
    const [endsNever, setEndsNever] = useState(initialEndsNever);
    const [endsAt, setEndsAt] = useState(initialEndsAt);

    const [employeesError, setEmployeesError] = useState(false);
    const [startError, setStartError] = useState(false);
    const [endError, setEndError] = useState(false);
    const [weekdaysRecurrenceError, setWeekdaysRecurrenceError] = useState(false);
    const [endsAtError, setEndsAtError] = useState(false);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showRecurrenceConfirmationModal, setShowRecurrenceConfirmationModal] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState("");
    const [confirmationModalData, setConfirmationModalData] = useState(null);

    const { data: employeesOptions = [] } = queryEmployeesSimple(editable);

    const resetConfirmationModal = () => {
        setShowConfirmationModal(false);
        setShowRecurrenceConfirmationModal(false);
        setConfirmationModalMessage("");
        setConfirmationModalData(null);
    }

    const reset = () => {
        setEmployees(initialEmployees);
        setStart(initialStart);
        setEnd(initialEnd);
        setNotes(initialNotes);

        setRecurrenceType(initialRecurrenceType);
        setRecurrenceFrequency(initialRecurrenceFrequency);
        setWeekdaysRecurrence(initialWeekdaysRecurrence);
        setStartsAt(initialStart);
        setEndsNever(initialEndsNever);
        setEndsAt(initialEndsAt);

        setEmployeesError(false);
        setStartError(false);
        setEndError(false);
        setWeekdaysRecurrenceError(false);
        setEndsAtError(false);

        resetConfirmationModal();
    }

    const validateField = (isError, setError) => {
        setError(isError);
        const isValid = !isError;
        return isValid;
    }

    const validateTimestamps = () => {
        let valid = true;

        if (moment(start).isAfter(moment(end))) {
            setStartError(true);
            setEndError(true);
            valid = false;
        }

        if (moment(endsAt).isBefore(moment(start), 'day') || moment(endsAt).isBefore(moment(end), 'day')) {
            setEndsAtError(true);
            valid = false;
        }

        return valid;
    }


    const validate = () => {
        let valid = true;

        valid = validateField(employees?.length <= 0, setEmployeesError) && valid;
        valid = validateField(start === '', setStartError) && valid;
        valid = validateField(end === '', setEndError) && valid;
        valid = validateTimestamps() && valid;

        return valid;
    }


    const collectRequestRecurrenceData = (data) => {
        switch (recurrenceType) {
            case 'none':
                return;
            case 'daily':
                data.recurrenceType = 'daily';
                data.recurrenceFrequency = recurrenceFrequency;
                data.recurrenceChanged = event
                    && (initialRecurrenceType !== data.recurrenceType
                        || initialRecurrenceFrequency !== data.recurrenceFrequency);
                break;
            case 'weekly':
                data.recurrenceType = 'weekly';
                data.recurrenceFrequency = recurrenceFrequency;
                data.weekdaysRecurrence = weekdaysRecurrence;
                data.recurrenceChanged = event
                    && (initialRecurrenceType !== data.recurrenceType
                        || initialRecurrenceFrequency !== data.recurrenceFrequency
                        || initialWeekdaysRecurrence !== data.weekdaysRecurrence);
                break;
        }

        data.endsAt = endsNever ? null : endsAt;
    }


    const collectRequestData = () => {
        let data = {};

        data.id = duplicate ? null : event?.id;
        data.event_type_id = eventTypeId;

        data.note = notes;
        data.team_id = teamId;

        data.start = start;
        data.end = end;

        const employee = employees[0];
        data.all_day = 1;
        data.employee_ids = employee ? [employee.id] : [];
        data.status = 'NULL';

        if (employee) {
            data.title = employee.person?.last_name + ' ' + employee.person?.first_name;
            if (eventTypeId === 'SICK') {
                data.title = data.title + ' ' + t('team-calendar.event-types.sick', 'Sick');
            } else if (eventTypeId === 'HOLIDAY') {
                data.title = data.title + ' ' + t('team-calendar.event-types.holiday', 'Holiday');
            }
        }

        collectRequestRecurrenceData(data);
        return data;
    }


    const collectRequestDataForAction = (inputAction, inputStatus = null) => {
        return {
            action: inputAction,
            status: inputStatus ?? null,
            id: event.id,
            employees: employees?.map(
                employee => ({
                    id: employee.id,
                    start: employee.start,
                    end: employee.end,
                })
            ) ?? [],
            start: start,
            end: end,
        }
    }


    const handleError422 = (errors) => {
        if (errors.user_id) {
            if (errors.user_id.includes("validation.events.user_id_unique")) {
                setStartError(true);
                setEndError(true);
            } else {
                setEmployeesError(true);
            }
        }
        if (errors.start) {
            setStartError(true);
        }
        if (errors.end) {
            setEndError(true);
        }
    }

    const handleSave = () => {
        const isValid = validate();

        if (!isValid) {
            return;
        }

        const data = collectRequestData();

        if (event?.id && event?.recurrence && recurrenceType != 'none') {
            handleEditRecurringEvent(data);
            return;
        }

        const action = data.id ? 'update' : 'save';
        handleAction({ action: action, data: data }, resetConfirmationModal, null, handleError422);
    }

    const handleEditEvent = () => {
        setEditable(true);
    }

    const handleEditRecurringEvent = (data) => {
        setConfirmationModalMessage(t(
            'team-calendar.confirmation-modal.choose-recurrence-change',
            'On which elements of recurrence do you want to apply the changes?'
        ))
        setConfirmationModalData({ action: 'update', data: data });
        setShowRecurrenceConfirmationModal(true);
    }

    const handleDuplicateEvent = () => {
        setDuplicate(true);
        setStart('');
        setEnd('');
        setRecurrenceType('none');
        setEndsAt('');
        setEditable(true);
    }


    const handleEventCancelled = () => {
        if (event?.recurrence) {
            setConfirmationModalMessage(t('team-calendar.confirmation-modal.choose-recurrence-deletion', 'Which elements of recurrence do you want to delete?'))
            setConfirmationModalData(collectRequestDataForAction('delete'));
            setShowRecurrenceConfirmationModal(true);
            return;
        }

        setConfirmationModalMessage(t('team-calendar.confirmation-modal.message-cancelled', 'Are you sure you want to delete this appointment?'))
        setConfirmationModalData(collectRequestDataForAction('delete'));
        setShowConfirmationModal(true);
    }


    const mayEditEvent = event?.permissions?.mayEdit;
    const mayDeleteEvent = event?.permissions?.mayDelete;


    const Actions = () => {
        return (
            <div className="flex items-center justify-end">
                <div className='flex flex-grow space-x-2'>
                    <EventDeleteActionButton
                        enabled={mayDeleteEvent}
                        onClick={handleEventCancelled}
                    />
                </div>
                <div className="flex space-x-2">
                    <EventEditActionModal
                        enabled={mayEditEvent}
                        onClick={handleEditEvent}
                    />
                    <EventDuplicateActionModal
                        enabled={mayEditEvent}
                        onClick={handleDuplicateEvent}
                    />
                </div>
            </div>
        );
    }


    return (
        <>
            <div className="mt-2">
                <div className="space-y-4">
                    {!editable &&
                        <Actions />
                    }

                    {(canTeamEvents || (!editable && event && !event.employee_ids.includes(currentEmployeeId)))
                        &&
                        <EventEmployeeSelectionModal
                            isMultiSelection={false}
                            isClientEvent={false}
                            editable={editable}
                            employeesOptions={employeesOptions}
                            employees={employees}
                            setEmployees={setEmployees}
                            employeesError={employeesError}
                            isContactSelection={false}
                        />
                    }

                    <EventDurationSelectionModal
                        isRecurrence={!!event?.recurrence}
                        editable={editable} eventTypeId={eventTypeId}
                        start={start} setStart={setStart} startError={startError}
                        end={end} setEnd={setEnd} endError={endError}
                        recurrenceType={recurrenceType} setRecurrenceType={setRecurrenceType}
                        recurrenceFrequency={recurrenceFrequency} setRecurrenceFrequency={setRecurrenceFrequency}
                        weekdaysRecurrence={weekdaysRecurrence} setWeekdaysRecurrence={setWeekdaysRecurrence} weekdaysRecurrenceError={weekdaysRecurrenceError}
                        endsNever={endsNever} setEndsNever={setEndsNever}
                        endsAt={endsAt} setEndsAt={setEndsAt} endsAtError={endsAtError}
                        startsAt={startsAt}
                    />

                    <EventNotesSelectionModal
                        editable={editable}
                        notes={notes}
                        setNotes={setNotes}
                    />
                </div>

                <ConfirmationModal
                    isOpen={showConfirmationModal}
                    onClose={resetConfirmationModal}
                    onConfirm={(data, option) => handleAction(data, resetConfirmationModal, option, handleError422)}
                    data={confirmationModalData}
                    message={confirmationModalMessage}
                />
                <RecurrenceConfirmationModal
                    isOpen={showRecurrenceConfirmationModal}
                    onClose={resetConfirmationModal}
                    onConfirm={(data, option) => handleAction(data, resetConfirmationModal, option, handleError422)}
                    data={confirmationModalData}
                    message={confirmationModalMessage}
                />
            </div>

            <EventSaveCancelActions
                editable={editable}
                handleSave={handleSave}
                handleCancel={() => handleAction({ action: 'cancel' }, reset, null, handleError422)}
            />
        </>
    );
}

export default EventHolidaySickModal;
