import { useState } from "react";

export default ({ minSwipeDistance = 100, onLeftSwipe, onRightSwipe }) => {


    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);


    const onTouchStart = (event) => {
        setTouchEnd(0) // otherwise the swipe is fired even with usual touch events
        setTouchStart(event.targetTouches[0].clientX);
    }


    const onTouchMove = (event) => setTouchEnd(event.targetTouches[0].clientX);


    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) {
            return;
        }
        
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            onLeftSwipe();
            return;
        }

        if (isRightSwipe) {
            onRightSwipe();
            return;
        }
    }


    return {
        onTouchStart,
        onTouchMove,
        onTouchEnd,
    }


}
