import './bootstrap';

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

// import {Datepicker} from "flowbite-datepicker";
window.Datepicker = require('flowbite-datepicker');

import './frontend_config';

require('./components/dashboard/DashboardContainer');
require('./components/TeamCalendarContainer');
require('./components/institutions/InstitutionsContainer');
require('./components/internal-event-types/InternalEventTypesContainer');
require('./components/employees/EmployeesContainer');
require('./components/clients/ClientsContainer');

