import { useRef } from "react";

export default ({ minSwipeDistance = 100, onLeftSwipe, onRightSwipe }) => {


    const touchStart = useRef(0);
    const touchEnd = useRef(0);


    const onTouchStart = (event) => {
        touchEnd.current = 0 // otherwise the swipe is fired even with usual touch events
        touchStart.current = event.targetTouches[0].clientX;
    }


    const onTouchMove = (event) => touchEnd.current = event.targetTouches[0].clientX;


    const onTouchEnd = () => {
        if (!touchStart.current || !touchEnd.current) {
            return;
        }
        
        const distance = touchStart.current - touchEnd.current;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            onLeftSwipe();
            return;
        }

        if (isRightSwipe) {
            onRightSwipe();
            return;
        }
    }


    return {
        onTouchStart,
        onTouchMove,
        onTouchEnd,
    }


}
