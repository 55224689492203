import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const TeamCalendarChipSelection = ({
    field,
    label,
    options,
    selectedOptions,
    handleSelection,
}) => {
    const { t } = useTranslation();

    const selectedIds = selectedOptions.map((selectedOption) => selectedOption.id);
    const filteredOptions = options.filter((option) => !selectedIds.includes(option.id));

    const handleOnClick = (event) => {
        handleSelection(event.target.value)
    }

    const handleOnDelete = (value) => {
        const newSelectedOptions = selectedOptions
            .filter((selectedOption) => selectedOption.id !== value.id);
        handleSelection(newSelectedOptions);
    }

    return (
        <div>
            <FormControl sx={{ m: 1, width: '225px' }}>
                <InputLabel 
                    id={`chip-selection-label-${field}`}
                    sx={{ backgroundColor: 'white' }}
                >
                    {label}
                </InputLabel>
                <Select
                    labelId={`chip-selection-label-${field}`}
                    multiple
                    value={selectedOptions}
                    onChange={handleOnClick}
                    input={
                        <OutlinedInput field="Chip" />
                    }
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip 
                                    key={value.id} 
                                    label={value.i18nKeyLabel ? t(value.i18nKeyLabel) : value.name} 
                                    onDelete={() => handleOnDelete(value)}
                                    onMouseDown={(event) => event.stopPropagation()}
                                />
                            ))}
                        </Box>
                    )}
                >
                    {filteredOptions.map((option) => (
                        <MenuItem
                            key={option.id}
                            value={option}
                        >
                            {option.i18nKeyLabel ? t(option.i18nKeyLabel) : option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default TeamCalendarChipSelection;