import React from "react";
import "../i18n/i18n";
import {QueryClient, QueryClientProvider} from "react-query";
import TeamCalendarWrapper from "./TeamCalendarWrapper";
import ReactDOMClient from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";

const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            }
        }
    }
);

export default function TeamCalendarContainer() {

    return <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Routes>
                    <Route path={"calendar/*"} element={<TeamCalendarWrapper />} />
                </Routes>
            </BrowserRouter>
        </QueryClientProvider>;
}

if (document.getElementById('team-calendar-container')) {
    ReactDOMClient.createRoot(document.getElementById('team-calendar-container')).render(<TeamCalendarContainer/>);
}
