import { Trans } from "react-i18next";

const EventPhoneNumbersModal = ({
    client,
}) => {


    const normalizePhoneNumber = (phone) => {
        const phoneNumberWithLeadingZero = phone?.phone_number ?? '';

        let countryCode = phone?.country_code ?? '';
        countryCode = countryCode == 'DE' ? '49' : '';

        if (!phoneNumberWithLeadingZero || !countryCode) {
            return '';
        }

        const formattedPhoneNumber = phoneNumberWithLeadingZero.startsWith('0')
            ? phoneNumberWithLeadingZero.slice(1)
            : phoneNumberWithLeadingZero;

        return `${countryCode}${formattedPhoneNumber}`;
    }


    const phoneHome = client?.person?.phone_home ?? null;
    const phoneNumberHome = phoneHome?.phone_number ?? '-';
    const normalizedPhoneNumberHome = normalizePhoneNumber(phoneHome);

    const phoneMobile = client?.person?.phone_mobile ?? null;
    const phoneNumberMobile = phoneMobile?.phone_number ?? '-';
    const normalizedPhoneNumberMobile = normalizePhoneNumber(phoneMobile);


    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">

            <label
                htmlFor="phone-home"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
                <Trans i18nKey="team-calendar.event-modal.phone-home">Phone Home</Trans>
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <a 
                    href={`tel:+${normalizedPhoneNumberHome}`} 
                    style={{ color: 'blue', textDecoration: 'none' }}
                >
                    <div className="text-sm sm:mt-px sm:pt-2">
                        {phoneNumberHome}
                    </div>
                </a>
            </div>

            <label
                htmlFor="phone-mobile"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
                <Trans i18nKey="team-calendar.event-modal.phone-mobile">Phone Mobile</Trans>
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <a 
                    href={`tel:+${normalizedPhoneNumberMobile}`} 
                    style={{ color: 'blue', textDecoration: 'none' }}
                >
                    <div className="text-sm sm:mt-px sm:pt-2">
                        {phoneNumberMobile}
                    </div>
                </a>
            </div>
        </div>
    )
}

export default EventPhoneNumbersModal;