const RedCrossRemoveButton = ({ 
    enabled = true,
    onClick, 
    style = {},
}) => {

    if (!enabled) {
        return;
    }

    return (
        <button
            onClick={onClick}
            style={style}
            className="text-red-500 hover:text-red-700"
        >
            <svg className="h-4 w-4" fill="currentColor"
                viewBox="0 0 20 20">
                <path
                    fillRule="evenodd"
                    d="M14.293 5.293a1 1 0 0 0-1.414 0L10 8.586 6.707 5.293a1 1 0 0 0-1.414 1.414L8.586 10l-3.293 3.293a1 1 0 1 0 1.414 1.414L10 11.414l3.293 3.293a1 1 0 0 0 1.414-1.414L11.414 10l3.293-3.293a1 1 0 0 0 0-1.414z"
                />
            </svg>
        </button>
    );
}

export default RedCrossRemoveButton;
