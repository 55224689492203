import React from "react";
import "../../i18n/i18n";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactDOMClient from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { deDE } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Markdown from 'react-markdown'
import dataPrivacy from "./DataPrivacy";
import { useTranslation } from "react-i18next";
import ReactMarkdownStyledDiv from "./ReactMarkdownStyledDiv";

const theme = createTheme({
    palette: {
        primary: {
            main: '#4E80EE',
        },
        secondary: {
            main: '#E89D27',
        },
    },
    typography: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14,
    },
}, deDE, // x-data-grid translations
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        }
    }
}
);

export default function DataPrivacyContainer() {
    const { t } = useTranslation();

    return (<ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Routes>
                        <Route
                            exact path={"data-privacy"}
                            element={
                                <ReactMarkdownStyledDiv children={
                                    <>
                                        <h1>{t("data-privacy", "Data Privacy")}</h1>
                                        <Markdown
                                            components={{
                                                h1: 'h2',
                                                h2: 'h3',
                                                h3: 'h4',
                                            }}
                                        >
                                            {dataPrivacy}
                                        </Markdown>
                                    </>
                                } />
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </QueryClientProvider>
        </LocalizationProvider>
    </ThemeProvider>);
}

if (document.getElementById('data-privacy-container')) {
    ReactDOMClient
        .createRoot(document.getElementById('data-privacy-container'))
        .render(<DataPrivacyContainer />);
}
