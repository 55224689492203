import { Trans, useTranslation } from "react-i18next";

const EventDrivingDistanceSelectionModal = ({
    editable,
    drivingDistance,
    setDrivingDistance,
    drivingDistanceError,
}) => {
    const { t } = useTranslation();

    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">

            <label
                htmlFor="driving-distance"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
                <Trans i18nKey="team-calendar.event-modal.driving-distance">Driving Distance</Trans>
            </label>

            <div className="mt-1 sm:mt-0 sm:col-span-2">
                {editable
                    ?
                    <input
                        type="number"
                        min="0"
                        id="driving-distance"
                        name="driving-distance"
                        value={drivingDistance}
                        onChange={(e) => setDrivingDistance(e.target.value)}
                        className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white shadow-sm focus:outline-none  sm:text-sm ${drivingDistanceError ? "border-red-500" : "border-gray-300"}`}
                        placeholder={t('team-calendar.event-modal.enter-driving-distance')}
                    />
                    :
                    <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">{drivingDistance}</div>
                }
            </div>
        </div>
    )
}

export default EventDrivingDistanceSelectionModal;