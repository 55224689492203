import React, { useEffect, useRef, useState } from "react";
import EventModal from "./EventModal";
import useHorizontalSwipe from "./gestures/HorizontalSwipe";
import EventTypeConfirmationModal from "./events/EventTypeConfirmationModal";
import TeamCalendarHeader from "./TeamCalendarHeader";
import TeamCalendar from "./TeamCalendar";
import { useCalendarViewStore } from "../store/CalendarViewStore";
import TeamCalendarFilterDrawer from "./calendar/TeamCalendarFilterDrawer";
import useTeamCalendarEventUpdater from "./events/TeamCalendarEventUpdater";
import useUser from "../hooks/useUser";
import useApi from "../hooks/useApi";
import moment from "moment";

export default function TeamCalendarWrapper() {
    const calendarRef = useRef(null);
    const { getEmployeeId } = useUser();
    const { 
        queryEventEmployees, 
        queryAssignedPatients,
    } = useApi();
    const {
        updateEventsByTeamSelection,
        filterEvents,
        updateEventsByRecurrence,
    } = useTeamCalendarEventUpdater();

    const updateGrid = useCalendarViewStore(state => state.updateGrid);
    const updateViewDate = useCalendarViewStore(state => state.updateViewDate);
    const setSelectedDate = useCalendarViewStore(state => state.setSelectedDate);
    const setSelectedId = useCalendarViewStore(state => state.setSelectedId);
    const setSelectedType = useCalendarViewStore(state => state.setSelectedType);
    const toggleEventTypeConfirmationModal = useCalendarViewStore(state => state.toggleEventTypeConfirmationModal);
    const toggleEventModal = useCalendarViewStore(state => state.toggleEventModal);
    const isSelectedInitialized = useCalendarViewStore(state => state.isSelectedInitialized);

    const employeeId = getEmployeeId();
    const teamSelection = useCalendarViewStore(state => state.selected);

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
    const [filter, setFilter] = useState(null);

    const { data: assignedEmployees = [], isLoading: isLoadingAssignedEmployees } = queryEventEmployees(
        employeeId,
        (data) => {
            if (!isSelectedInitialized) {
                selectAll(data);
            }
        }
    );
    const { data: assignedPatients = [], isLoading: isLoadingAssignedPatients } = queryAssignedPatients(employeeId);

    const handleEventsChanged = (events, action) => {
        const calendarApi = calendarRef.current.getApi();
        const format = 'YYYY-MM-DD HH:mm';

        const calendarStart = calendarApi.view.currentStart;
        const calendarEnd = calendarApi.view.currentEnd;

        const range = {
            start: moment(calendarStart).startOf('day').format(format),
            end: moment(calendarEnd).subtract(1, 'days').endOf('day').format(format),
        }

        const filterEventsByRange = (events) => {
            return events.filter(event => {
                const start = moment(event.start);
                const end = moment(event.end);
                const endsAt = event?.recurrence?.endsAt 
                    ? moment(event.recurrence.endsAt)
                    : null;
                return start.isSameOrBefore(range.end) &&
                    (end.isSameOrAfter(range.start) || (endsAt && endsAt.isSameOrAfter(range.start)));
            })
        }

        const addEvents = (events) => {
            let updatedEvents = updateEventsByTeamSelection(events, teamSelection, assignedEmployees, assignedPatients, range);
            updatedEvents = filterEvents(updatedEvents, filter);
            updateEventsByRecurrence(updatedEvents);
            updatedEvents.forEach(updatedEvent => calendarApi.addEvent(updatedEvent, true));
        }

        const removeEvents = (events) => {
            const currentEvents = events.map(currentEvent => calendarApi.getEventById(currentEvent.id));
            currentEvents.forEach(currentEvent => currentEvent?.remove());
        }

        const filteredEvents = action !== 'deleted' ? filterEventsByRange(events) : events;

        if (filteredEvents.length <= 0) {
            return;
        }

        switch (action) {
            case 'created':
                addEvents(filteredEvents);
                break;
            case 'updated':
                removeEvents(filteredEvents);
                addEvents(filteredEvents);
                break;
            case 'deleted':
                removeEvents(filteredEvents);
                break;
            default:
                break;
        }
    }

    const handleDateClick = (arg) => {
        setSelectedDate(arg.dateStr);   

        toggleEventTypeConfirmationModal(true);
    };

    const handleEventClick = (arg) => {
        const eventTypeId = arg?.event?.extendedProps?.event_type_id;

        if (eventTypeId === 'PUBLIC_HOLIDAY') {
            return;
        }

        setSelectedDate(arg.event.startStr);
        setSelectedId(arg.event.id);
        setSelectedType(eventTypeId);

        toggleEventModal(true);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePrevClick = () => {
        calendarRef.current.getApi().prev();
        updateViewDate(calendarRef.current.getApi().getDate());
    };

    const handleNextClick = () => {
        calendarRef.current.getApi().next();
        updateViewDate(calendarRef.current.getApi().getDate());
    };

    const handleTodayClick = () => {
        calendarRef.current.getApi().today();
        updateViewDate(calendarRef.current.getApi().getDate());
    };

    const handleGoToDateClick = (date) => {
        calendarRef.current.getApi().gotoDate(date);
        updateViewDate(calendarRef.current.getApi().getDate());
    }

    const gridButtonHandler = (viewType) => {
        const calendarApi = calendarRef.current.getApi();
        switch (viewType) {
            case 'five-days':
                updateGrid(viewType, 'timeGridWeek', [0, 6]);
                calendarApi.changeView('timeGridWeek');
                return;
            case 'single-day':
                updateGrid(viewType, 'timeGridDay', []);
                calendarApi.changeView('timeGridDay');
                return;
            case 'week':
                updateGrid(viewType, 'timeGridWeek', []);
                calendarApi.changeView('timeGridWeek');
                return;
            case 'month':
                updateGrid(viewType, 'dayGridMonth', []);
                calendarApi.changeView('dayGridMonth');
                return;
            case 'list':
                updateGrid(viewType, 'listWeek', []);
                calendarApi.changeView('listWeek');
                return;
        }
    };

    const horizontalSwipeHandler = useHorizontalSwipe({
        onLeftSwipe: handleNextClick,
        onRightSwipe: handlePrevClick
    });

    return <div className="relative" {...horizontalSwipeHandler}>
        <EventTypeConfirmationModal />
        <EventModal handleEventsChanged={handleEventsChanged} />

        <TeamCalendarHeader
            filter={filter}
            isLargeScreen={isLargeScreen}
            gridButtonHandler={gridButtonHandler}
            handlePrevClick={handlePrevClick}
            handleTodayClick={handleTodayClick}
            handleNextClick={handleNextClick}
        />

        {!isLoadingAssignedPatients && !isLoadingAssignedEmployees &&
            <TeamCalendar
                calendarRef={calendarRef}
                handleDateClick={handleDateClick}
                handleEventClick={handleEventClick}
                teamSelection={teamSelection}
                assignedEmployees={assignedEmployees}
                assignedPatients={assignedPatients}
                filter={filter}
            />
        }

        <TeamCalendarFilterDrawer
            isLargeScreen={isLargeScreen}
            filter={filter}
            teamMembers={assignedEmployees}
            setFilter={setFilter}
            handleGoToDateClick={handleGoToDateClick}
        />

    </div>
}
