const useUser = () => {
    const getPermissions = () => {
        const permissionsMeta = document.querySelector('meta[name="permissions"]');
        return permissionsMeta ? permissionsMeta.content.split(',') : [];
    }

    const getTeamId = () => {
        const teamMeta = document.querySelector('meta[name="team_id"]');
        return teamMeta ? teamMeta.content : null;
    }

    const getUserId = () => {
        const userMeta = document.querySelector('meta[name="user_id"]');
        return userMeta ? userMeta.content : null;
    }

    const getParsedUserId = () => {
        const userMeta = document.querySelector('meta[name="user_id"]');
        return userMeta ? parseInt(userMeta.content) : null;
    }

    const getEmployeeId = () => {
        const employeeMeta = document.querySelector('meta[name="employee_id"]');
        return employeeMeta ? employeeMeta.content : null;
    }

    return {
        getPermissions,
        getTeamId,
        getUserId,
        getParsedUserId,
        getEmployeeId
    }
}

export default useUser;
