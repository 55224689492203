import { useState, useRef, useEffect } from 'react';

const Autocomplete = ({ 
    options, 
    value, 
    error, 
    onSelect, 
    renderOption, 
    renderValue, 
    filterFunction,
    sortFunction,
    placeholder = '', 
    resetAfterSelection = false 
}) => {
    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [showOptions, setShowOptions] = useState(false);

    const optionsRef = useRef(null);

    const updateFilterOptions = (options, filter = null) => {
        let result = options;
        if (filter) {
            result = filterFunction(result, filter);
        }
        if (sortFunction) {
            result = sortFunction(result);
        }
        setFilteredOptions(result);
    }

    useEffect(() => {
        updateFilterOptions(options ?? [], inputValue ?? '');
    }, [options]);

    useEffect(() => {
        setInputValue(renderValue(value));
    }, [value]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (optionsRef.current && !optionsRef.current.contains(event.target)) {
                setShowOptions(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        updateFilterOptions(options, event.target.value);
        setShowOptions(true);
    };

    const handleOptionClick = (option) => {
        if (resetAfterSelection) {
            setInputValue('');
        } else {
            setInputValue(renderValue(option));
        }
        setFilteredOptions([]);
        onSelect(option);
        setShowOptions(false);
    };

    const handleClearSelection = () => {
        setInputValue('');
        onSelect(null);
    };

    const handleFocus = () => {
        updateFilterOptions(options, inputValue);
        setShowOptions(true);
    }

    const handleBlur = () => {
        setInputValue(renderValue(value));
        if (!optionsRef.current) {
            setShowOptions(false);
        }
    };

    return (
        <div className="relative">
            <input
                type="text"
                id="autocomplete"
                name="autocomplete"
                className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${error ? "border-red-500" : "border-gray-300"}`}
                placeholder={placeholder}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                autoComplete="off"
                onFocus={handleFocus}
            />
            {inputValue && (
                <button
                    type="button"
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 focus:outline-none"
                    onClick={handleClearSelection}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-3 text-gray-800"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M13.2929 10L19.1464 4.14645C19.3417 3.95118 19.3417 3.63468 19.1464 3.43934C18.9512 3.244 18.6347 3.244 18.4393 3.43934L12.5858 9.29289L6.73223 3.43934C6.53689 3.244 6.22039 3.244 6.02505 3.43934C5.82972 3.63468 5.82972 3.95118 6.02505 4.14645L11.8787 10L6.02505 15.8536C5.82972 16.0488 5.82972 16.3653 6.02505 16.5607C6.22039 16.756 6.53689 16.756 6.73223 16.5607L12.5858 10.7071L18.4393 16.5607C18.6347 16.756 18.9512 16.756 19.1464 16.5607C19.3417 16.3653 19.3417 16.0488 19.1464 15.8536L13.2929 10Z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            )}
            {showOptions && filteredOptions.length > 0 && (
                <ul
                    ref={optionsRef}
                    className="absolute z-10 max-h-80 w-full bg-white border border-gray-300 shadow-lg mt-1 overflow-y-auto"
                >
                    {filteredOptions.map((option) => (
                        <li
                            key={option.id}
                            className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleOptionClick(option)}
                        >
                            {renderOption(option)}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Autocomplete;
