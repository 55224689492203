import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const isSmallScreen = window.innerWidth < 600;

const transformIntoDateString = (dateWithTimeInUTC) => {
    const timezoneOffset = (new Date(dateWithTimeInUTC)).getTimezoneOffset() * 60000;
    const currentDateWithTime = new Date(dateWithTimeInUTC - timezoneOffset);
    return currentDateWithTime.toISOString().slice(0, 10);
}

const CalendarViewStore = (set, get) => ({
    // Persistent
    viewDate: transformIntoDateString(new Date()),
    viewType: 'week',
    gridType: 'timeGridWeek',
    hiddenDays: [],
    isSelectedInitialized: false,
    selected: [],

    // Non-persistent
    selectedDate: null,
    selectedId: null,
    selectedType: null,
    isFilterDrawerOpen: !isSmallScreen,
    isEventTypeConfirmationModalOpen: false,
    isEventModalOpen: false,

    // Methods
    updateViewDate: (newDate) => set(() => (
        { viewDate: transformIntoDateString(newDate.valueOf()), }
    )),
    updateGrid: (
        newViewType,
        newGridType,
        newHiddenDays,
    ) => set(() => (
        {
            viewType: newViewType,
            gridType: newGridType,
            hiddenDays: newHiddenDays,
        }
    )),
    initializeSelected: (selection) => {
        if (get().isSelectedInitialized) {
            return;
        }

        set(() => (
            {
                isSelectedInitialized: true,
                selected: [...selection],
            }
        ));
    },
    updateSelected: (selection) => set(() => (
        {
            isSelectedInitialized: true,
            selected: [...selection],
        }
    )),
    handleSelection: (teamMemberId) => set((state) => (
        {
            selected: state.selected.includes(teamMemberId)
                ? state.selected.filter(id => id !== teamMemberId)
                : [...state.selected, teamMemberId]
        }
    )),
    setSelectedDate: (newSelectedDate) => set(() => (
        {
            selectedDate: newSelectedDate
        }
    )),
    setSelectedId: (newSelectedId) => set(() => (
        {
            selectedId: newSelectedId
        }
    )),
    setSelectedType: (newSelectedType) => set(() => (
        {
            selectedType: newSelectedType
        }
    )),
    toggleFilterDrawer: (isOpen) => set(() => (
        {
            isFilterDrawerOpen: isOpen
        }
    )),
    toggleEventTypeConfirmationModal: (isOpen) => set(() => (
        {
            isEventTypeConfirmationModalOpen: isOpen
        }
    )),
    toggleEventModal: (isOpen) => set(() => (
        {
            isEventModalOpen: isOpen
        }
    )),
});


const persistenceExclusion = (state) =>
    Object.fromEntries(
        Object.entries(state).filter(([key]) => {
            return ![
                'selectedDate',
                'selectedId',
                'selectedType',
                'isFilterDrawerOpen',
                'isEventTypeConfirmationModalOpen',
                'isEventModalOpen'
            ].includes(key);
        })
    );


export const useCalendarViewStore = create(
    persist(
        CalendarViewStore,
        {
            name: 'calendar-storage',
            partialize: persistenceExclusion,
        },
    )
);
