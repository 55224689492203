import { t } from "i18next";
import moment from "moment";

const LastUpdatedModal = ({
    event,
}) => {
    const updatedAt = event?.updated_at ? moment(event.updated_at).format('DD.MM.YYYY HH:mm:ss') : null;
    const updatedBy = updatedAt
        && event?.updated_by?.person?.last_name
        && event?.updated_by?.person?.first_name
        ? `${event?.updated_by?.person?.last_name} ${event?.updated_by?.person?.first_name}`
        : t('team-calendar.event-modal.unknown')

    return (
        <div className="text-sm text-gray-400 mt-8">
            {`${t('team-calendar.event-modal.last-updated-by', 'Last updated by')} ${updatedBy} (${updatedAt}).`}
        </div>
    )
};

export default LastUpdatedModal;