import { MinusIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

const EventUnassignActionModal = ({
    enabled,
    onClick,
}) => {
    const { t } = useTranslation();

    if (!enabled) {
        return;
    }

    return (
        <button 
            onClick={onClick}
            className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            title={t('team-calendar.event-modal.unassign', 'Unassign event')}
        >
            <MinusIcon 
                className="h-5 w-5 text-gray-500" 
                aria-hidden="true" 
            />
        </button>
    );
};

export default EventUnassignActionModal;
