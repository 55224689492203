import { Trans, useTranslation } from "react-i18next"
import Autocomplete from "../Autocomplete";
import moment from "moment";
import { useEffect, useRef } from "react";
import PersonObjectHelper from "./PersonObjectHelper";
import RedCrossRemoveButton from "../general/RedCrossRemoveButton";

const EventEmployeeWithTimeSelectionModal = ({
    editable,
    selectedEmployees, // { id, start, end, person }
    setSelectedEmployees,
    selectedEmployeesError,
    setSelectedEmployeesError,
    employees,
    globalStart,
    globalEnd,
}) => {
    const { t } = useTranslation();

    const {
        compareInts,
        sortByName,
        filterByName,
        extractNameOrEmpty,
        filterOutByIds,
        filterOutById,
    } = PersonObjectHelper();

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevGlobalStart = usePrevious(globalStart);
    const prevGlobalEnd = usePrevious(globalEnd);

    const isValidDate = (date, start, end) => {
        if (!date) {
            return true;
        }
        return (!start || date.isSameOrAfter(start))
            && (!end || date.isSameOrBefore(end))
    };

    const onSelectEmployee = (employee) => {
        let newSelectedEmployees = [...selectedEmployees, employee];

        if (newSelectedEmployees?.length > 1) {
            for (const selectedEmployee of newSelectedEmployees) {
                if (!selectedEmployee.start) {
                    selectedEmployee.start = globalStart;
                }

                if (!selectedEmployee.end) {
                    selectedEmployee.end = globalEnd;
                }
            }
        }

        setSelectedEmployees(newSelectedEmployees)
    }

    const onChangeStart = (employee, newStart) => {
        const date = globalStart ? globalStart.substring(0, 10) : moment().format('YYYY-MM-DD');
        employee.start = date + ' ' + newStart + ':00';

        const parsedDate = moment(employee.start);
        const parsedGlobalStart = globalStart ? moment(globalStart) : null;
        const parsedGlobalEnd = globalEnd ? moment(globalEnd) : null;

        if (!isValidDate(parsedDate, parsedGlobalStart, parsedGlobalEnd)) {
            return;
        }

        let newSelectedEmployees = filterOutById(selectedEmployees, employee.id);
        setSelectedEmployees([...newSelectedEmployees, employee]);

        const employeeError = selectedEmployeesError?.find(error => compareInts(error.id, employee.id)) ?? undefined;

        if (employeeError?.start) {
            let newSelectedEmployeesError = filterOutById(selectedEmployees, employeeError.id);
            employeeError.start = false;
            setSelectedEmployeesError([...newSelectedEmployeesError, employeeError]);
        }
    }

    const onChangeEnd = (employee, newEnd) => {
        const date = globalEnd ? globalEnd.substring(0, 10) : moment().format('YYYY-MM-DD');
        employee.end = date + ' ' + newEnd + ':00';

        const parsedDate = moment(employee.end);
        const parsedGlobalStart = globalStart ? moment(globalStart) : null;
        const parsedGlobalEnd = globalEnd ? moment(globalEnd) : null;

        if (!isValidDate(parsedDate, parsedGlobalStart, parsedGlobalEnd)) {
            return;
        }

        let newSelectedEmployees = filterOutById(selectedEmployees, employee.id);
        setSelectedEmployees([...newSelectedEmployees, employee]);

        const employeeError = selectedEmployeesError?.find(error => compareInts(error.id, employee.id)) ?? undefined;

        if (employeeError?.end) {
            let newSelectedEmployeesError = filterOutById(selectedEmployees, employeeError.id);
            employeeError.end = false;
            setSelectedEmployeesError([...newSelectedEmployeesError, employeeError]);
        }
    }

    useEffect(() => {
        if (selectedEmployees?.length <= 1) {
            return;
        }

        let newSelectedEmployees = [];

        const globalStartChange = prevGlobalStart !== globalStart;
        const globalEndChange = prevGlobalEnd !== globalEnd;

        const parsedPrevGlobalStart = moment(prevGlobalStart);
        const parsedPrevGlobalEnd = moment(prevGlobalEnd);

        const parsedGlobalStart = globalStart ? moment(globalStart) : null;
        const parsedGlobalEnd = globalEnd ? moment(globalEnd) : null;

        for (const selectedEmployee of selectedEmployees) {
            const parsedStart = selectedEmployee.start ? moment(selectedEmployee.start) : null;
            const parsedEnd = selectedEmployee.end ? moment(selectedEmployee.end) : null;

            if ((globalStartChange && parsedStart.isSame(parsedPrevGlobalStart))
                || !isValidDate(parsedStart, parsedGlobalStart, parsedGlobalEnd)
            ) {
                selectedEmployee.start = globalStart;
            }

            if ((globalEndChange && parsedEnd.isSame(parsedPrevGlobalEnd))
                || !isValidDate(parsedEnd, parsedGlobalStart, parsedGlobalEnd)
            ) {
                selectedEmployee.end = globalEnd;
            }

            newSelectedEmployees.push(selectedEmployee);
        }

        setSelectedEmployees(newSelectedEmployees);
    }, [globalStart, globalEnd]);

    return (
        <div className="sm:items-start sm:border-gray-200">

            <label
                htmlFor="employees"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
                <Trans i18nKey="team-calendar.event-modal.employees">employees</Trans>
            </label>

            {!editable && selectedEmployees?.length <= 0 &&
                <div className="mt-1 sm:mt-0">
                    <div className="mt-2">
                        <div className="flex items-center justify-between py-1 text-sm">
                            <Trans i18nKey="team-calendar.not-assigned">Not assigned</Trans>
                        </div>
                    </div>
                </div>
            }

            {selectedEmployees?.length === 1 &&
                <div className="mt-1 sm:mt-0">
                    <div className="mt-2">
                        <div
                            key={"employee_" + selectedEmployees[0].id}
                            className="flex items-center justify-between py-1 text-sm"
                        >
                            {extractNameOrEmpty(selectedEmployees[0])}
                            <RedCrossRemoveButton
                                enabled={editable}
                                onClick={() => { setSelectedEmployees(filterOutById(selectedEmployees, selectedEmployees[0].id)); }}
                            />
                        </div>
                    </div>
                </div>
            }

            {!editable && selectedEmployees?.length > 1 &&
                <div className="mt-1 sm:mt-0">
                    <div className="mt-2">
                        {sortByName(selectedEmployees).map(employee => {
                            return (
                                <div
                                    key={"employee_" + employee.id}
                                    className="grid grid-cols-12 gap-x-1 mt-2 text-sm"
                                >
                                    <div className="grid col-span-6">
                                        {extractNameOrEmpty(employee)}
                                    </div>

                                    <div className="grid col-span-4 flex justify-center">
                                        {
                                            (employee.start ? t('team-calendar.event-modal.end.time', { date: moment(employee.start).toDate() }) : 'X')
                                            + ' - ' +
                                            (employee.end ? t('team-calendar.event-modal.end.time', { date: moment(employee.end).toDate() }) : 'X')
                                        }
                                    </div>
                                </div>
                            );
                        })
                        }
                    </div>
                </div>
            }

            {editable &&
                <div className="mt-1 sm:mt-0">
                    <div className="mt-2">
                        {selectedEmployees?.length > 1 &&
                            sortByName(selectedEmployees).map(employee => {
                                const employeeError = selectedEmployeesError?.find(error => compareInts(error.id, employee.id));
                                return (
                                    <div
                                        key={"employee_" + employee.id}
                                        className="flex flex-col bg-gray-200 my-2"
                                    >
                                        <div className="flex items-center justify-between ml-2 mr-2">
                                            {extractNameOrEmpty(employee)}
                                            <RedCrossRemoveButton
                                                enabled={true}
                                                onClick={() => { setSelectedEmployees(filterOutById(selectedEmployees, employee.id)); }}
                                            />
                                        </div>

                                        <div className="mr-2 mb-2">
                                            <div className="grid grid-cols-3 gap-4 items-start border-gray-200 ml-2">
                                                <label
                                                    htmlFor={`start-time-${employee.id}`}
                                                    className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                                >
                                                    <Trans i18nKey="team-calendar.event-modal.start.label">Start</Trans>
                                                </label>
                                                <input
                                                    type="time"
                                                    id={`start-time-${employee.id}`}
                                                    value={employee.start?.substring(11, 16) ?? ''}
                                                    onChange={(event) => onChangeStart(employee, event.target.value)}
                                                    className={`col-span-2 mt-1 block w-full py-2 px-3 border ${employeeError?.start ? "border-red-500" : "border-gray-200"} bg-white shadow-sm focus:outline-none sm:text-sm`}
                                                />
                                            </div>
                                            <div className="grid grid-cols-3 gap-4 items-start border-gray-200 ml-2">
                                                <label
                                                    htmlFor={`end-time-${employee.id}`}
                                                    className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                                >
                                                    <Trans i18nKey="team-calendar.event-modal.end.label">End</Trans>
                                                </label>
                                                <input
                                                    type="time"
                                                    id={`end-time-${employee.id}`}
                                                    value={employee.end?.substring(11, 16) ?? ''}
                                                    onChange={(event) => onChangeEnd(employee, event.target.value)}
                                                    className={`col-span-2 mt-1 block w-full py-2 px-3 border ${employeeError?.end ? "border-red-500" : "border-gray-200"} bg-white shadow-sm focus:outline-none sm:text-sm`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <Autocomplete
                        options={filterOutByIds(employees, selectedEmployees)}
                        error={false}
                        onSelect={(employee) => onSelectEmployee(employee)}
                        renderOption={extractNameOrEmpty}
                        renderValue={extractNameOrEmpty}
                        filterFunction={filterByName}
                        sortFunction={sortByName}
                        placeholder={t('team-calendar.event-modal.add-employee')}
                        resetAfterSelection={true}
                    />
                </div>
            }
        </div>
    )
};

export default EventEmployeeWithTimeSelectionModal;