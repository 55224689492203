import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Trans } from "react-i18next";
import Spinner from "./Spinner";

const ImportModal = ({ isOpen, onClose, title, handleImport, handleConvert }) => {


    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileError, setSelectedFileError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const onChangeFile = (event) => {
        setErrorMessage('');
        setSelectedFile(event.target.files[0]);
    }


    const onFinish = (error) => {
        if (error) {
            setErrorMessage(error);
        }

        setIsLoading(false);
    }


    const doConvert = () => {
        setErrorMessage('');
        setIsLoading(true);

        handleConvert(selectedFile, onFinish);
    }


    const doImport = () => {
        setErrorMessage('');
        setIsLoading(true);

        handleImport(selectedFile, onFinish, onClose);
    }


    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-20 overflow-y-auto"
                    onClose={() => onClose()}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                        {isLoading
                            ?
                            <Spinner />
                            :
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title as="h3"
                                    className="flex items-start justify-between pb-4 border-b text-gray-900 text-xl lg:text-2xl font-semibold">
                                    {title}
                                    <button
                                        onClick={onClose}
                                        type="button"
                                        className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                        aria-label="Close">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="h-6 w-6">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </Dialog.Title>
                                <div className="mt-2">
                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                        <label htmlFor="file-upload"
                                            className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                            <Trans i18nKey="import-modal.file">Datei</Trans>
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="file"
                                                id="file-upload"
                                                name="file-upload"
                                                onChange={onChangeFile}
                                                className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${selectedFileError ? "border-red-500" : "border-gray-300"}`}
                                            />
                                        </div>
                                    </div>
                                    {errorMessage &&
                                        <p className="mt-2 text-sm text-red-600">
                                            <Trans i18nKey="import-modal.error">Following error:</Trans>{errorMessage}
                                        </p>
                                    }
                                </div>
                                <div className="mt-4 flex justify-between">
                                    <button
                                        type="button"
                                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={onClose}

                                    >
                                        <Trans i18nKey="import-modal.cancel">Cancel</Trans>
                                    </button>
                                    <button
                                        type="submit"
                                        className="inline-flex items-center border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-3 focus:ring-blue-400"
                                        onClick={doConvert}
                                    >
                                        <Trans i18nKey="import-modal.convert">Convert</Trans>
                                    </button>
                                    <button
                                        type="submit"
                                        className="inline-flex items-center border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-3 focus:ring-blue-400"
                                        onClick={doImport}
                                    >
                                        <Trans i18nKey="import-modal.import">Import</Trans>
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </Dialog>
            </Transition>
        </>
    );

};

export default ImportModal;