import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import rrulePlugin from '@fullcalendar/rrule'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import deLocale from '@fullcalendar/core/locales/de';
import useApi from "../hooks/useApi";
import "./TeamCalendar.css";
import useUser from "../hooks/useUser";
import useTeamCalendarEventContent from "./calendar/TeamCalendarEventContent";
import { useCalendarViewStore } from "../store/CalendarViewStore";
import Spinner from "./Spinner";
import useTeamCalendarEventUpdater from "./events/TeamCalendarEventUpdater";
import moment from "moment";

const TeamCalendar = ({
    calendarRef,
    handleDateClick,
    handleEventClick,
    teamSelection,
    assignedEmployees,
    assignedPatients,
    filter,
}) => {
    const { getEmployeeId } = useUser();
    const { queryEventsManually, queryPublicHolidays } = useApi();
    const employeeId = getEmployeeId();
    const { renderEventContent } = useTeamCalendarEventContent(employeeId);
    const {
        updateEventsByTeamSelection,
        filterEvents,
        updateEventsByRecurrence
    } = useTeamCalendarEventUpdater();

    const { getState } = useCalendarViewStore;
    const initialViewDate = getState().viewDate; // Read the state directly without causing re-renders
    const gridType = useCalendarViewStore(state => state.gridType);
    const hiddenDays = useCalendarViewStore(state => state.hiddenDays);

    const { data: publicHolidays = [], isLoading: isLoadingPublicHolidays } = queryPublicHolidays();

    const adaptPublicHolidaysForCalendar = (publicHolidays) => {
        return publicHolidays.flatMap(publicHoliday => [
            {
                ...publicHoliday,
                allDay: true,
                color: "transparent",
                display: "background"
            }
        ]);
    }

    const handleFullCalendarResize = () => {
        const menuHeight = 64;
        const fullCalendarHeaderHeight = window.innerWidth >= 1024 ? 56 : 80;
        return window.innerHeight - menuHeight - fullCalendarHeaderHeight;
    };

    const fetchCalendarEvents = (fetchInfo, successCallback, failureCallback) => {
        const range = {
            start: moment(fetchInfo.startStr).startOf('day').format('YYYY-MM-DD HH:mm'),
            end: moment(fetchInfo.endStr).subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm'),
        };
        queryEventsManually(range)
            .then((response) => {
                let result = updateEventsByTeamSelection(
                    response.data.data,
                    teamSelection,
                    assignedEmployees,
                    assignedPatients,
                    range,
                );
                result = filterEvents(result, filter);
                updateEventsByRecurrence(result);
                successCallback(result);
            })
            .catch((error) => {
                console.error("Error fetching events:", error);
                failureCallback(error);
            });
    };

    return (
        <>
            <div className="relative bg-white w-full">
                {isLoadingPublicHolidays
                    ? <Spinner />
                    :
                    <FullCalendar
                        ref={calendarRef}
                        initialDate={initialViewDate}
                        eventContent={renderEventContent}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin]}
                        initialView={gridType}
                        nowIndicator={true}
                        weekNumbers={true}
                        weekText={"KW"}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        allDayText={''}
                        slotEventOverlap={false}
                        headerToolbar={false}
                        scrollTime={'06:00:00'}
                        scrollTimeReset={false}
                        contentHeight={handleFullCalendarResize()}
                        windowResize={handleFullCalendarResize}
                        locale={deLocale}
                        eventSources={[
                            fetchCalendarEvents,
                            adaptPublicHolidaysForCalendar(publicHolidays),
                        ]}
                        slotDuration={'00:15:00'}
                        slotLabelInterval={'01:00:00'}
                        hiddenDays={hiddenDays}
                        views={{
                            dayGridMonth: {
                                type: 'dayGrid',
                            },
                            timeGridDay: {
                                type: 'timeGrid',
                                duration: { days: 1 },
                            },
                            timeGridWeek: {
                                type: 'timeGrid',
                                duration: { weeks: 1 },
                            },
                            listWeek: {
                                type: 'list',
                                duration: { days: 7 },
                            }
                        }}
                        slotLabelFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            omitZeroMinute: false,
                            meridiem: 'short'
                        }}
                    />
                }
            </div>
        </>
    )
};

export default TeamCalendar;
