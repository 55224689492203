import { Grid } from "@mui/material";
import UpcomingEvents from "./UpcomingEvents";
import ClientsWithoutEvents from "./ClientsWithoutEvents";
import NotPerformedEvents from "./NotPerformedEvents";
import NotConfirmedEvents from "./NotConfirmedEvents";
import HoursWorked from "./HoursWorked";
import EventModal from "../EventModal";

const Dashboard = () => {
    const permissionsMeta = document.querySelector('meta[name="permissions"]');
    const permissions = permissionsMeta ? permissionsMeta.content.split(',') : [];

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={6} sx={{ padding: 2 }}>
                    <UpcomingEvents />
                </Grid>
                <Grid item xs={12} md={6} sx={{ padding: 2 }}>
                    <ClientsWithoutEvents />
                </Grid>
                <Grid item xs={12} md={6} sx={{ padding: 2 }}>
                    <NotPerformedEvents />
                </Grid>
                {/* <Grid item xs={12} md={6} sx={{padding: 2}}>
                    <NotConfirmedEvents/>
                    </Grid> */}
                <Grid item xs={12} md={6} sx={{ padding: 2 }}>
                    <HoursWorked />
                </Grid>
            </Grid>

            <EventModal handleEventsChanged={() => {}} />
        </>
    );
}

export default Dashboard;
