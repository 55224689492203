import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const isSmallScreen = window.innerWidth < 600;

const EmployeesViewStore = (set, get) => ({
    columnVisibility: {
        phone: !isSmallScreen,
        roles: !isSmallScreen,
        team: !isSmallScreen,
    },

    sortModels: [], // element: { field, sort }
    filterModelItems: [], // element: { field, operator: "contains", value }

    paginationModel: {
        pageSize: 10,
        page: 0,
    },

    updateColumnVisibility: (inputColumnVisibility) => {
        set({
            columnVisibility: {
                phone: (inputColumnVisibility?.phone ?? true),
                roles: (inputColumnVisibility?.roles ?? true),
                team: (inputColumnVisibility?.team ?? true),
            }
        })
    },

    handleScreenSizeChange: (_) => {
        const currentVisibility = get().columnVisibility;

        set({
            columnVisibility: {
                phone: currentVisibility.phone,
                roles: currentVisibility.roles,
                team: currentVisibility.team,
            }
        });
    },

    updateSortModels: (sortModels) => {
        set({
            sortModels: [...sortModels],
        });
    },

    updateFilterModelItems: (filterModel) => {
        set({
            filterModelItems: [...filterModel.items],
        });
    },

    updatePaginationModel: (paginationModel) => {
        set({
            paginationModel: {
                pageSize: parseInt(paginationModel?.pageSize) ?? 10,
                page: parseInt(paginationModel?.page) ?? 0,
            }
        })
    }

});

export const useEmployeesViewStore = create(
    persist(
        EmployeesViewStore,
        {
            name: 'employees-view-storage',
        }
    )
);
