import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import useApi from "../hooks/useApi";
import { Trans, useTranslation } from "react-i18next";
import Autocomplete from "./Autocomplete";
import moment from "moment";
import { useParams, useLocation } from "react-router-dom";
import Spinner from "./Spinner";
import { CheckIcon, DocumentCheckIcon, DocumentDuplicateIcon, PencilIcon } from "@heroicons/react/24/outline";
import ConfirmationModal from "./ConfirmationModal";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { TrashIcon } from "@heroicons/react/20/solid";
import EventDurationSelectionModal from './events/EventDurationSelectionModal';
import RecurrenceConfirmationModal from './events/RecurrenceConfirmationModal';
import EventBudgetSelectionModal from './events/EventBudgetSelectionModal';
import EventServiceSelectionModal from './events/EventServiceSelectionModal';
import EventDrivingDistanceSelectionModal from './events/EventDrivingDistanceSelectionModal';
import EventTitleSelectionModal from './events/EventTitleSelectionModal';
import EventLocationSelectionModal from './events/EventLocationSelectionModal';
import EventNotesSelectionModal from './events/EventNotesSelectionModal';
import EventInternalEventTypeSelectionModal from './events/EventInternalEventTypeSelectionModal';
import EventClientSelectionModal from './events/EventClientSelectionModal';
import EventEmployeeSelectionModal from './events/EventEmployeeSelectionModal';
import EventPhoneNumbersModal from './events/EventPhoneNumbersModal';

const EventModal = ({
    currentUserId,
    currentEmployeeId,
    teamId,
    isOpen,
    onClose,
    date,
    handleEventsUpdated,
}) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { type } = useParams();

    const {
        queryEmployeeNames,
        queryEmployees,
        queryEventTypes,
        queryActiveInternalEventTypes,
        queryPatients,
        queryEligibleServices,
        queryActiveBudgets,
        saveOrUpdateEvent,
        queryEvent,
        updateEventStatus,
        deleteEvent,
        deleteRecurringEvent,
    } = useApi();

    const { state } = useLocation();

    const permissionsMeta = document.querySelector('meta[name="permissions"]');
    const permissions = permissionsMeta ? permissionsMeta.content.split(',') : [];
    const canTeamEvents = permissions.includes('team.events') || permissions.includes('teams');

    const { data: employees = [], isLoading: isLoadingEmployees = true } = queryEmployees();
    const { data: employeeNames = [], isLoading: isLoadingEmployeeNames = true } = queryEmployeeNames();
    const { data: eventTypes = [], isLoading: isLoadingEventTypes = true } = queryEventTypes();
    const { data: internalEventTypes = [], isLoading: isLoadingInternalEventTypes = true } = queryActiveInternalEventTypes();
    const { data: teamClients = [], isLoading: isLoadingTeamClients = true } = queryPatients(null, teamId);
    const { data: event = null, isLoading: isLoadingEvent, refetch } = queryEvent(id);

    // const userIdMeta = document.querySelector('meta[name="user_id"]');
    // const userId = userIdMeta ? userIdMeta.content : null;
    const [editable, setEditable] = useState(!id);
    const [disabled, setDisabled] = useState(false);
    const [eventId, setEventId] = useState(null);
    const [status, setStatus] = useState(null);
    const [dialogTitle, setDialogTitle] = useState('');
    const [employeeId, setEmployeeId] = useState(canTeamEvents ? -1 : currentEmployeeId);
    const [employeeIdError, setEmployeeIdError] = useState(false);
    const [employeeIds, setEmployeeIds] = useState([]);
    const [internalEventTypeId, setInternalEventTypeId] = useState('-1');
    const [eventTypeId, setEventTypeId] = useState(type ? type.toUpperCase() : "NONE");
    const [internalEventTypeIdError, setInternalEventTypeIdError] = useState(false);
    const [eventTypeIdError, setEventTypeIdError] = useState(false);
    const [client, setClient] = useState(null);
    const [clientError, setClientError] = useState(false);
    const [listOfEmployeesError, setListOfEmployeesError] = useState(false);
    const [serviceId, setServiceId] = useState(-1);
    const [serviceIdError, setServiceIdError] = useState(false);
    const [serviceName, setServiceName] = useState("");
    const [budgetId, setBudgetId] = useState(-1);
    const [budgetIdError, setBudgetIdError] = useState(false);
    const [drivingDistance, setDrivingDistance] = useState(0);
    const [drivingDistanceError, setDrivingDistanceError] = useState(false);
    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState(false);
    const [location, setLocation] = useState("");
    const [locationError, setLocationError] = useState(false);
    const [start, setStart] = useState("");
    const [startError, setStartError] = useState(false);
    const [end, setEnd] = useState("");
    const [endError, setEndError] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [notes, setNotes] = useState("");
    const [errorMessage, setErrorMessage] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showRecurrenceConfirmationModal, setShowRecurrenceConfirmationModal] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState("");
    const [confirmationModalData, setConfirmationModalData] = useState(null);
    const [listOfUpdatedEmployees, setListOfUpdatedEmployees] = useState([]);

    const clickedStart = state?.clickedStart ?? null;
    const [recurrenceType, setRecurrenceType] = useState('none');
    const [recurrenceFrequency, setRecurrenceFrequency] = useState(1);
    const [weekdaysRecurrence, setWeekdaysRecurrence] = useState([true, true, true, true, true, true, true]);
    const [weekdaysRecurrenceError, setWeekdaysRecurrenceError] = useState(false);
    const [startsAt, setStartsAt] = useState("");
    const [endsNever, setEndsNever] = useState(true);
    const [endsAt, setEndsAt] = useState("");
    const [endsAtError, setEndsAtError] = useState(false);

    const { data: budgets = [], isLoading: isLoadingBudgets } = queryActiveBudgets(client);
    const { data: services = [], isLoading: isLoadingServices = true } = queryEligibleServices(budgetId);
    const { data: clients = [], isLoading: isLoadingClients = true } = queryPatients(employeeId, teamId);

    useEffect(() => {
        if (event) {
            setDialogTitle(t('team-calendar.event-modal.title-edit', 'Edit Event'));
        } else if (type) {
            setDialogTitle(t('team-calendar.event-modal.title-new-event-type', {
                eventType: t('team-calendar.event-types.' + type, type)
            }));
        } else {
            setDialogTitle(t('team-calendar.event-modal.title-new-event', 'Create Event'));
        }
    }, []); 

    useEffect(() => {
        if (!editable) {
            return;
        }

        if (date && (eventTypeId === 'HOLIDAY' || eventTypeId === 'SICK')) {
            const parsedDateInput = moment(date.dateStr);

            setStart(parsedDateInput.format('YYYY-MM-DD'));
            setEnd(parsedDateInput.format('YYYY-MM-DD'));

            return;
        }

        if (eventTypeId === 'CLIENT_APPOINTMENT' || eventTypeId === 'INTERNAL') {
            const parsedDateInput = date ? moment(date.dateStr) : moment();

            const hasTimestamp = date && !date.allDay;
            const startDate = hasTimestamp
                ? parsedDateInput
                : parsedDateInput.hour(8).minute(0);
            const endDate = hasTimestamp
                ? parsedDateInput.clone().add(1, 'hours')
                : parsedDateInput.clone().hour(8).minute(30);

            setStart(startDate.format('YYYY-MM-DDTHH:mm'));
            setEnd(endDate.format('YYYY-MM-DDTHH:mm'));

            return;
        }

        setStart("");
        setEnd("");
    }, [date]);


    const getClient = (id) => {
        let client = null;
        if (teamClients) {
            client = teamClients.find((c) => c.id === id)
        }
        if (!client && event.client?.id === id) {
            client = event.client;
        }
        return client;
    }

    const getService = (id) => {
        if (services) {
            return services.find((service) => service.id === parseInt(id));
        }
        return null;
    }

    const setTimeInterval = () => {
        const format = 'YYYY-MM-DDTHH:mm';

        if (!clickedStart) {
            setStart(moment(event.start).format(format));
            setEnd(moment(event.end).format(format));
            return;
        }

        let start = moment(event.start);
        let end = moment(event.end);
        const durationInMilliseconds = end.diff(start, 'ms');

        start = moment(clickedStart);
        end = moment(clickedStart).add(durationInMilliseconds, 'ms');

        setStart(start.format(format))
        setEnd(end.format(format))
    }

    const initEvent = (event) => {
        if (event) {
            setStatus(event.status);
            setEmployeeId(event.employee_ids.length > 0 ? event.employee_ids[0] : '-1');
            setEmployeeIds(event.employee_ids);
            setEventTypeId(event.event_type_id);
            setInternalEventTypeId(event.internal_event_type_id);
            setClient(getClient(parseInt(event.client_id)));
            setServiceId(parseInt(event.service_id ?? -1));
            setBudgetId(event.budget_type_id ?? -1);
            setDrivingDistance(event.driving_distance);
            setTitle(event.title);
            setIsPaid(event.paid);
            setLocation(event.location);
            setTimeInterval();
            setNotes(event.note);

            if (event.recurrence) {
                const recurrence = event.recurrence;
                setRecurrenceType(recurrence.type ?? 'none');
                setRecurrenceFrequency(recurrence.frequency ?? 1);
                setWeekdaysRecurrence(recurrence.weekdays ?? [true, true, true, true, true, true, true]);
                setStartsAt(moment(event.start).format('YYYY-MM-DDTHH:mm'));
                setEndsNever(!recurrence.endsAt);
                setEndsAt(recurrence.endsAt ? moment(recurrence.endsAt).format('YYYY-MM-DD') : "");
            }
        }
    }

    useEffect(() => {
        initEvent(event);
    }, [event]);

    useEffect(() => {
        if (editable) {
            setServiceId(-1);
        }
    }, [budgetId]);

    useEffect(() => {
        if (editable) {
            if (client && serviceName != "" && eventTypeId === "CLIENT_APPOINTMENT") {
                setTitle(client.person.last_name + ', ' + client.person.first_name + ' -- ' + serviceName);
            } else {
                setTitle("");
            }
        }
    }, [client, serviceName]);

    useEffect(() => {
        if (!editable || eventTypeId !== "CLIENT_APPOINTMENT") {
            return;
        }

        if (parseInt(serviceId) == -1) {
            setServiceName("");  // Fallback name if service not found
            setTitle("");  // Fallback name if service not found
            return;
        }

        if (services) {
            const selectedService = getService(serviceId);

            if (selectedService) {
                setServiceName(selectedService.name);  // Set the service name if the service exists
            } else {
                console.warn("Service not found for id:", serviceId);
                setServiceName("");  // Fallback name if service not found
                setTitle("");  // Fallback name if service not found
            }
        }
    }, [services, serviceId]);

    useEffect(() => {
        if (editable) {
            // reset budgets after client changes while editing
            setBudgetId(-1);
            if (eventTypeId == "CLIENT_APPOINTMENT") {
                if (client?.person?.address) {
                    setLocation(client.person.address.street + ' ' + client.person.address.street_number + ', ' + client.person.address.zip + ' ' + client.person.address.city);
                } else {
                    setLocation("");
                }
            }
        }
    }, [client]);

    const validateField = (isError, setError) => {
        setError(isError);
        const isValid = !isError;
        return isValid;
    }


    const validateTimestamps = () => {
        let valid = true;

        switch (eventTypeId) {
            case "HOLIDAY":
            case "SICK":
                if (moment(start).isAfter(moment(end))) {
                    setStartError(true);
                    setEndError(true);
                    valid = false;
                }
                break;
            case "CLIENT_APPOINTMENT":
            case "INTERNAL":
            default:
                if (moment(start).isSameOrAfter(moment(end))) {
                    setStartError(true);
                    setEndError(true);
                    valid = false;
                }
                break;
        }

        if (moment(endsAt).isBefore(moment(start), 'day') || moment(endsAt).isBefore(moment(end), 'day')) {
            setEndsAtError(true);
            valid = false;
        }

        return valid;
    }


    const validate = () => {
        let valid = true;
        let errorMessages = [];

        if (eventTypeId === "NONE") {
            setEventTypeIdError(true);
            errorMessages.push(t("team-calendar.event-modal.error.select-eventType"));
            valid = false;
        } else {
            setEventTypeIdError(false);
        }

        if (eventTypeId === "INTERNAL") {
            if (internalEventTypeId === "-1") {
                setInternalEventTypeIdError(true);
                valid = false;
            } else {
                setInternalEventTypeIdError(false);
            }
        }

        if (eventTypeId === "CLIENT_APPOINTMENT") {
            valid = validateField(employeeId === -1 && !canTeamEvents, setEmployeeIdError) && valid;
            valid = validateField(client === null, setClientError) && valid;
            valid = validateField(budgetId === -1, setBudgetIdError) && valid;
            valid = validateField(serviceId === -1, setServiceIdError) && valid;
            valid = validateField(title === "", setTitleError) && valid;
            valid = validateField(location === "", setLocationError) && valid;
        }

        if (eventTypeId === "CLIENT_APPOINTMENT" || eventTypeId === "INTERNAL") {
            switch (recurrenceType) {
                case 'daily':
                    valid = validateField(!endsNever && endsAt === '', setEndsAtError) && valid;
                    break;
                case 'weekly':
                    valid = validateField(weekdaysRecurrence.length !== 7, setWeekdaysRecurrenceError) && valid;
                    valid = validateField(!endsNever && endsAt === '', setEndsAtError) && valid;
                    break;
                default:
                    break;
            }
        }

        if (eventTypeId === "HOLIDAY" || eventTypeId === "SICK") {
            valid = validateField(employeeId === -1, setEmployeeIdError) && valid;
        }

        valid = validateField(start === '', setStartError) && valid;
        valid = validateField(end === '', setEndError) && valid;
        valid = validateTimestamps() && valid;

        setErrorMessage(errorMessages)
        return valid;
    }


    const collectRequestRecurrenceData = (data) => {
        switch (recurrenceType) {
            case 'none':
                return;
            case 'daily':
                data.recurrenceType = 'daily';
                data.recurrenceFrequency = recurrenceFrequency;
                break;
            case 'weekly':
                data.recurrenceType = 'weekly';
                data.recurrenceFrequency = recurrenceFrequency;
                data.weekdaysRecurrence = weekdaysRecurrence;
                break;
        }

        data.endsAt = endsNever ? null : endsAt;
    }


    const collectRequestData = () => {
        let data = {};

        data.id = eventId ?? null;
        data.event_type_id = eventTypeId;

        data.note = notes;
        data.team_id = teamId;

        data.start = start;
        data.end = end;

        switch (eventTypeId) {
            case "INTERNAL":
                data.all_day = 0;
                data.employee_ids = employeeIds.filter((id) => parseInt(id) !== -1);
                data.internal_event_type_id = internalEventTypeId;
                data.paid = isPaid;
                data.status = 'NULL';
                data.title = title;
                data.location = location;
                data.user_id = currentUserId;
                collectRequestRecurrenceData(data);
                return data;
            case "CLIENT_APPOINTMENT":
                data.all_day = 0;
                data.budget_type_id = parseInt(budgetId) !== -1 ? budgetId : null;
                data.client_id = client.id;
                data.employee_ids = employeeId && parseInt(employeeId) !== -1 ? [employeeId] : [];
                data.location = location;
                data.service_id = serviceId;
                data.status = 'NOT_PERFORMED';
                data.driving_distance = parseInt(drivingDistance) || 0;
                data.title = title;
                collectRequestRecurrenceData(data);
                return data;
            default: 
                const employee = employees.data.find(emp => emp.id === parseInt(employeeId));
                if (employee) {
                    data.title = employee.person?.last_name + ' ' + employee.person?.first_name;
                    if (eventTypeId === 'SICK') {
                        data.title = data.title + ' ' + 'Krank';
                    } else if (eventTypeId === 'HOLIDAY') {
                        data.title = data.title + ' ' + 'Urlaub';
                    }
                }
                data.all_day = 1;
                data.employee_ids = employeeId && parseInt(employeeId) !== -1 ? [employeeId] : [];
                data.status = 'NULL';
                data.user_id = currentUserId;
                return data;
        }
    }


    const handleError422 = (errors) => {
        if (errors.user_id) {
            if (errors.user_id.includes("validation.events.user_id_unique")) {
                setStartError(true);
                setEndError(true);
                setErrorMessage(t('validation.events.user_id_unique'));
            } else {
                setEmployeeIdError(true);
            }
        }
        if (errors.event_type_id) {
            setEventTypeIdError(true);
        }
        if (errors.client_id) {
            setClientError(true);
        }
        if (errors.budget_type_id) {
            setBudgetIdError(true);
        }
        if (errors.service_id) {
            setServiceIdError(true);
        }
        if (errors.driving_distance) {
            setDrivingDistanceError(true);
        }
        if (errors.title) {
            setTitleError(true);
        }
        if (errors.location) {
            setLocationError(true);
        }
        if (errors.start) {
            setStartError(true);
        }
        if (errors.end) {
            setEndError(true);
        }
    }


    const handleSaveOrUpdateEvent = (data) => {
        saveOrUpdateEvent(data).then((response) => {
            handleEventsUpdated();
            setDisabled(false);
            onClose(response.data, eventId === null ? 'created' : 'updated');
        }).catch((error) => {
            setDisabled(false);
            if (error.response?.status === 422) {
                let errors = error.response.data.errors;
                handleError422(errors);
            } else {
                console.log(error);
            }
        });
    }

    const handleCancel = () => {
        if (event) {
            setEditable(false);
            setDialogTitle(t('team-calendar.event-modal.title-edit', 'Event details'));
            initEvent(event);
        } else {
            onClose(null);
        }
        return;
    }

    const handleSave = () => {
        setDisabled(true);
        const isValid = validate();

        if (!isValid) {
            setDisabled(false);
            return;
        }

        const data = collectRequestData();
        if (recurrenceType != 'none') {
            setConfirmationModalMessage(t('team-calendar.confirmation-modal.choose-recurrence-change', 'On which elements of recurrence do you want to apply the changes?'))
            setConfirmationModalData({ action: 'update', data: data });
            setShowRecurrenceConfirmationModal(true);
            return;
        }
        handleSaveOrUpdateEvent(data);
    };

    const handleEditEvent = () => {
        setDialogTitle(t('team-calendar.event-modal.title-edit-event', 'Edit Event'));
        setEventId(parseInt(id));
        setEditable(true);
    }

    const handleDuplicateEvent = () => {
        setDialogTitle(t('team-calendar.event-modal.title-duplicate-event', 'Duplicate Event'));
        setStart('');
        setEnd('');
        setEventId(null);
        setRecurrenceType('none');
        setEditable(true);
    }


    const handleEventPerformed = () => {
        setConfirmationModalMessage(t('team-calendar.confirmation-modal.message-performed', 'Are you sure you want to mark this appointment as performed?'))
        setConfirmationModalData({ action: 'status', status: 'PERFORMED', id: id });
        setShowConfirmationModal(true);
    }


    const handleEventConfirmed = () => {
        setConfirmationModalMessage(t('team-calendar.confirmation-modal.message-confirmed', 'Are you sure you want to confirm this appointment?'))
        setConfirmationModalData({ action: 'status', status: 'CONFIRMED', id: id });
        setShowConfirmationModal(true);
    }


    const handleEventCancelled = () => {
        if (event?.recurrence) {
            setConfirmationModalMessage(t('team-calendar.confirmation-modal.choose-recurrence-deletion', 'Which elements of recurrence do you want to delete?'))
            setConfirmationModalData({ action: 'delete', id: id, start: start, end: end });
            setShowRecurrenceConfirmationModal(true);
            return;
        }

        setConfirmationModalMessage(t('team-calendar.confirmation-modal.message-cancelled', 'Are you sure you want to delete this appointment?'))
        setConfirmationModalData({ action: 'delete', id: id });
        setShowConfirmationModal(true);
    }


    const handleUnassignFromEvent = () => {
        setConfirmationModalMessage(t('team-calendar.confirmation-modal.message-unassign-from-event', 'Do you want to unassign from the event?'))
        setConfirmationModalData({ action: 'unassign', id: id });
        setShowConfirmationModal(true);
    }


    const handleAssignToEvent = () => {
        setConfirmationModalMessage(t('team-calendar.confirmation-modal.message-assign-to-event', 'Do you want to assign to the event?'))
        setConfirmationModalData({ action: 'assign', id: id });
        setShowConfirmationModal(true);
    }


    const onCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
        setShowRecurrenceConfirmationModal(false);

        setConfirmationModalData(null);
        setConfirmationModalMessage(null);

        handleEventsUpdated();
    }


    const onConfirm = (data, option = null) => {
        try {
            switch (data.action) {
                case 'update':
                    let updateData = data.data;
                    updateData.recurrenceUpdateOption = option;
                    handleSaveOrUpdateEvent(updateData);
                    return;
                case 'status':
                    updateEventStatus(data.id, data.status, event.employee_ids, start, end).then((response) => {
                        onClose(response.data, 'updated');
                        setStatus(data.status);
                        refetch();
                        setShowConfirmationModal(false);
                    }).catch((_) => { });
                    return;
                case 'assign':
                    updateEventStatus(data.id, status, [currentEmployeeId], start, end).then((response) => {
                        setEmployeeId(currentUserId);
                        refetch();
                        onClose(response.data, 'updated', true);
                        setShowConfirmationModal(false);
                    }).catch((_) => { });
                    return;
                case 'unassign':
                    updateEventStatus(data.id, status, [], start, end).then((response) => {
                        setEmployeeId(-1);
                        refetch();
                        onClose(response.data, 'updated', true);
                        setShowConfirmationModal(false);
                    }).catch((_) => { });
                    return;
                case 'delete':
                    if (option) {
                        deleteRecurringEvent(data.id, option, data.start, data.end,).then((_) => {
                            onClose(null, 'deleted', true);
                        }).catch((_) => { });
                        return;
                    } else {
                        deleteEvent(data.id).then((response) => {
                            onClose({ id: data.id }, 'deleted', true);
                            setShowConfirmationModal(false);
                        }).catch((_) => { });
                    }
            }
        } finally {
            setShowConfirmationModal(false);
            setShowRecurrenceConfirmationModal(false);

            setConfirmationModalData(null);
            setConfirmationModalMessage(null);
        }
    }


    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-20 overflow-y-auto"
                    onClose={() => onClose(null)}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                        {isLoadingEvent
                            || (!editable && isLoadingClients)
                            || isLoadingEmployees
                            || isLoadingEmployeeNames
                            || isLoadingEventTypes
                            || isLoadingServices
                            || isLoadingBudgets
                            || isLoadingInternalEventTypes
                            ?
                            <Spinner />
                            :
                            <div
                                className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title as="h3"
                                    className="flex items-start justify-between pb-4 border-b text-gray-900 text-xl lg:text-2xl font-semibold">
                                    {dialogTitle}
                                    <button
                                        onClick={() => onClose(null)}
                                        type="button"
                                        className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                        aria-label="Close">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="h-6 w-6">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </Dialog.Title>
                                <div className="mt-2">
                                    {/*<form>*/}
                                    <div className="space-y-4">
                                        {!editable && (status !== 'CONFIRMED' || event?.can_update_confirmed) &&
                                            <div className="flex items-center justify-end space-x-2">
                                                {/*Termin editieren*/}
                                                <button onClick={handleEditEvent}
                                                    className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                    title={t('team-calendar.event-modal.edit', 'Edit event')}>
                                                    <PencilIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                                </button>
                                                 {/*Termin duplizieren*/}
                                                <button onClick={handleDuplicateEvent}
                                                    className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                    title={t('team-calendar.event-modal.duplicate', 'Duplicate event')}>
                                                    <DocumentDuplicateIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                                </button>
                                                {/*Termin abgeben*/}
                                                {event?.employee_ids.includes(parseInt(currentEmployeeId))
                                                    && eventTypeId === 'CLIENT_APPOINTMENT'
                                                    && status !== 'PERFORMED'
                                                    && status !== 'CONFIRMED'
                                                    && moment(start).isAfter(moment())
                                                    &&
                                                    <button onClick={handleUnassignFromEvent}
                                                        className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                        title={t('team-calendar.event-modal.unassign', 'Unassign from current user')}>
                                                        <XMarkIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                                    </button>
                                                }
                                                {/*Termin übernehmen*/}
                                                {event?.employee_ids.length === 0
                                                    && eventTypeId === 'CLIENT_APPOINTMENT'
                                                    && status !== 'PERFORMED'
                                                    && status !== 'CONFIRMED'
                                                    && moment(start).isAfter(moment())
                                                    &&
                                                    <button onClick={handleAssignToEvent}
                                                        className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                        title={t('team-calendar.event-modal.assign', 'Assign to current user')}>
                                                        <PlusIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                                    </button>
                                                }
                                                {/*Durchführung bestätigen*/}
                                                {canTeamEvents 
                                                && eventTypeId === 'CLIENT_APPOINTMENT'
                                                && status === 'PERFORMED'
                                                && event?.employee_ids.length != 0
                                                && moment(start).isBefore(moment()) &&
                                                    <button onClick={handleEventConfirmed}
                                                        className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                        title={t('team-calendar.event-modal.switch-status-confirmed', 'Set status to confirmed')}>
                                                        <DocumentCheckIcon className="h-5 w-5 text-gray-500"
                                                            aria-hidden="true" />
                                                    </button>
                                                }
                                                {/*Termin durchgeführt*/}
                                                {eventTypeId === 'CLIENT_APPOINTMENT' 
                                                && status !== 'PERFORMED'
                                                && status !== 'CONFIRMED'
                                                && event?.employee_ids.length != 0
                                                && moment(start).isBefore(moment()) &&
                                                    <button onClick={handleEventPerformed}
                                                        className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                        title={t('team-calendar.event-modal.switch-status-performed', 'Set status to performed')}>
                                                        <CheckIcon className="h-5 w-5 text-gray-500"
                                                            aria-hidden="true" />
                                                    </button>
                                                }
                                                {/*Termin löschen*/}
                                                {(eventTypeId !== 'CLIENT_APPOINTMENT' || (status !== 'PERFORMED' && status !== 'CONFIRMED')) &&
                                                    <button onClick={handleEventCancelled}
                                                        className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                        title={t('team-calendar.event-modal.switch-status-cancelled', 'Set status to cancelled')}>
                                                        <TrashIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                                    </button>
                                                }
                                            </div>
                                        }

                                        {((canTeamEvents && eventTypeId !== 'INTERNAL')
                                            || (event && !event.employee_ids.includes(currentEmployeeId)) && eventTypeId !== 'INTERNAL' && !editable)
                                            &&
                                            <EventEmployeeSelectionModal
                                                isMultiSelection={false}
                                                isClientEvent={canTeamEvents && eventTypeId !== "HOLIDAY" && eventTypeId !== "SICK"}
                                                editable={editable}
                                                event={event}
                                                employeeIds={employeeIds}
                                                setEmployeeIds={setEmployeeIds}
                                                employeeId={employeeId}
                                                setEmployeeId={setEmployeeId}
                                                employees={employees}
                                                employeeNames={employeeNames}
                                                listOfEmployeesError={listOfEmployeesError}
                                                employeeIdError={employeeIdError}
                                            />
                                        }

                                        {eventTypeId === "INTERNAL" &&
                                            <EventEmployeeSelectionModal
                                                isMultiSelection={true}
                                                isClientEvent={false}
                                                editable={editable}
                                                event={event}
                                                employeeIds={employeeIds}
                                                setEmployeeIds={setEmployeeIds}
                                                employeeId={employeeId}
                                                setEmployeeId={setEmployeeId}
                                                employees={employees}
                                                employeeNames={employeeNames}
                                                listOfEmployeesError={listOfEmployeesError}
                                                employeeIdError={employeeIdError}
                                            />
                                        }

                                        {eventTypeId === "CLIENT_APPOINTMENT" &&
                                            <EventClientSelectionModal
                                                editable={editable}
                                                client={client}
                                                setClient={setClient}
                                                clients={clients}
                                                clientError={clientError}
                                            />
                                        }

                                        {eventTypeId === "CLIENT_APPOINTMENT" &&
                                            <EventBudgetSelectionModal
                                                editable={editable}
                                                budgetId={budgetId}
                                                setBudgetId={setBudgetId}
                                                budgets={budgets}
                                                budgetIdError={budgetIdError}
                                            />
                                        }

                                        {(eventTypeId === "CLIENT_APPOINTMENT") &&
                                            <EventDrivingDistanceSelectionModal
                                                editable={editable}
                                                drivingDistance={drivingDistance}
                                                setDrivingDistance={setDrivingDistance}
                                                drivingDistanceError={drivingDistanceError}
                                            />
                                        }

                                        {eventTypeId === "CLIENT_APPOINTMENT" &&
                                            <EventServiceSelectionModal
                                                editable={editable}
                                                serviceId={serviceId}
                                                setServiceId={setServiceId}
                                                services={services}
                                                serviceIdError={serviceIdError}
                                            />
                                        }

                                        {(eventTypeId === "CLIENT_APPOINTMENT" || eventTypeId === "INTERNAL") &&
                                            <EventTitleSelectionModal
                                                editable={editable}
                                                title={title}
                                                setTitle={setTitle}
                                                titleError={titleError}
                                            />
                                        }

                                        {(eventTypeId === "CLIENT_APPOINTMENT" || eventTypeId === "INTERNAL") &&
                                            <EventLocationSelectionModal
                                                editable={editable}
                                                location={location}
                                                setLocation={setLocation}
                                                locationError={locationError}
                                            />
                                        }

                                        {(eventTypeId === "CLIENT_APPOINTMENT" && !editable) &&
                                            <EventPhoneNumbersModal client={event.client} />
                                        }

                                        <EventDurationSelectionModal
                                            isRecurrence={event !== null && event.recurrence !== null}
                                            editable={editable} eventTypeId={eventTypeId}
                                            start={start} setStart={setStart} startError={startError}
                                            end={end} setEnd={setEnd} endError={endError}
                                            recurrenceType={recurrenceType} setRecurrenceType={setRecurrenceType}
                                            recurrenceFrequency={recurrenceFrequency} setRecurrenceFrequency={setRecurrenceFrequency}
                                            weekdaysRecurrence={weekdaysRecurrence} setWeekdaysRecurrence={setWeekdaysRecurrence} weekdaysRecurrenceError={weekdaysRecurrenceError}
                                            endsNever={endsNever} setEndsNever={setEndsNever}
                                            endsAt={endsAt} setEndsAt={setEndsAt} endsAtError={endsAtError}
                                            startsAt={startsAt}
                                        />

                                        <EventNotesSelectionModal
                                            editable={editable}
                                            notes={notes}
                                            setNotes={setNotes}
                                        />

                                        {eventTypeId === "INTERNAL" &&
                                            <EventInternalEventTypeSelectionModal
                                                editable={editable}
                                                internalEventTypeId={internalEventTypeId}
                                                setInternalEventTypeId={setInternalEventTypeId}
                                                internalEventTypes={internalEventTypes}
                                                internalEventTypeIdError={internalEventTypeIdError}
                                            />
                                        }
                                    </div>
                                    {/*</form>*/}
                                </div>
                                {errorMessage.length !== 0 && (
                                    <Transition
                                        show={true}
                                        enter="transition-opacity duration-800"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="transition-opacity duration-800"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div
                                            className="flex justify-between text-sm mt-4 mb-4 bg-red-100 border border-red-200 text-gray-700 px-4 py-3 relative">
                                            <ul>
                                                {
                                                    errorMessage.map(err => <li key={err}>{err}</li>)
                                                }
                                            </ul>
                                        </div>
                                    </Transition>
                                )}
                                {errorMessage.length === 0 && (
                                    <Transition
                                        show={true}
                                        enter="transition-opacity duration-800"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="transition-opacity duration-800"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="h-0"></div>
                                    </Transition>
                                )}
                                <div className="mt-4 flex justify-between">
                                    {editable ?
                                        <>
                                            <button
                                                type="button"
                                                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={handleCancel}
                                            >
                                                <Trans i18nKey="team-calendar.event-modal.cancel">Cancel</Trans>
                                            </button>
                                            <button
                                                type="submit"
                                                className="inline-flex items-center border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-3 focus:ring-blue-400"
                                                onClick={handleSave}
                                            >
                                                <Trans i18nKey="team-calendar.event-modal.save">Save</Trans>
                                            </button>
                                        </>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Dialog>
            </Transition>
            <ConfirmationModal
                isOpen={showConfirmationModal}
                onClose={onCloseConfirmationModal}
                onConfirm={onConfirm}
                data={confirmationModalData}
                message={confirmationModalMessage}
            />
            <RecurrenceConfirmationModal
                isOpen={showRecurrenceConfirmationModal}
                onClose={onCloseConfirmationModal}
                onConfirm={onConfirm}
                data={confirmationModalData}
                message={confirmationModalMessage}
            />
        </>
    );
};
export default EventModal;
